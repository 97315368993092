import ApplicationController from "./application_controller"

// Rename to notes modal controller
export default class extends ApplicationController {
  static targets = [
    'noteCloseButton',
    'noteTextField',
    'noteAddButton',
    'misspelledAndToBeCheckedCheckboxes',
    'misspelledCheckbox',
    'toBeCheckedCheckbox',
    'misspelledLabel',
    'toBeCheckedLabel'
  ]

  initialize(){
    window['notes_modal'] = this;

    // for type-o and type-l stages 
    this.closeNotesModalOnResponse = false;
    
    // Check initial checkboxes based on typing form checkboxes current states - Checked and Misspelled
    if (typing_form) {
      if(typing_form.isTypingOttomanStage() && typing_form.typing_for != 'combined_word'){
        if (typing_form.misspelledCheckBoxTarget.checked) {
          this.misspelledCheckboxTarget.click()
        }
        if (typing_form.toBeCheckedCheckBoxTarget.checked) {
          this.toBeCheckedCheckboxTarget.click()
        }
      } else if (typing_form.isTypingLatinStage() && typing_form.typing_for != 'combined_word') {
        // TODO: Left for TypeL
        // if (typing_form.misspelledCheckBoxTarget.checked) {
        //   this.misspelledCheckboxTarget.click()
        // }
        // if (typing_form.toBeCheckedCheckBoxTarget.checked) {
        //   this.toBeCheckedCheckboxTarget.click()
        // }
      } else {
        this.misspelledLabelTarget.classList.add("collapse")
        this.misspelledCheckboxTarget.classList.add("collapse")
        this.toBeCheckedCheckboxTarget.click()
        this.toBeCheckedLabelTarget.classList.add('collapse')
        this.toBeCheckedCheckboxTarget.classList.add('collapse')
      }
    }
    this.hierarchyItemNotes = parseInt(this.element.dataset['itemHierarchyNotesCount']);
    
    // add note styling in navbar if notes exists
    if (this.hierarchyItemNotes != 0){
      if ($('#js_notes_count_icon').length == 0) {
        this.displayNotesCountIconInNavbar(this.hierarchyItemNotes);
        this.addNavbarNotesStyling();
      }
    }
  }

  setHierarchyNotes(notesCount){
    this.hierarchyItemNotes = notesCount;
  }

  // in the following method some code portion is temporarily commented in case this feature is required again by client
  hotKeys(e){
    const eventObject = e;
    var enterKey = 13

    if (eventObject.ctrlKey && eventObject.keyCode == enterKey) {
      if (this.noteTextFieldTarget.value != '') {
        this.noteAddButtonTarget.click()
        // this.closeNotesModalOnResponse = true
        // this.noteCloseButtonTarget.click()
      } 
      // else {
      //   this.noteCloseButtonTarget.click()
      // } // else case is not required as per requirement of client
    } else {
      // Return true so that other hotkeys function work normally for other keys
      return true
    }
  }

  //hides the modal
  // deleteModal(){
  //   this.closeNotesModalOnResponse = false;
  //   this.noteCloseButtonTarget.click()
  // } 

  // only one checkbox can selected from notes modal
  handleCheckboxChange(e) {
    this.misspelledAndToBeCheckedCheckboxesTargets.forEach((checkbox) => {
      if (checkbox != e.currentTarget) {
        checkbox.checked = false
      }
    })
  }

  toggleRelatedItemsOnTypingForm(e){
    if (typing_form && typing_form.typing_for != 'combined_word') {
      if(typing_form.isTypingOttomanStage()){
        if (!this.misspelledCheckboxTarget.checked && !this.toBeCheckedCheckboxTarget.checked) {
          e.preventDefault();
          alert("Selecting from either 'Check' or 'Misspelled' is mandatory to create Note.")
          return false
        }

        // TODO: DRY
        const misspelledOptionsContainer = document.getElementById('misspelled-options')
        const misspelledOptionsCheckboxes = misspelledOptionsContainer.querySelectorAll('.js-misspelled-option-checkboxes');
        let misspelledFieldsContainer = document.getElementById('js-misspelled-fields-container')

        if (this.misspelledCheckboxTarget.checked) {
          typing_form.misspelledCheckBoxTarget.checked = this.misspelledCheckboxTarget.checked;
          typing_form.toBeCheckedCheckBoxTarget.checked = false
          
          misspelledOptionsContainer.classList.remove('d-none');
          misspelledFieldsContainer.classList.remove('d-none')
          //to remove add alternative button of simple ottoman value when item is marked 'misspelled' by notes 
          $('.js-add-alternative-btn.js-simple-add-alt-btn').addClass('d-none');          
        }

        if(this.toBeCheckedCheckboxTarget.checked) {
          typing_form.toBeCheckedCheckBoxTarget.checked = this.toBeCheckedCheckboxTarget.checked
          typing_form.misspelledCheckBoxTarget.checked = false
          
          misspelledOptionsContainer.classList.add('d-none');
          misspelledFieldsContainer.classList.add('d-none')
          //to add alternative button of simple ottoman value when item is unmarked 'misspelled' by notes 
          $('.js-add-alternative-btn.js-simple-add-alt-btn').removeClass('d-none');
          // Uncheck all checkboxes inside the misspelled-options container
          misspelledOptionsCheckboxes.forEach(checkbox => {
            checkbox.checked = false;
          });
        }
      }
    }
    this.addNotesIconAndUpdateStyling(this.noteAddButtonTarget.dataset.itemId);
    return true;  
  }

  displayNotesCountIconInNavbar(NotesCount){
    $('#js_notes_nav_item').addClass('text-danger');
    let numberIcon = `
      <div id='js_notes_count_icon' 
           class='my-auto badge nav-link active bg-danger ms-2 rounded-circle border border-2 text-center text-white'>
        ${NotesCount}
      </div>`;
    $('#js_notes_nav_item').append(numberIcon);
  }

  // this method displays notes icon in sidebar list when new note is created
  // and also adds notes styling on entry screen and navbar
  addNotesIconAndUpdateStyling(item_id) {
    $(`#js_side_bar_word_item_${item_id}`).removeClass('collapse');
    if(typing_form){
      typing_form.addNotesStyling();
    }

    let notesCountIcon = $('#js_notes_count_icon');
    this.setHierarchyNotes(this.hierarchyItemNotes + 1);

    if (notesCountIcon.hasClass('collapse')) {
      notesCountIcon.removeClass('collapse');
      this.addNavbarNotesStyling();
    } else {
      notesCountIcon.text(this.hierarchyItemNotes);
    }
  }

  addNavbarNotesStyling(){
    $('#js_notes_nav_item').addClass('text-danger');
    $('#js_notes_nav_item .text-danger').addClass('text-danger');
    $('#js_notes_nav_item .dim-red').addClass('bg-dim dim-red');
  }

  // this method removes notes icon from sidebar list when all notes are deleted 
  // and also removes notes styling from entry screen and navbar
  removeNotesIconAndUpdateStyling(e) {
    if (window.confirm("Are you sure you want to delete this note?")) {
      if (typing_form){
        typing_form.setCurrentItemNotes(typing_form.currentItemNotes - 1);
        
        if (typing_form.currentItemNotes == 0 && 
          e.target.dataset.noteStage == typing_form.typing_stage){  
          $(`#js_side_bar_word_item_${this.noteAddButtonTarget.dataset.itemId}`).addClass('collapse');
        }
        typing_form.removeNotesStyling(e);
      }

      this.removeNotesStylingFromNavBar();
    } else {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }

  // this method removed notes related styling from typing edit area and also from navbar  
  removeNotesStylingFromNavBar(){
    this.setHierarchyNotes(this.hierarchyItemNotes - 1);
    let updatedItemHierarchyNotesCount = this.hierarchyItemNotes;

    if ( updatedItemHierarchyNotesCount == 0) {
      this.removeHighlightedNotesStylingFromNavbar(); 
    } else {
      $('#js_notes_count_icon').text(updatedItemHierarchyNotesCount);
    }
  }

  removeHighlightedNotesStylingFromNavbar(){
    $('#js_notes_count_icon').addClass('collapse');
    $('#js_notes_nav_item').removeClass('text-danger');
    $('#js_notes_nav_item .text-danger').removeClass('text-danger');
    $('#js_notes_nav_item .dim-red').removeClass('bg-dim dim-red');
  }
}