import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    'adminCheckbox',
    'userCheckbox',
    'defaultAdminDictionariesMultiSelectField',
    'defaultUserDictionariesMultiSelectField',
    'saveReportSetting'
  ]

  initialize(){
    this.initializeCheckboxes();
    this.initializeSelect2();
  }

  initializeCheckboxes(){
    this.adminCheckboxTargets.forEach(checkbox => {
      const showInReports = checkbox.dataset.showInReports === "true";
      checkbox.checked = showInReports;
    });

    this.userCheckboxTargets.forEach(checkbox => {
      const showInReports = checkbox.dataset.showInReports === "true";
      checkbox.checked = showInReports;
    });
  }

  initializeSelect2() {
    // initialize Select2 for Admin Dictionaries
    this.setupSelect2Field(this.defaultAdminDictionariesMultiSelectFieldTarget, this.saveDefaultDictionary.bind(this));
    
    // initialize Select2 for User Dictionaries
    this.setupSelect2Field(this.defaultUserDictionariesMultiSelectFieldTarget, this.saveDefaultDictionary.bind(this));
  }

  setupSelect2Field(target, saveCallback) {
    const selectField = $(target);

    selectField.select2({
      theme: "bootstrap-5",
      width: selectField.data('width') || (selectField.hasClass('w-100') ? '100%' : 'style'),
      closeOnSelect: false,
    });

    selectField.on("select2:close", saveCallback);
  }

  saveDefaultDictionary() {
    this.saveReportSettingTarget.click();
  }

  markAll(e){
    const clickedCheckbox = e.target;
    const checkboxUserType = clickedCheckbox.dataset.checkboxUser;
    let checkedState = clickedCheckbox.checked;
    
    this.updateCheckboxes(checkboxUserType, checkedState);
  }

  updateCheckboxes(userType, state) {
    if(userType == 'Admin'){
      this.adminCheckboxTargets.forEach((checkbox) => {
        checkbox.checked = state;
      })
    } else {
      this.userCheckboxTargets.forEach((checkbox) => {
        checkbox.checked = state;
      })
    }
  }
}
