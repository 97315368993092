import Utils from '../lib/utils'

// This method take points Array, and
// finds and returns the starting X and Y coordinates, and width and height of the polygon
const calculatePolygonDimensions = (pointsArray) => {
  let minX = 10000
  let minY = 10000
  let maxX = -10000
  let maxY = -10000
  for(let i = 0; i < pointsArray.length; i++){
    const p = pointsArray[i]
    if(p.x < minX)
      minX = p.x
    if(p.y < minY)
      minY = p.y
    if(p.x > maxX)
      maxX = p.x
    if(p.y > maxY)
      maxY = p.y
  }

  return {
    minX: minX,
    minY: minY,
    width: maxX - minX,
    height: maxY - minY,
  }
}

const intersects = (path1, path2) => {
  const det = (path1[1].x - path1[0].x) * (path2[1].y - path2[0].y) - (path2[1].x - path2[0].x) * (path1[1].y - path1[0].y)
  if (det === 0) {
    return false
  } else {
    const lambda = ((path2[1].y - path2[0].y) * (path2[1].x - path1[0].x) + (path2[0].x - path2[1].x) * (path2[1].y - path1[0].y)) / det
    const gamma = ((path1[0].y - path1[1].y) * (path2[1].x - path1[0].x) + (path1[1].x - path1[0].x) * (path2[1].y - path1[0].y)) / det
    return (0 < lambda && lambda < 1) && (0 < gamma && gamma < 1)
  }
}

const sortClockwise = (polygon) => {
  const points = Utils.copyObject(polygon)
  points.sort((a,b)=>a.y - b.y)
  // Get center y
  const cy = (points[0].y + points[points.length -1].y) / 2
  // Sort from right to left
  points.sort((a, b)=> b.x - a.x)
  // Get center x
  const cx = (points[0].x + points[points.length -1].x) / 2
  // Center point
  const center = { x: cx, y: cy }

  // Pre calculate the angles as it will be slow in the sort
  // As the points are sorted from right to left the first point
  // is the rightmost
  // Starting angle used to reference other angles
  let startAng
  points.forEach(point => {
    let ang = Math.atan2(point.y - center.y, point.x - center.x)
    if(!startAng)
      startAng = ang
    else if (ang < startAng) // ensure that all points are clockwise of the start point
      ang += Math.PI * 2
    point.angle = ang // add the angle to the point
  })
  // Sort clockwise
  points.sort((a, b) => a.angle - b.angle)
  return points
}

const sortCounterClockwise = (polygon) => {
  const points = sortClockwise(polygon)
  // reverse the order
  const ccwPoints = points.reverse()
  // move the last point back to the start
  ccwPoints.unshift(ccwPoints.pop())
  return ccwPoints
}

const distinct = (array, keys) => {
  let seen = Object.create(null)
  const result = array.filter(o => {
    const key = keys.map(k => o[k]).join('|')
    if (!seen[key]) {
      seen[key] = true
      return true
    }
  })
  return result
}

export {
  intersects,
  calculatePolygonDimensions,
  sortClockwise,
  sortCounterClockwise,
  distinct,
}
