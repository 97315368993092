// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import 'alpinejs'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import 'controllers'
import 'stylesheets/application'
// import * as Grid from 'ag-grid-community';
import 'lib/eb_theme'
import _ from 'lodash'
import Cookies from 'js-cookie'
import scrollIntoView from 'scroll-into-view-if-needed'
import BuildlqColors from '../lib/cropper/buildlq_colors'

// import * as turf from '@turf/turf'
// window.turf = turf;
// Including javascript directly
require("../lib/global_constants.js");
require("../lib/global_methods.js");
require("../lib/modal_state_methods.js");
require("../lib/bootstrap5_v1_layout.js");
// remove images from webpack
const images = require.context('../images', true)

// window.polygonSplitter = require('polygon-splitter')
// import * as polygonSplitter from 'polygon-splitter'
// window.polygonSplitter = polygonSplitter


window.Routes = require('routes')
window.Rails = Rails;
window.Cookies = Cookies;
window.scrollIntoView = scrollIntoView;
window.BuildlqColors = BuildlqColors;

// window.agGrid = Grid;
Rails.start()
ActiveStorage.start()


// Activate all tooltips
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})

//Activate all tooltips for modals
$(document).on('ajax:success', function(event) {
  feather.replace();

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
});

// TODO: Move to specific file
$(document).ready(function() {
  $('.js-return-approve-message-modal').on('shown.bs.modal', function () {
    // Focus on the textarea inside the modal
    $('.js-return-approve-message-textarea', this).focus();
  });
});


