// to set the global flags associated with modals to track their current status (open/closed) specifically in hotkeys methods

window.openApproveOrReturnModal = function() {
  window["pageset_approve_or_return_modal"] = true;
}

window.closeApproveOrReturnModal = function() {
  window["pageset_approve_or_return_modal"] = undefined;
}

window.openSubmitPageSetModal = function() {
  window["submit_page_set_modal"] = true;
}

window.closeSubmitPageSetModal = function() {
  window["submit_page_set_modal"] = undefined;
}