import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["latinValusOption"]

  initialize(){
    const otherDropdownOptions = this.getOtherBtnDropdownData(); // Getting dropdown's latin values, flags and sources

    if (!this.isBlankDropdownValues(otherDropdownOptions)){
      //show other btn if dropdown has options
      this.showOtherBtn();
      this.removePopulatedOptionFromOtherBtnDropdown();
    }
    // 'js-unpopulated-history-sources' uniquely identifies fields for Latin alternative fields sources
    // to be populated from 'other' dropdown. Removing this class from prepopulated alternative fields
    // since they are already populated.
    this.removeSourcesIdentifierFromNewPopulatedAlternativeField();
  }
  
  removeSourcesIdentifierFromNewPopulatedAlternativeField() {
    $('.js-unpopulated-history-sources').each(function() {
      $(this).removeClass('js-unpopulated-history-sources');
    });
  }

  getOtherBtnDropdownData() {
    const otherDropdownValues        = [];
    let otherDropdownLatinValueFlags = this.element.getAttribute('data-value-latin-field-flags');
    let otherDropdownLatinValue      = this.element.getAttribute('data-value-latin'); 
    let otherDropdownSources         = JSON.parse(this.element.getAttribute('data-item-alternative-history'));
    
    otherDropdownValues.push({otherDropdownLatinValue, otherDropdownLatinValueFlags, otherDropdownSources});
    
    // Getting dropdown's alternative latin values, flags and sources
    const otherDropdownLatinAlternativeValueFields = this.element.querySelectorAll('.js-other-alternative-entry');
    
    otherDropdownLatinAlternativeValueFields.forEach(alternativeLatinValueField => {
      otherDropdownLatinValueFlags = alternativeLatinValueField.getAttribute('data-alternative-entry-field-flags');
      otherDropdownLatinValue      = alternativeLatinValueField.getAttribute('data-alternative-entry-latin-value');
      otherDropdownSources         = JSON.parse(alternativeLatinValueField.getAttribute('data-alternative-entry-field-alternative-histories'));
      
      otherDropdownValues.push({otherDropdownLatinValue, otherDropdownLatinValueFlags, otherDropdownSources});
    });
    return otherDropdownValues;
  }

  showOtherBtn() {
    this.element.closest('.js-other-latin-parent-element').querySelector('#otherLatinValuesdropdownMenu').classList.remove('hide_thumbnail');     
  }

  removePopulatedOptionFromOtherBtnDropdown() {
    const otherDropdownData = this.getOtherBtnDropdownData(); // Getting dropdown's latin values, flags and sources
    const latinTextFieldData = this.getPopulatedTextFieldData();// Getting prepopulated container latin values, flags and sources 

    // hiding option with same latin values as text field latin values
    if (this.isSameLatinValues(latinTextFieldData, otherDropdownData)){
      this.hideIdenticalLatinValueOptionsFromOtherDropdown();
    } 
  }

  getPopulatedTextFieldData() {
    const prePopulatedContainer = this.element.closest('.js-typing-value-container');
    
    // Getting prepopulated container latin values  
    const textFieldLatinFlagsAndValues = [];
    const textFieldLatinConatiner      = prePopulatedContainer.querySelector('.js-latin-entries-input'); 
    const textFieldSourcesConatiners   = prePopulatedContainer.querySelectorAll('.js-latin-history-sources');
    
    let textFieldLatinValue            = prePopulatedContainer.querySelector('.js-latin-text-field').value; 
    let containerFlags                 = this.getTypeLFlags(textFieldLatinConatiner);
    let textFieldSourceValues          = []
    
    // getting latin value fields sources
    textFieldSourcesConatiners.forEach(textFieldSourcesConatiner => {
      textFieldSourceValues.push(this.getTypeLSources(textFieldSourcesConatiner.querySelectorAll('.js-input-group')));
    });

    let latinFieldSources = textFieldSourceValues[0];
    textFieldLatinFlagsAndValues.push({textFieldLatinValue, containerFlags, latinFieldSources}); 

    // getting alternative latin values, flags and sources
    const textFieldLatinAlternativeContainers = prePopulatedContainer.querySelectorAll('.js-alternative-typing-fields');
    textFieldLatinAlternativeContainers.forEach((existingLatinAlternativeContainer, index) => {

      const alternativeTextField = existingLatinAlternativeContainer.querySelector('.js-alternative-text-field');
      if (alternativeTextField) {
        textFieldLatinValue = alternativeTextField.value
        containerFlags      = this.getTypeLFlags(existingLatinAlternativeContainer);
        latinFieldSources   = textFieldSourceValues[index + 1];
        
        textFieldLatinFlagsAndValues.push({textFieldLatinValue, containerFlags, latinFieldSources});
      }
    });
    return textFieldLatinFlagsAndValues;
  }

  hideIdenticalLatinValueOptionsFromOtherDropdown() {
    this.element.classList.add('collapse');
        
    // removing other button in case dropdown gets empty
    this.checkAndRemoveOtherButton();
  }

  checkAndRemoveOtherButton() {
    if (this.element.closest('.js-other-latin-options-dropdown').querySelectorAll('.js-type-l-other-values-grid-items.collapse').length == this.element.closest('.js-other-latin-options-dropdown').querySelectorAll('.js-type-l-other-values-grid-items').length){
      this.element.closest('.js-other-latin-parent-element').querySelector('#otherLatinValuesdropdownMenu').classList.add('hide_thumbnail');
    }
  }

  getTypeLFlags(latinContainer){
    const lstToggle     = latinContainer.querySelector('input.js-lst-toggle').checked;
    const vulToggle     = latinContainer.querySelector('input.js-vulgar-toggle').checked;
    const orgToggle     = latinContainer.querySelector('input.js-original-toggle').checked;
    const unknownToggle = latinContainer.querySelector('input.js-unknown-toggle').checked;
    
    return this.typeLTypingFieldFlags(lstToggle, vulToggle, orgToggle, unknownToggle);
  }

  typeLTypingFieldFlags(lstToggle, vulToggle, orgToggle, unknownToggle) {
    let typingFieldFlags = [];
    
    if (lstToggle) {
      typingFieldFlags.push('lst');
    }
    
    if (vulToggle) {
      typingFieldFlags.push('Vulg');
    }
    
    if (orgToggle) {
      typingFieldFlags.push('Org.');
    }

    if (unknownToggle) {
      typingFieldFlags.push('?');
    }

    return typingFieldFlags.join(', ');
  }

  getTypeLSources(sourcesConatiner) {
    const allSourcesValue = [];
    sourcesConatiner.forEach((source, index) => {
      const sourceInputs = source.querySelectorAll('.js-source-input');
      const sourceValue = [];
      sourceInputs.forEach((sourceInput, inputIndex) => {
        if (sourceInput.type != 'hidden') {
          sourceValue.push(sourceInput.value)
        } 
      });
      allSourcesValue.push(sourceValue)
    });

    // getting sources values that has url or page no
    const filteredSourcesValue = allSourcesValue.filter(sourceValue => {
      return sourceValue.some((value, index) => index !== 0 && value.trim() !== '');
    });
    
    return filteredSourcesValue;
  }

  isBlankDropdownValues(dropdownValues) {
    // Check if dropdownValues is an array
    if (!Array.isArray(dropdownValues)) {
      return false;
    }

    // Check if all values in an object are empty
    const isObjectEmpty = obj => Object.values(obj).every(value => value === '');

    // Check if all objects in the array are empty
    return dropdownValues.every(isObjectEmpty);
  }

  // while compairing two sources from different items, in some cases source values are undefined and in some other cases source values are null, this method equivalize them with empty string for smooth comparison
  normalizeNullOrUndefined(value) {
    return value == null ? '' : value;
  }

  areHistorySourcesSame(textFieldValueHistorySources, dropdownOptionValueHistorySources) {
    let currentPopulatedValueHistorySources = textFieldValueHistorySources
    let dropdownValueHistorySources = dropdownOptionValueHistorySources

    // creating a Map to store latin sources
    let populatedValueSourceMap = new Map();
    for (let source of currentPopulatedValueHistorySources) {
      // let key = `${source[0]}|${source[1]}|${source[2]}`;
      let key = `${this.normalizeNullOrUndefined(source[0])}|${this.normalizeNullOrUndefined(source[1])}|${this.normalizeNullOrUndefined(source[2])}`;
      populatedValueSourceMap.set(key, true);
    }

    // creating a Map to store other sources
    let dropdowndownValueSourceMap = new Map();
    for (let source of dropdownValueHistorySources) {
      // let key = `${source.dictionary_name}|${source.url}|${source.page_no}`;
      let key = `${this.normalizeNullOrUndefined(source.dictionary_name)}|${this.normalizeNullOrUndefined(source.url)}|${this.normalizeNullOrUndefined(source.page_no)}`;
      dropdowndownValueSourceMap.set(key, true);
    }

    // comparing the size of the two Maps
    if (populatedValueSourceMap.size !== dropdowndownValueSourceMap.size) {
      return false;
    }

    // comparing the keys in both Maps
    for (let key of populatedValueSourceMap.keys()) {
      if (!dropdowndownValueSourceMap.has(key)) {
        return false;
      }
    }

    return true;
  }

  isSameLatinValues(textFieldLatinFlagsAndValues, otherDropdownValues){
    if (textFieldLatinFlagsAndValues.length != otherDropdownValues.length){
      return false;
    }
    
    for (let i = 0; i < textFieldLatinFlagsAndValues.length; i++) {
      const textFieldContainerValues = textFieldLatinFlagsAndValues[i];
      const dropdownOptionValues = otherDropdownValues[i];
      
      // return if flags are not same
      if (textFieldContainerValues.containerFlags != dropdownOptionValues.otherDropdownLatinValueFlags){
        return false;
      }  
      
      // return if latin values are not same
      if (textFieldContainerValues.textFieldLatinValue != dropdownOptionValues.otherDropdownLatinValue ) {
          return false;
      }
      
      // return if sources are not same
      if(textFieldContainerValues.latinFieldSources.length != dropdownOptionValues.otherDropdownSources.length) {
        return false;
      }

      if (!this.areHistorySourcesSame(textFieldContainerValues.latinFieldSources, dropdownOptionValues.otherDropdownSources)){
        return false
      }
    }

    return true;
  }

  //Method to populate latin text fields with selected values 
  populateAndHandlingVisibilityOfLatinValuesFromDropdown(e){
    const selectedLatinValueOption = event.currentTarget;
    // Fetching typing field container to update latin values 
    const typingFieldContainer = selectedLatinValueOption.closest('.js-typing-value-container');

    // deleting existing type-l alternative entries 
    this.deleteExistingAlternativeLatinFields(typingFieldContainer);

    this.setLatinFieldValues(selectedLatinValueOption, typingFieldContainer);
    this.handleOtherDropdownOptionsVisibility();
  }

  setLatinFieldValues(selectedLatinValueOption, typingFieldContainer){
    this.setSimpleLatinField(selectedLatinValueOption, typingFieldContainer);
    this.setAlternativeLatinField(selectedLatinValueOption, typingFieldContainer);
  }

  setSimpleLatinField(selectedLatinValueOption, typingFieldContainer){
    const historiesSourcesContainerId                  = selectedLatinValueOption.getAttribute('data-transcription-sources-field-id');
    const isCorrectedField                             = selectedLatinValueOption.getAttribute('data-corrrected-field') === 'true';
    const selectedSimpleLatinValue                     = selectedLatinValueOption.getAttribute('data-value-latin');
    const selectedSimpleLatinFieldflags                = selectedLatinValueOption.getAttribute('data-value-latin-field-flags');
    const selectedSimpleLatinFieldAlternativeHistories = JSON.parse(selectedLatinValueOption.getAttribute('data-item-alternative-history'));
    const latinValueField                              = typingFieldContainer.querySelector('.js-latin-text-field');
    
    // updating simple latin value field with selected text and flags
    this.setLatinTypingField(latinValueField, selectedSimpleLatinFieldflags, selectedSimpleLatinValue, 
                             selectedSimpleLatinFieldAlternativeHistories, typingFieldContainer, historiesSourcesContainerId, isCorrectedField);

    const sourcesButton = typingFieldContainer.querySelector('.js-history-sources-btn')

    if (sourcesButton){
      if (selectedSimpleLatinFieldAlternativeHistories.length > 0){
        sourcesButton.classList.add('text-danger')
      } else {
        sourcesButton.classList.remove('text-danger')
      }
    }
  }

  setAlternativeLatinField(selectedLatinValueOption, typingFieldContainer){
    const selectedLatinAlternativeData = [];
    // getting selected alternative latin values and flags  
    selectedLatinValueOption.querySelectorAll('.js-other-alternative-entry[data-alternative-entry-latin-value]').forEach(alternativeEntry => {
      const alternativeFieldLatinValue           = alternativeEntry.getAttribute('data-alternative-entry-latin-value');
      const alternativeFieldFlags                = alternativeEntry.getAttribute('data-alternative-entry-field-flags');
      const alternativeFieldAlternativeHistories = JSON.parse(alternativeEntry.getAttribute('data-alternative-entry-field-alternative-histories'));
      
      selectedLatinAlternativeData.push({ alternativeFieldLatinValue, alternativeFieldFlags, alternativeFieldAlternativeHistories });
    });

    if(selectedLatinAlternativeData.length > 0) { // create alternative fields if option has alternative fields
      // creating new alternative fields with selected text and flags and history sources
      this.createAndPopulateAlternativeLatinFieldsWithSelectedData(selectedLatinAlternativeData, typingFieldContainer);
    }
  }

  handleOtherDropdownOptionsVisibility(){
    const selectedLatinFields = this.element.closest('.js-other-latin-options-dropdown').querySelectorAll('.js-type-l-other-values-grid-items.collapse'); 

    if (selectedLatinFields.length > 0) {
      selectedLatinFields.forEach(selectedLatinField => {
        selectedLatinField.classList.remove('collapse');
      });
    }

    this.element.classList.add('collapse');
    // remove other btn if only option is selected
    this.checkAndRemoveOtherButton();
  }

  deleteExistingAlternativeLatinFields(typingFieldContainer) {
    const alternativeLatinValueFields = typingFieldContainer.querySelectorAll('.js-entries-alternatives');
    
    if (alternativeLatinValueFields.length > 0) {
      alternativeLatinValueFields.forEach(field => {
        const deleteAlternativeFieldbutton = field.querySelector('.js-remove-alternative-entry');
        
        if (deleteAlternativeFieldbutton) {
          deleteAlternativeFieldbutton.click();
        }
      });
    }
  }

  createAndPopulateAlternativeLatinFieldsWithSelectedData(selectedLatinAlternativeData, typingFieldContainer){
    selectedLatinAlternativeData.forEach(function(alternative_entry) {
      typingFieldContainer.querySelector('.js-btn-add-alternate').click();
      
      let alternateTextFields         = typingFieldContainer.querySelectorAll('.js-alternative-text-field');
      let focusedAlternativeTextField = alternateTextFields[alternateTextFields.length - 1];
      const selectedTypingField       = focusedAlternativeTextField.closest('.js-entries-input');

      // alternative history takes some time to be rendered
      setTimeout(function() {
        const alternativeFieldHistorySourcesContainerId = '0000.js-unpopulated-history-sources'; // new sources created by add alternative btn has ID '0000'
        this.setLatinTypingField(focusedAlternativeTextField, alternative_entry.alternativeFieldFlags, 
                                 alternative_entry.alternativeFieldLatinValue, alternative_entry.alternativeFieldAlternativeHistories,
                                 typingFieldContainer, alternativeFieldHistorySourcesContainerId, false);

        const sourcesButton = selectedTypingField.querySelector('.js-alternate-history-sources-btn');
        if (sourcesButton) {
          if (alternative_entry.alternativeFieldAlternativeHistories.length > 0) {
            sourcesButton.classList.add('text-danger');            
          } else {
            sourcesButton.classList.remove('text-danger');
          }          
        }

      }.bind(this), 10);
    }.bind(this));
  }

  setLatinTypingField( typingField, typingFieldFlags, typingFieldText, typingAlternativeHistories, 
                       typingFieldContainer, historiesSourcesContainerId, isCorrected){
    typingField.value = typingFieldText;

    this.setTypingFieldFlags(typingField, typingFieldFlags);

    this.setSpellingSources(typingAlternativeHistories, typingFieldContainer, historiesSourcesContainerId, isCorrected);
  }

  setTypingFieldFlags(typingField, typingFieldFlags){
    const typingContainer = typingField.closest('.js-entries-input');
    const flagContainer   = typingContainer.querySelector('.js-root-parent-container');
    const toggleToUpdate  = {
                              'lst': '.js-lst-toggle',
                              'Vulg': '.js-vulgar-toggle',
                              'Org.': '.js-original-toggle',
                              '?': '.js-unknown-toggle'
                            };
    Object.entries(toggleToUpdate).forEach(([toggleFlag, toggleClass]) => {
      flagContainer.querySelector(toggleClass).checked = typingFieldFlags.includes(toggleFlag);
    });

  }

  setSpellingSources(selectedLatinAlternativeHistories, typingFieldContainer, containerClass, isCorrected) {
    // let focusedHistoriesSourcesContainer = typingFieldContainer.querySelector(`.${containerClass}`);
    let focusedHistoriesSourcesContainer = typingFieldContainer.querySelector('.js-latin-history-sources');
    const isAlternateLatinFieldContainer = containerClass == '0000.js-unpopulated-history-sources'; 
    
    // removing 'js-unpopulated-history-sources' to make unique identifier of alternative latin value container 
    if (isAlternateLatinFieldContainer) {
      focusedHistoriesSourcesContainer = typingFieldContainer.querySelector('.js-latin-history-sources.js-unpopulated-history-sources');
      focusedHistoriesSourcesContainer.classList.remove('js-unpopulated-history-sources')
    }
    
    let existingSourcesFields = focusedHistoriesSourcesContainer.querySelectorAll('.js-nested-form-wrapper');
    this.resetExistingSources(existingSourcesFields);
    
    if (selectedLatinAlternativeHistories.length > 0) {
      this.populateSpellingSources(selectedLatinAlternativeHistories, focusedHistoriesSourcesContainer);
    }
  }
  
  resetExistingSources(existingSourcesFields ){
    // resetting existing url and page number valuess
    for (let i = 0; i < existingSourcesFields.length; i++ ) {
      
      const urlInput = existingSourcesFields[i].querySelector('.js-source-url-input');
      const pageNoInput = existingSourcesFields[i].querySelector('.js-source-page-no-input');

      if (urlInput) urlInput.value = '';
      if (pageNoInput) pageNoInput.value = '';
      
      // deleting custom sources field
      //if (i > 4) existingSourcesFields[i].querySelector('.js-history-source-remove-field-btn').click();
      if (i > 4) {
        const removeBtn = existingSourcesFields[i].querySelector('.js-history-source-remove-field-btn');
        if (removeBtn) {
          removeBtn.click();
        }
      }
    }
  }

  getVisibleSources(spellingSourcesContainer){
    return $(spellingSourcesContainer).find('.js-nested-form-wrapper').filter(function() {
      return $(this).css('display') !== 'none';
    });

  }

  populateSpellingSources(selectedLatinAlternativeHistories, focusedHistoriesSourcesContainer){
    // getting visible history sources fields
    const existingSourcesFields = this.getVisibleSources(focusedHistoriesSourcesContainer);

    for (let i = 0; i < selectedLatinAlternativeHistories.length; i++ ) {
      let isCustomSource = true;
      // populate default sources fields
      for (let j = 0; j < existingSourcesFields.length; j++) {
        const dictionaryNameInput = existingSourcesFields[j].querySelector('.js-source-dictionary-name-input');

        if (dictionaryNameInput && dictionaryNameInput.value === selectedLatinAlternativeHistories[i].dictionary_name) {
          const urlInput = existingSourcesFields[j].querySelector('.js-source-url-input');
          const pageNoInput = existingSourcesFields[j].querySelector('.js-source-page-no-input');
          
          if (urlInput) urlInput.value = selectedLatinAlternativeHistories[i].url;
          if (pageNoInput) pageNoInput.value = selectedLatinAlternativeHistories[i].page_no;
          
          isCustomSource = false;
          break;
        }
      }
      // If no matching dictionary_name was found, create new source field
      if (isCustomSource) {
        focusedHistoriesSourcesContainer.querySelector('.js-add-history-sources-field-btn').click();
        
        const newSourceField  = this.getVisibleSources(focusedHistoriesSourcesContainer).last()[0];
        const dictionaryInput = newSourceField.querySelector(".js-source-dictionary-name-input");
        const urlInput        = newSourceField.querySelector('.js-source-url-input');
        const pageNoInput     = newSourceField.querySelector('.js-source-page-no-input');

        dictionaryInput.value = selectedLatinAlternativeHistories[i].dictionary_name;
        urlInput.value        = selectedLatinAlternativeHistories[i].url;
        pageNoInput.value     = selectedLatinAlternativeHistories[i].page_no;
      }
    }
  }
}
