// Todo: Make entry type strings available in constants to be used everywhere
const generalClipTypeColor = function(entryType) {
  switch(entryType) {
    case 'subentry':
      return 'rgba(93, 92, 255, 0.2)'
      break;
    case 'other':
      return 'rgba(81, 133, 224, 0.2)'
      break;
    case 'entry':
    default:
      return 'rgba(207, 77, 77, 0.2)'
      break;
  }
}

// Using index to support existing case. TODO: Remove index case if possible
const clipColor = function(clip, index = 0){  
  let fillStyle = 'rgba(207, 77, 77, 0.2)'; // Default case
  if (cropper && clip.otherWordCategory) { 
    
    let otherWordIds = cropper.otherWordIds
    let otherWordCategory = parseInt(clip.otherWordCategory)
    let otherWordIndex = 0;
    for (otherWordIndex = 0; otherWordIndex < otherWordIds.length; otherWordIndex++) {
      if (clip.otherWordCategory == otherWordIds[otherWordIndex]) {
        break;
      }
    }
    // Circle around the colors
    otherWordIndex = otherWordIndex % cropper.otherWordsColors.length;
    fillStyle = cropper.otherWordsColors[otherWordIndex]
  } else if (typing_form && typing_form.otherWordIds && clip.otherWordCategory){
    let otherWordIds = typing_form.otherWordIds;
    let otherWordCategory = parseInt(clip.otherWordCategory)
    let otherWordIndex = 0;
    for (otherWordIndex = 0; otherWordIndex < otherWordIds.length; otherWordIndex++) {
      if (clip.otherWordCategory == otherWordIds[otherWordIndex]) {
        break;
      }
    }
    // Circle around the colors
    otherWordIndex = otherWordIndex % typing_form.otherWordsColors.length;
    fillStyle = typing_form.otherWordsColors[otherWordIndex]
  }
  return fillStyle;
}

//Transform color in rgba format from hex format. For eg #f8e45c -> rgba(248, 228, 92, 0.2)
const hexToRGB = function(hex){
    hex = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,(m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16));
    return hex = `rgba(${hex[0]}, ${hex[1]}, ${hex[2]}, 0.2)`;
} 

// Colors for Clips in slice and split stages
const fillColor = function(index, opacity = 0.2){
  return `rgba(32, 3, 252, ${opacity})`
}

const BuildlqColors = () => {
  return {
    generalClipTypeColor: generalClipTypeColor,
    clipColor: clipColor,
    hexToRGB: hexToRGB
  }
}

export default BuildlqColors();
