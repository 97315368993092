// cropper_controller.js
import ApplicationController from "./application_controller";
import { initStateManagement } from '../lib/cropper/state_management'
import { initClipsManagement } from '../lib/cropper/clips_management'
import { dragElement } from 'modules/drag_element';
import Polygon from '../lib/polygon'
import BuildlqColors from '../lib/cropper/buildlq_colors'
import API from '../lib/api'

const SLICING_MODES = ['polygon', 'rectangle', 'line']

export default class CropperController extends ApplicationController {
  static targets = [
    'spinner',
    'unsavedChanges',
    'reorderEntriesContainer',
    'reorderEntriesRoot',
    'reorderColumnsRoot',
    'reorderColumnsContainer',
    'reorderEntriesButton',
    'returnOrApproveModal',
    'GrabButton',
    'currentItemIndexContainer'];

  // Gets called in initialize()
  #assignDefaultValues(){
    this.slicingMode = SLICING_MODES[0]
    this.selectionMode = 'none'
    this.otherWordCategoryOptions = {}
    this.otherWordIds = []
    this.otherWordsColors = []
    this.lShapeMode = false
    this.lShapeAngleIndex = false
    this.lShapePointIndexes = []
    this.polygonInEditMode = false
    this.polygonIndexInEditMode = false
    this.stage = ''
    this.unsavedChanges = false
    this.maxPolygons = 200
    this.isNotesModalOpen = false
    if (this.stage == 'highlight') {
      this.currentOtherWordCategory = document.querySelectorAll('.js-other-word-category-with-name')[0].dataset.otherWordCategoryId
    }

    this.displayLShapedMode = 'edges'
    this.grabScroll = false // to check if hand icon from tool bar is selected ot not // hand tool to scroll image by grabbing
    // https://trello.com/c/Zb15PJtQ/710-adding-shortcuts-for-adding-and-subtracting-area-highlight
    // While drawing rectangle, on pressing particular keys, the mode gets changed to Rectangle+ or Rectangle-, then it gets reverted to original Rectangle mode. This flags helps in maintaining that data
    this.temporaryEditedSliceMode     = '' // '+' , '-', ' '
  }

  #initializeSaveListenersOnPageChange(){
    // Upon selecting any stage or any item from the list, the page is redirected, hence we first save the current changes before redirection
    [...Array.from(document.querySelectorAll('.cropper-stages .nav-pills')), ...Array.from(document.querySelectorAll('.list-group-item'))].forEach((elem) =>
      elem.addEventListener('click', async e => {
        e.preventDefault();
        e.stopPropagation();
        const currentTarget = e.currentTarget;
        if (this.unsavedChanges) {
          cropper.submit().then(() => {
            // TODO: this setTimeout is set for firefox only, because next and previous pages were being loaded before saving current items Ticket# 439
            setTimeout(() => {
              window.location = currentTarget.getAttribute('href');
            }, 500)
          });
        } else {
          window.location = currentTarget.getAttribute('href');
        }
      })
    );
  }

  // Dynamic Values here mean that are based on some values in data attributes
  #initializeDynamicValues(){
    this.isSnapshot           = this.element.dataset.isSnapshot;
    this.stage                = this.element.dataset.stage;
    this.slicingMode          = this.element.dataset.defaultTool;// this.isHighlightStage() ? SLICING_MODES[1] : this.slicingMode
    this.autoSortEntries      = toBool(this.element.dataset.doSorting);
    this.showEntriesNumbering = toBool(this.element.dataset.showNumbering);
    this.dictionaryColumnsDirection = this.element.dataset.dictionaryDirection || 'rtl';
    if(this.hasReorderEntriesButtonTarget)
      this.#updateVisibilityReorderEntriesButton()
    this.maxPolygons = parseInt(this.element.dataset.maxPolygons) || 200;

    this.dictionaryId = this.element.dataset.dictionaryId
    this.pageSetId    = this.element.dataset.setId
    if(this.element.dataset.defaultToolOverall == 'grab'){
      cropper.toggleGrabScrolling();
    }
  }

  #initializeBeforeUnload(){
    // executes when the page is about to be unloaded
    // Unload means redirecting to another page or closing tab
    window.onbeforeunload = () => {
      if (cropper.unsavedChanges)
        return "Changes may not be saved"
    }
  }

  #updateVisibilityReorderEntriesButton(){
    this.reorderEntriesButtonTarget.classList.toggle("collapse", !this.showEntriesNumbering);
  }

  initialize() {
    // Setting Global variable for cropper
    window['cropper'] = this;
    // initializing values
    this.#assignDefaultValues()
    this.#initializeDynamicValues()
    this.#initializeSaveListenersOnPageChange();
    this.#initializeBeforeUnload();
    initStateManagement(this);
    initClipsManagement(this);
    this.setSlicingMode();
    if(this.isHighlightStage()){
      this.initializeOtherWordCategoriesData();
      this.initializeOtherWordRatioButtonsColors()
    }
    this.handleScrollToBottom();
  }

  connect(){
    // sidebars and canvas(es) height
    $('.js-h_drawing_area').each(function(index, item){
      let bottomMargin  = 5; // In px
      let itemNewHeight = window.innerHeight - (item.getBoundingClientRect().top + window.scrollY) - (bottomMargin);
      item.style.height = '' + itemNewHeight + 'px';
    });
    $('.js-h_drawing_area_secondary').each(function(index, item){
      item.style.height = '' + $('.js-canvas-first')[0].clientHeight + 'px';
    });
  }

  handleScrollToBottom() {
    if (localStorage.getItem('scrollToBottom') === 'true') {
      setTimeout(() => {
        const currentCanvas = this.activeCanvas();
        const canvasElement = currentCanvas?.element; // getting canvas dom element to perform scroll operation
        if (canvasElement) { 
          const step = canvasElement.scrollHeight; // total scrollable height
          this.#scroll({
            movingUp: false,
            movingDown: true,
            step: step,
            scrollGradually: false
          });                  
          localStorage.removeItem('scrollToBottom');
        }
      }, 300);
    }
  }

  showItemOfWrittenIndex(e) {
    this.currentItemIndexContainerTarget.contentEditable = true;
    this.currentItemIndexContainerTarget.focus();
    this.currentItemIndexContainerTarget.innerText = '';
  }

  editToWrittenEntryItem(){
    let currentItemIndexContainer = this.currentItemIndexContainerTarget
    let allEntryItems = document.querySelectorAll('.js-selectable-entry')

    if (Number(currentItemIndexContainer.innerText) <= allEntryItems.length ) {
      allEntryItems[currentItemIndexContainer.innerText-1].click()
      currentItemIndexContainer.blur()
    } else {
      allEntryItems[allEntryItems.length - 1].click()
      currentItemIndexContainer.blur()
    }
    currentItemIndexContainer.contentEditable = false
  }

  preventContextMenu(e) {
    e.preventDefault()
  }

  #outlineCanvases() {
    canvasInstances.forEach((canvasInstance) => {
      if (canvasInstance.mainContext.currentPath.length > 0)
        canvasInstance.outline()
    })
  }

  initializeOtherWordCategoriesData(){
    var otherWordOptions = {}

    let otherWordCategoryOptions = document.getElementsByClassName('js-other-word-category-with-name')
    for (var i = 0; i < otherWordCategoryOptions.length; i++) {
      let key = otherWordCategoryOptions[i].dataset['otherWordCategoryId'];
      let value = otherWordCategoryOptions[i].dataset['otherWordCategoryTitle'];
      otherWordOptions[key] = value
    
      // Populating otherWordCategory ids
      this.otherWordIds.push(key)
    }
    this.otherWordCategoryOptions = otherWordOptions;

    return;
  }

  initializeOtherWordRatioButtonsColors(){
    document.querySelectorAll('.js-other-word-category-with-name').forEach((element) => {
      //Trasforming color in rgba format when initializing, input from color picker is recieved in hex format
      let fillStyle = BuildlqColors.hexToRGB(element.dataset.otherWordCategoryColor)
      element.style.background = fillStyle
      this.otherWordsColors.push(fillStyle)//populating other word colors
    })
  }

  // Todo: Need to move/handle some hotkeys for canvas only. Currently moved that action to first canvas
  hotKeys(e) {
    if (pageset_approve_or_return_modal|| submit_page_set_modal || preview_modal || this.isNotesModalOpen || !this.isHighlightStage() && button_scroller)
      return true;
    // Button scroller controller is used for a different purpose in Highlight
    if (!this.isHighlightStage() && button_scroller && isContinuingColumnsModalOpen)
      return true;

    const eventObject = window.event ? event : e
    const enter = 13
    const shift = 16
    const altKey = 18
    const alt = 18
    const esc = 27
    const pgUp = 33
    const pgDown = 34
    const left = 37
    const up = 38
    const right = 39
    const down = 40

    const a = 65
    const b = 66
    const h = 72
    const s = 83
    const v = 86
    const y = 89
    const z = 90
    if (cropper.inRectangleEditMode() && [up, down, right, left, enter].includes(eventObject.keyCode)) {
      e.preventDefault();
      this.handleUpdatingSelectedRectangle(e)
      return
    }

    // Alt Key Cases
    if (eventObject.altKey) {
      if (eventObject.keyCode == s) {
        this.moveToNextEntryType()
      } else if (eventObject.keyCode == a) {
        if (eventObject.altKey) {
          const modes = this.isSliceStage() ? ['line', 'polygon'] : ['polygon', 'rectangle']
          if (this.selectionMode === 'none') {
            const nextMode = modes[(modes.indexOf(this.slicingMode) + 1) % 2];
            document.querySelector('button[data-value="' + nextMode + '"]')?.click();
          }
        }
      }
      // Ctrl Key Cases
    } else if (eventObject.ctrlKey) {
      switch (eventObject.keyCode) {
        case z:
          if (this.undo()){
            this.resetLShapedMode()
            this.redraw()
            this.#outlineCanvases();
            this.setUnsavedChanges(this.currentState().unsavedChanges)
          }
          break
        case y:
          if (this.redo()){
            this.redraw()
            this.#outlineCanvases()
            this.setUnsavedChanges(this.currentState().unsavedChanges)
          }
          break
        case s:
          this.submit();
          break
        case v:
          canvasInstances.forEach((canvasInstance) => {
            canvasInstance.zoomFitWidth()
          })
          break
        // case h:
        //   canvasInstances.forEach((canvasInstance) => {
        //     canvasInstance.zoomFitHeight()
        //   })
        //   break
        case h:
          e.preventDefault()
          if(this.hasGrabButtonTarget) {
            this.GrabButtonTarget.click();
          }      
          break
        case b:
          canvasInstances.forEach((canvasInstance) => {
            canvasInstance.zoomFitWindow()
          })
          break
        case enter:
          this.submitAndMoveToPreviousItem();
          return;
        default:
          break
      }
    } else {
      switch (eventObject.keyCode) {
        case esc:
          cropper.resetCurrentDrawing()
          
          break
        case enter:
          e.preventDefault();
          let markedClips = this.getClips().filter((clip) => clip.markedForLinking);
          if(markedClips.length > 0){
            if(markedClips.length == 1) { break; }

            this.combineClips();
            this.setUnsavedChanges();
            this.autoSortEntries = false;
            this.redraw();  

          } else if (this.lShapeMode){
            canvasInstances.forEach((canvasInstance) => {
              canvasInstance.mainContext.pushClippedData({
                path: this.polygonInEditMode,
                index: this.polygonIndexInEditMode
              })
            })
            if(this.isSplitStage()){
              this.firstCanvas().mainContext.pushClippedData({
                path: this.polygonInEditMode,
                index: this.polygonIndexInEditMode
              })
            }
            this.resetLShapedMode()
            this.pushState(true, this.unsavedChanges)
            this.redraw()
          } else if (this.firstCanvas().mainContext.gridMode) {
            // GRID Mode, Not being used
            const body = {
              rotation: this.firstCanvas().mainContext.currentRotation
            }
            fetch(location.href, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
            }).then(response => location.reload())
          } else {
            // when current item container of badge is not in edit mode
            if (this.currentItemIndexContainerTarget.contentEditable == 'inherit' || this.currentItemIndexContainerTarget.contentEditable == 'false' ) {
              cropper.submitAndMoveToNextItem();
              return
            } else {
              e.stopPropagation();
              const moveDirection = 'next'
              
              let editToWrittenEntryItem = this.currentItemIndexContainerTarget
        
              if ((!/[0-9]/.test(event.key) && event.keyCode !== 13)|| (event.keyCode === 13 && editToWrittenEntryItem.innerText == '')) {
                event.preventDefault();
              } else if (event.keyCode === 13 && /[0-9]/.test(editToWrittenEntryItem.innerText)) {
                this.editToWrittenEntryItem();
              }
    
            }
          }
          break
        case left:
        case right:
        case up:
        case down:
        case pgUp:
        case pgDown:
          e.preventDefault();
          let scrollGradually = false;
          if (this.lShapeMode) {
            switch (eventObject.keyCode) {
              case left:
                var identifier = this.polygonInEditMode[this.lShapeAngleIndex].x
                if (this.displayLShapedMode == 'edges') {
                  this.lShapePointIndexes.forEach((index) => {
                    if (this.polygonInEditMode[index].x === identifier)
                      this.polygonInEditMode[index].x--
                  })
                } else {
                  this.polygonInEditMode[this.lShapeAngleIndex].x--
                }
                break
              case right:
                var identifier = this.polygonInEditMode[this.lShapeAngleIndex].x
                if (this.displayLShapedMode == 'edges') {
                  this.lShapePointIndexes.forEach((index) => {
                    if (this.polygonInEditMode[index].x === identifier)
                      this.polygonInEditMode[index].x++
                  })
                } else {
                  this.polygonInEditMode[this.lShapeAngleIndex].x++
                }
                break
              case up:
              case pgUp:
                var identifier = this.polygonInEditMode[this.lShapeAngleIndex].y
                if (this.displayLShapedMode == 'edges') {
                  this.lShapePointIndexes.forEach((index) => {
                    if (this.polygonInEditMode[index].y === identifier)
                      this.polygonInEditMode[index].y--
                  })
                } else {
                  this.polygonInEditMode[this.lShapeAngleIndex].y--
                }
                break
              case down:
              case pgDown:
                var identifier = this.polygonInEditMode[this.lShapeAngleIndex].y
                if (this.displayLShapedMode == 'edges') {
                  this.lShapePointIndexes.forEach((index) => {
                    if (this.polygonInEditMode[index].y === identifier)
                      this.polygonInEditMode[index].y++
                  })
                } else {
                  this.polygonInEditMode[this.lShapeAngleIndex].y++
                }
                break
              default:
                break
            }
            this.setUnsavedChanges()
            this.redraw()
            return
          } else if ([left, right, up, down].includes(eventObject.keyCode) && !preview_modal) {
            if ([left, up].includes(eventObject.keyCode)) { 
              this.submitAndMoveToPreviousItem();
              return;
            } else {
              this.submitAndMoveToNextItem();
              return;
            }
          }

          // Scrolling && 
          if (this.activeCanvas().mainContext.globalZoomFactor <= 1){
            if(canvasInstances.length <= 1 && eventObject.keyCode == pgDown){ //this check is added to address the issue in this ticket -> https://trello.com/c/acwDOJuV/813-incorrect-navigation-page-down-button-in-initial-slice-redirects-to-next-entry-instead-of-linked-slice
              this.submitAndMoveToNextItem()
              return;
            }
          }
          const lastPoint = this.activeCanvas().mainContext.currentPathLastPoint()
          let step = 0
          if (lastPoint) {
            const lastPointTransformed = this.activeCanvas().mainContext.transformFromOriginalCoordinates(lastPoint)
            switch (eventObject.keyCode) {
              case pgUp:
                step = this.activeCanvas().mainCanvasTarget.height - lastPointTransformed.y
                break
              case pgDown:
                step = lastPointTransformed.y
                break
            }
            step = step / this.activeCanvas().mainContext.globalZoomFactor - 10
          } else {
            // Scrolling current Canvas
            switch (eventObject.keyCode) {
              case pgUp:
                if(this.activeCanvas().mainContext.reachedCanvasTop == true) { // check if reached at top of current canvas 
                  if(canvasInstances.length > 1) { // check if multiple canvases 
                    if(this.currentOnScreenCanvasIndex() == 0) { // if at the top of first canvas of multiple canvases in linked slices case // left arrow button functionality
                      localStorage.setItem('scrollToBottom', 'true'); // checking whether scrolling to the bottom should occur // here localStorage is a web storage API that allows you to store key-value pairs in a web browser
                      this.submitAndMoveToPreviousItem();            
                    }
                    else { // if at the top of canvas but not the first canvas of multiple canvases // instead of left arrow button functionality do custom up button -> in button_scroller_controller showPreviousItem()
                      this.scrollToPreviousCanvas();
                    }
                  }
                  else {// no multiple canvanses just single canvas case // left arrow button functionality
                    localStorage.setItem('scrollToBottom', 'true'); // checking whether scrolling to the bottom should occur // here localStorage is a web storage API that allows you to store key-value pairs in a web browser
                    this.submitAndMoveToPreviousItem();                       
                  }
                }
                break;
              case pgDown:
                if(this.activeCanvas().mainContext.reachedCanvasBottom == true) {// if reached at bottom of current canvas 
                  if(canvasInstances.length > 1) {// check if multiple canvases 
                    if(this.currentOnScreenCanvasIndex() == (canvasInstances.length - 1)) { // if at the bottom of last canvas of multiple canvases in linked slices case // right arrow button functionality
                      this.submitAndMoveToNextItem();
                    } else { // if at the bottom of canvas but not the last canvas of multiple canvases // instead of right arrow button functionality do custom down button -> in button_scroller_controller shownextitem()
                      this.scrollToNextCanvas()
                    }
                  } else { // no multiple canvanses just single canvas case // right arrow button functionality
                    this.submitAndMoveToNextItem();
                  }
                }
                break;
            }
            const pageScrollThroughKeyboardFactor = eventObject.keyCode == pgDown ? this.pageScrollStep() : 0.9;
            scrollGradually = false; // No gradual scrolling
            step = this.activeCanvas().mainCanvasTarget.height * pageScrollThroughKeyboardFactor
            step = step / this.activeCanvas().mainContext.globalZoomFactor
          }
          this.#scroll({
            movingUp: eventObject.keyCode === pgUp,
            movingDown: eventObject.keyCode === pgDown,
            step: step,
            scrollGradually: scrollGradually
          })
          break
        default:
          break
      }
    }
  }

  // This submits the current page if required
  submitAndMoveToNextItem() {
    if (cropper.unsavedChanges) {
      cropper.submit().then(() => {
        // TODO: this is added for firefox browser  
        setTimeout(() => {
          cropper.openNextItemPage()
        }, 500)
      });
    } else { 
      cropper.openNextItemPage()
    }
  }

  scrollToNextCanvas() {
    button_scroller.showNextItem();
  }

  submitAndMoveToPreviousItem() {
    if (cropper.unsavedChanges) {
      cropper.submit().then(() => {
        // TODO: this setTimeout is set for firefox only, because next and previous pages were being loaded before saving current items Ticket# 439
        setTimeout(() => {
          cropper.openPreviousItemPage()
        }, 500)
      });
    } else {
      cropper.openPreviousItemPage()
    }
  }

  scrollToPreviousCanvas() {
    button_scroller.showPreviousItem();
  }

  toggleGrabScrolling(){
    this.grabScroll = !this.grabScroll;
    this.GrabButtonTarget.classList.toggle('active', this.grabScroll);
  }

  inRectangleEditMode(){
    let markedClips = cropper.getClips().filter((clip) => clip.markedToEditPolygon)
    return (markedClips.length == 1)
  }

  selectedRectangleClipForEditing(){
    let markedClips = cropper.getClips().filter((clip) => clip.markedToEditPolygon)
    return markedClips[0];
  }

  moveToNextEntryType(){
    let currentCheckedRadioButtonIndex = null
    // TODO: Use targets
    let allElements = document.querySelectorAll('.js-all-entry-radio-buttons')
    // TODO: Find index
    allElements.forEach((element, index) => {
      if (element.checked) {
        currentCheckedRadioButtonIndex = index
      }
    })
    if(allElements[currentCheckedRadioButtonIndex + 1]){
      allElements[currentCheckedRadioButtonIndex + 1].click()
    } else {
      allElements[0].click()
    }
  }

  // Move this methods in a separate file
  // Name these as OneTime instead of temporary
  resetTemporarySlicingMode(){
    this.temporaryEditedSliceMode = ''
  }

  // this method is being called from button_scroller_controller.js 
  // https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport
  // TODO: DRY
  isElementInViewport (el) {
    let rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  }

  isRectangleToolMode() {
    return cropper.slicingMode == 'rectangle';
  }

  isPolygonToolMode() {
    return cropper.slicingMode == 'polygon';
  }

  setAppendTemporarySlicingMode(){
    this.temporaryEditedSliceMode = '+'
  }

  setDiffTemporarySlicingMode(){
    this.temporaryEditedSliceMode = '-'
  }

  isAnyTemporarySlicingMode(){
    return this.temporaryEditedSliceMode != ''    
  }

  isTemporarySlicingModeAppend(){
    return this.temporaryEditedSliceMode == '+'
  }

  isTemporarySlicingModeDiff(){
    return this.temporaryEditedSliceMode == '-'
  }

  resetCurrentDrawing() {
    cropper.resetTemporarySlicingMode();
    canvasInstances.forEach((canvasInstance) => {
      canvasInstance.resetPath() || canvasInstance.resetRectangle()
    })
  }

  // TODO: this is the part of draw L shape mode but this method can modify all sides instead of L - For rectangle
  handleUpdatingSelectedRectangle(e){
    const left  = 37
    const up    = 38
    const right = 39
    const down  = 40
    const enter = 13

    let selectedRectangleClipForEditing = cropper.selectedRectangleClipForEditing()
    // scale path due to image ratio  
    let scaledPath = canvasInstances[selectedRectangleClipForEditing.canvasIndex].mainContext.scaledPath(selectedRectangleClipForEditing.polygon)
    if (e.keyCode == enter){    
      selectedRectangleClipForEditing.scaledPolygon = scaledPath;
      selectedRectangleClipForEditing.markedToEditPolygon = false
      this.setUnsavedChanges()
      this.redraw()
    } else {
      var polygon = new Polygon(selectedRectangleClipForEditing.polygon)
      let aSide = polygon.aSide()
      let bSide = polygon.bSide()
      let cSide = polygon.cSide()
      let dSide = polygon.dSide()

      // in the following code, calling this.setUnsavedChanges() in every case to alert/remind user to save changes before proceeding to next page.   // ticket ref: https://trello.com/c/ytpXSay0/1227-save-changes-to-rectangle-shape-without-requiring-manual-enter-press  
      if (e.ctrlKey || e.metaKey) { // metaKey key for mac
        switch(e.keyCode){
          case up:
            cSide[0].y--
            cSide[1].y--
            break
          case down:
            cSide[0].y++
            cSide[1].y++
            break
          case left:
            dSide[0].x--
            dSide[1].x--
            break
          case right:
            dSide[0].x++
            dSide[1].x++
            break
        }
        if (!this.isUnsavedChangesIconVisible()) {
          this.setUnsavedChanges();
        }
      } else {
        switch(e.keyCode){
          case up:
            aSide[0].y--
            aSide[1].y--
            break
          case down:
            aSide[0].y++
            aSide[1].y++
            break
          case right:
            bSide[0].x++
            bSide[1].x++
            break
          case left:
            bSide[0].x--
            bSide[1].x--
            break 
        }
        if (!this.isUnsavedChangesIconVisible()) {
          this.setUnsavedChanges();
        }
      }
      selectedRectangleClipForEditing.scaledPolygon = scaledPath;
      this.redraw()
    }
  }

  #scroll({ movingUp, movingDown, step = 1, scrollGradually = false }){
    if (scrollGradually){
      // Keeping scrollGradually in code, but it is not being used in app
      let gradualStep = step * 0.1;
      for(let i = 0; i < 9; i++){

        setTimeout(() => {
          cropper.activeCanvas().zoomInstance.move({
            movingUp: movingUp,
            movingDown: movingDown,
            step: gradualStep
          })
        }, 75 * i);
      }
    } else {
      this.activeCanvas().zoomInstance.move({
        movingUp: movingUp,
        movingDown: movingDown,
        step: step
      })
    }
  }

  pageScrollStep(){
    let canvasContext = cropper.activeCanvas().mainContext;
    let onScreenPaths = canvasContext.slicingPaths.map(slicingPath => slicingPath.path).filter((path) => {
      return canvasContext.isPathInCanvas(path)
    });

    let pageSizeStep = 0.9; // If there are no paths on screen
    if (onScreenPaths.length == 0)
      return pageSizeStep;

    let lowestY = Math.max(...onScreenPaths.flat().map(point => point.y))
    pageSizeStep = ( lowestY - canvasContext.transformedRoot().y ) / ( canvasContext.transformedRoof().y - canvasContext.transformedRoot().y )

    pageSizeStep = pageSizeStep - (0.15)

    if (pageSizeStep <= 0.25 && onScreenPaths.length == 1 || (pageSizeStep < 0))
      pageSizeStep = 0.9;

    return pageSizeStep;
  }

  #assignCanvasesCursor(cursorValue){
    canvasInstances.forEach((canvasInstance) => {
      canvasInstance.hoverCanvasTarget.style.cursor = cursorValue;
    })
  }

  #setCanvasCursor(){
    if (this.slicingMode === 'line')
      this.#assignCanvasesCursor('none');
    else if (this.slicingMode === 'rectangle')
      this.#assignCanvasesCursor('none');
    else
      this.#assignCanvasesCursor('default');
  }

  setSlicingMode(e) {
    this.redraw()
    this.clearCurrentPath();

    const splittedValue = e?.currentTarget.dataset.value.split('-')
    if (splittedValue?.length > 1) {
      [cropper.slicingMode, cropper.selectionMode] = splittedValue
    } else if (splittedValue?.length) {
      cropper.slicingMode = splittedValue[0]
      cropper.selectionMode = 'none'
    }
    this.#setCanvasCursor(e);
    // Adding and removing active classes
    this.sliceViewModeSwitcher()
  }

  sliceViewModeSwitcher(e){
    document.querySelectorAll('.js-slice-view-mode-switcher').forEach(el => el.classList.remove('active'))
    const fullValue = cropper.slicingMode + (cropper.selectionMode === 'none' ? '' : '-' + cropper.selectionMode);
    if (e) {
      e?.currentTarget.classList.add('active');
    } else {
      document.querySelector(`[data-action="click->cropper#setSlicingMode"][data-value="${fullValue}"]`).classList.add('active');
    }
  }

  async submit() {
    switch (this.stage) {
      case 'split':
        await this.submitColumns()
        break
      case 'slice':
        await this.submitRegions()
        break
      case 'highlight':
        await this.submitEntries()
        break
      default:
    }
    // Ideally submitColumns, submitRegions and submitEntries should set these values. But due to some strange reasons, they are not. Hence, adding them here.
    this.setUnsavedChanges(false);
  }

  setEntryType(e) {
    this.redraw()
    this.clearCurrentPath()
    this.currentOtherWordCategory = e.currentTarget.dataset.otherWordCategoryId
  }

  clearCurrentPath(){
    canvasInstances.forEach((canvas_instance) => {
      canvas_instance.mainContext.clearCurrentPath();
    })
  }

  rotate(e) {
    canvasInstances.forEach((canvas_instance) => {
      canvas_instance.rotate(e);
    })
  }

  // TODO: Not being used now, zoom is separate for each canvas
  handleZoom(e) {
    canvasInstances.forEach((canvas_instance) => {
      canvas_instance.handleZoom(e);
    })
  }

  // Currently Slice has only one canvas, but keeping method in cropper to add support for multiple canvases when required
  prepareClipsFromSlices() {
    canvasInstances.forEach((canvas_instance) => {
      canvas_instance.mainContext.prepareClipsFromSlices();
    })
  }

  setUnsavedChanges(value = true) {
    this.unsavedChanges = value
    if (this.currentState().unsavedChanges !== this.unsavedChanges) {
      this.pushState(true, this.unsavedChanges);
    }

    this.unsavedChangesTarget.classList.toggle("d-none", !value);
  }

  isUnsavedChangesIconVisible() {
    return !this.unsavedChangesTarget.classList.contains('d-none')
  }

  // Apply Button -> Upon which submission, columns, regions, etc gets updated
  isApplyButtonLoadingSpinnerVisible() {
    return !this.spinnerTarget.classList.contains('d-none')
  }

  // It manages stage wise snapshots
  // E.g At slice stage, we are at show page of 'Column', so saving snapshot of column
  saveSnapshot() {
    if (this.isUnsavedChangesIconVisible()){
      if (this.isSplitStage()){
        API.post({
          url: `/pages/${this.firstCanvas().pageId}/create_snapshot`
        })
      } else if (this.isSliceStage()) {
        API.post({
          url: `/columns/${this.firstCanvas().columnId}/create_snapshot`
        })
      } else if (this.isHighlightStage()) {
        API.post({
          url: `/regions/${this.firstCanvas().regionId}/create_snapshot`
        })
      }
    }
  }

  /**
   * @param {boolean} makeItemReady Whether item can be taken as done or not
   * @param {('slice'|'highlight'|'type-o'|'type-l')} stages Which stage to make available
   */
  makeAvailable(makeItemReady, ...stages) {
    stages.forEach(stage => {
      document.querySelector('.nav-item.' + stage)?.classList.remove('no-click', 'disabled');
    });
  }

  changeClipType(clip){
    let clipsToUpdate = this.relatedClips(clip)

    // Removing previous clips
    var newClipsToAdd = JSON.parse(JSON.stringify(clipsToUpdate))
    this.removeClip(clip)

    for (var i = 0; i <= newClipsToAdd.length -1 ; i++) { 
      // Setting attributes for new clips
      newClipsToAdd[i].otherWordCategory = cropper.currentOtherWordCategory
      // we will also use this approach for creation 
      // if (cropper.entryType == 'other') {
      //   newClipsToAdd[i].otherWordCategory = cropper.currentOtherWordCategory
      // }
      // Setting id to null so that, it is created as unpersisted clip, and gets created on Saving state
      delete newClipsToAdd[i].id
      newClipsToAdd[i].number = null;
      
      // Adding new clips to canvases, before assinging clips, so that it can be numbered easily
      let canvasIndex = newClipsToAdd[i].canvasIndex;
      canvasInstances[canvasIndex].mainContext.clips.push(newClipsToAdd[i])
      if (i == 0) {
        cropper.assignNumberNewClip(newClipsToAdd[0])  
      } else {
        newClipsToAdd[i].number = newClipsToAdd[0].number  
      }
    } 
  }

  // Removes the clip from any of the canvases
  // If clip is inParts, then deletes both
  removeClip(clip) {
    let clipsToRemove = this.relatedClips(clip)
    
    for (var i = 0; i < clipsToRemove.length; i++) {
      let clipForRemoval = clipsToRemove[i];
      canvasInstances[clipForRemoval.canvasIndex].mainContext.removeClip(clipForRemoval);
    }
  }

  submitColumns() {
    if (this.isApplyButtonLoadingSpinnerVisible()) {
      return;
    }
    return new Promise(resolve => {
      this.firstCanvas().mainContext.clips = this.firstCanvas().mainContext.clips.filter((v, i) => {
        const nextI = i === this.firstCanvas().mainContext.clips.length - 1 ? 0 : i + 1;
        if (i === nextI) return true;
        if (v.polygon.every((point, i) => samePoint(this.firstCanvas().mainContext.clips[nextI].polygon[i], point))) {
          if (v.id && v.id !== this.firstCanvas().mainContext.clips[nextI].id) {
            API.delete({
              url: Routes.dictionary_set_column_path(this.dictionaryId, this.firstCanvas().pageSetId, v.id)
            }).then();
          }
          return false;
        } else {
          return true;
        }
      });
      let requestCount = this.firstCanvas().mainContext.clips.length + this.firstCanvas().mainContext.deletedClips.length
      let successCount = 0;
      if (requestCount) {
        this.showProcessingSpinner()
      }
      const checkLoadingStatus = (succeeded) => {
        requestCount--
        successCount += +!!succeeded;
        if (requestCount <= 0) {
          this.firstCanvas().mainContext.deletedClips = []
          this.hideProcessingSpinner()
          this.setUnsavedChanges(false)
          if (successCount) {
            this.makeAvailable(successCount === this.maxPolygons, 'slice');
          }
          resolve();
        } else {
          this.showProcessingSpinner()
        }
      }

      if (this.autoSortEntries) { this.sortClips(); }
      // Saves current Snapshot if changes present
      this.saveSnapshot()

      try {
        let response = API.put({
          url: Routes.dictionary_set_page_path(this.dictionaryId, this.firstCanvas().pageSetId, this.firstCanvas().pageId),
          data: { page: { columns_order_edited: !this.autoSortEntries } }
        })
      } catch {
        console.log('Update Page Failed')
      }
      this.firstCanvas().mainContext.clips.map(async (clip, index) => {
        let response
        if (clip.id) {
          try {
            response = await API.put({
              url: Routes.dictionary_set_column_path(this.dictionaryId, this.firstCanvas().pageSetId, clip.id),
              data: { column: { points: clip.scaledPolygon, order: clip.number }, image: { data: clip.data } },
            })
            checkLoadingStatus(!!response.id)
          } catch {
            checkLoadingStatus(false);
          }
        } else {
          try {
            response = await API.post({
              url: `${window.location.pathname}/columns`,
              data: { column: { points: clip.scaledPolygon, order: clip.number }, image: { data: clip.data } },
            })
            clip.id = response.column.id
            checkLoadingStatus(!!response.column.id)
          } catch {
            checkLoadingStatus(false);
          }
        }
      })

      this.firstCanvas().mainContext.deletedClips.map(async (clip) => {
        try {
          const response = await API.delete({
            url: Routes.dictionary_set_column_path(this.dictionaryId, this.firstCanvas().pageSetId, clip.id)
          })
          checkLoadingStatus(!!response.column.id)
        } catch {
          checkLoadingStatus(false);
        }
      })
    });
  }

  async submitRegions() {
    if (this.isApplyButtonLoadingSpinnerVisible()) {
      return;
    }

    this.prepareClipsFromSlices();
    // Filter duplicate clips.
    this.firstCanvas().mainContext.clips = this.firstCanvas().mainContext.clips.filter((v, i) => {
      const nextI = i === this.firstCanvas().mainContext.clips.length - 1 ? 0 : i + 1;
      if (i === nextI) return true;
      return !v.polygon.every((point, i) => samePoint(this.firstCanvas().mainContext.clips[nextI].polygon[i], point));
    });

    // to collect data for creation, updating, and deletion of slices.
    let clipsToDelete = [];
    let clipsToUpdate = [];
    let clipsToCreate = [];

    this.getDeletedClips().forEach(clip => {
      clipsToDelete.push({ id: clip.id, pageSetId: clip.pageSetId });
    });

    this.getClips().forEach((clip, index) => {
      const isLastRegion = index === this.getClips().length - 1;
      const clipData = {
        points: clip.scaledPolygon,
        order: index + 1,
        is_last_region: isLastRegion,
        image: { data: clip.data }
      };

      if (clip.id) {
        clipsToUpdate.push({ id: clip.id, pageSetId: clip.pageSetId, ...clipData });
      } else {
        clipsToCreate.push(clipData);
      }
    });

    // determining if changes are present
    const hasChanges = clipsToDelete.length > 0 || clipsToUpdate.length > 0 || clipsToCreate.length > 0;
    
    if (hasChanges) {
      // Saves current Snapshot if changes are present
      this.saveSnapshot();
    }

    // creating a combined request payload.
    const combinedRequestData = {
      column_id: this.firstCanvas().columnId,
      clips_to_delete: clipsToDelete,
      clips_to_update: clipsToUpdate,
      clips_to_create: clipsToCreate
    };

    // show processing spinner
    this.showProcessingSpinner();

    try {
      // making a single API call to handle all operations.
      const response = await $.ajax({
        url: Routes.apply_slice_update_operations_slices_path(),
        type: 'POST',
        data: combinedRequestData,
        dataType: 'json' // Expect JSON response
      });
      
      if (response.success) {
        this.resetDeletedClips();
        this.setUnsavedChanges(false);
        this.makeAvailable(true, 'highlight');

        // assigning IDs(ids that we get from response) to newly created clips.
        const createdSlicesIds = response.items.filter(item => item.action === 'created').map(item => item.id);
        const createdClips = this.getClips().filter(clip => !clip.id);
        createdClips.forEach((clip, index) => {
          if (createdSlicesIds[index]) {
            clip.id = createdSlicesIds[index];
            const slicingPath = this.firstCanvas().mainContext.findRelatedSlicingPath(clip);
            if (slicingPath) {
              slicingPath.id = createdSlicesIds[index];
            }
          }
        });
      } else {
          response.error.forEach(msg => showFlashMessage(msg, 'error', 'float'));
          cropper.hideProcessingSpinner();
          return;
      }
    } catch (error) {
        console.error(err);  
    } finally {
        this.hideProcessingSpinner();
    }
  }

  #filterAndDeleteSimilarClips(){
    canvasInstances.forEach((canvas_instance) => {
      canvas_instance.mainContext.clips = canvas_instance.mainContext.clips.filter((clip, index) => {
        const nextIndex = calculateCircularNextIndex(canvas_instance.mainContext.clips, index)
        if (index === nextIndex)// For the case when there is only one element in array
          return true;
        let nextClip = canvas_instance.mainContext.clips[nextIndex]

        if (areClipsSimilar(clip, nextClip)) {
          const name = 'dictionary_set_' + (clip.entryType === 'other' ? 'other_word' : clip.entryType) + '_path';
          API.delete({
            url: Routes[name](this.dictionaryId, this.pageSetId, clip.id)
          }).then();

          return false;
        } else {
          return true;
        }
      });
    })
  }

  #updateHref(entryId, entryType){
    document.querySelector('.nav-item.type-' + entryType) ? document.querySelector('.nav-item.type-' + entryType).querySelector('a').href = Routes.edit_dictionary_set_entry_path(this.dictionaryId, 'type_' + entryType, this.pageSetId, entryId) : {};
  }
  #createOrUpdateEntry(clip){
    let requestParams;
    let requestData = {
      entry: {
        points: clip.scaledPolygon, assigned_number: clip.number, assigned_sub_number: clip.subNumber, in_parts: clip.inParts
      }, image: {
        data: clip.data
      }
    }
    let type;
    if (clip.id) {
      requestParams = {
        dictionaryId: this.dictionaryId, 
        pageSetId: clip.pageSetId, 
        clipId: clip.id,   
      }
      type = "put"
    } else {
      requestParams = {
        dictionaryId: this.dictionaryId, 
        pageSetId: clip.pageSetId, 
        regionId: clip.regionId,
        
      }
      type = "post"
    }
    let entryRequestData = {
      requestParams : requestParams,
      requestData: requestData,
      type: type
    }
    return entryRequestData;
  }

  #createOrUpdateSubentry(clip){
    let requestParams;
    let requestData = {
      subentry: {
        points: clip.scaledPolygon, assigned_number: clip.number, assigned_sub_number: clip.subNumber, in_parts: clip.inParts,
        region_id: clip.regionId
      }, image: {
        data: clip.data
      }, first_region_id: cropper.firstCanvas().regionId
    }
    let type;
    if (clip.id) {
      requestParams = {
        dictionaryId: this.dictionaryId, 
        pageSetId: clip.pageSetId, 
        clipId: clip.id,
      }
      type = "put";
    } else {
      requestParams = {
        dictionaryId: this.dictionaryId, 
        pageSetId: clip.pageSetId, 
        clipId: clip.id,
      }
      type = "post";
    }

    let subentryRequestData = {
      requestParams : requestParams,
      requestData: requestData,
      type: type,
    }
    return subentryRequestData;
  }

  #createOrUpdateOtherWord(clip){
    let requestParams;
    let requestData = { other_word: { points: clip.scaledPolygon, assigned_number: clip.number, assigned_sub_number: clip.subNumber, in_parts: clip.inParts, other_word_category_id: clip.otherWordCategory, item_order: clip.itemOrder }, image: { data: clip.data } }
    let type;
    if (clip.id) {
      requestParams = {
        dictionaryId: this.dictionaryId, 
        pageSetId: clip.pageSetId, 
        clipId: clip.id,
      }
      type = "put";
    } else {
      requestParams = {
        dictionaryId: this.dictionaryId, 
        pageSetId: clip.pageSetId, 
        regionId: clip.regionId,
      }
      type = "post";
    }

    let otherRequestData = {
      requestParams : requestParams,
      requestData: requestData,
      type: type
    }
    return otherRequestData;
  }

  createCombinedTyping(clip){
    let requestParams;
    let relatedClipIds = [];
    let relatedClips = this.relatedClips(clip)
    relatedClips.forEach(clip => relatedClipIds.push(clip.id))
    let requestData = {
      combined_typing: {
        region_id: clip.regionId
      },
      linked_item_ids: relatedClipIds
    }
    requestParams = {
      dictionaryId: this.dictionaryId, 
      pageSetId: clip.pageSetId, 
      regionId: clip.regionId,
    };
    let combinedTypingRequestData = {
      requestParams : requestParams,
      requestData: requestData
    }
    return combinedTypingRequestData;
  }

  async submitEntries() {
    if (this.isApplyButtonLoadingSpinnerVisible()) {
      return;
    }

    this.#filterAndDeleteSimilarClips();

    let requestCount = this.getClips().length + this.getDeletedClips().length
    let successCount = 0;
    if (requestCount) {
      this.showProcessingSpinner()
    }
    // Saves current Snapshot if changes present
    this.saveSnapshot()
    
    // we are deleting clip first because subentry can be created with entry or without entry
    let otherDeleteRequestData = [];
    this.getDeletedClips().map((clip) => {
      try {
        let requestParams = {
          dictionaryId: this.dictionaryId, 
          pageSetId: clip.pageSetId, 
          clipId: clip.id
        }
    
        let entryRequestData = {
          requestParams : requestParams
        }

          otherDeleteRequestData.push(entryRequestData);
      } catch (err){
        console.error(err);
      }
    })
    
    let clipRegionId;

    let otherPostRequestData = [];
    let otherPutRequestData = [];   
    this.getClips().map((otherClip, i) => {
      try {
        let otherRequestData = this.#createOrUpdateOtherWord(otherClip);
        let otherRequestType = otherRequestData.type;
        if(otherRequestType == "post")
          otherPostRequestData.push(otherRequestData)
        else
          otherPutRequestData.push(otherRequestData)
      } catch (err){
        console.error(err);
      }
    })

    let canvasRegionId = canvasInstances[0].regionId;

    let combinedRequestData = {
      region_id: canvasRegionId,
      create: {
        other: otherPostRequestData,
      },
      update: {
        other: otherPutRequestData,
      },
      delete: {
        other: otherDeleteRequestData,
      }
    }

    let response = await API.post({
      url: Routes.handle_highlighted_entries_highlight_path(),
      data: combinedRequestData
    })

    this.#handleHighlightSubmitResponse(response)
  }

  #handleHighlightSubmitResponse(response){
    if (response.success){
      cropper.resetDeletedClips()
      cropper.hideProcessingSpinner()
      cropper.setUnsavedChanges(false)
      if (response.items.length > 0) {
        cropper.makeAvailable(true, 'type-o');
      }
      
      // Assigning ids to clips
      response.items.forEach((item) => {
        let correspondingClip = this.getClips().find((clip) => {
          return (clip.number == item.number && clip.subNumber == item.sub_number && clip.otherWordCategory == item.otherWordCategoryId)
        })
        correspondingClip.id = item.id
      })
    } else {
      response.error.forEach(msg => showFlashMessage(msg, 'error', 'float'));
      cropper.hideProcessingSpinner()
      // alert("Error: Something went wrong.")
    }
  }

  updateClipNumbers(e) {
    // ////debugger
    this.autoSortEntries = false;
    let controller_instance = this;
    let assignedNumbers = {};
    Object.keys(gon.row_data).forEach(key => {
      const gridId = `item_order_${key}`; // Get the grid ID
      const grid = gridReferences[gridId];
      const gridData = gon.row_data[key];
      gridData.forEach((row, index) => {
        const clip = row.clips[0];

        // finding the corresponding clip in allClips using the clip id & otherWordCategory id
        const clip_on_canvas = controller_instance.getClips('all').find((canvas_clip) => canvas_clip.id === clip.id && canvas_clip.otherWordCategory === clip.otherWordCategoryId);
        // If a matching clip is found, set the clip number
        if (clip_on_canvas) {
            // ////debugger
            // Assign clip number based on the row index + 1
            clip_on_canvas.number = (index + 1);//gridData.indexOf(row) + 1; // This sets the clip number based on its entry index
        }
      });
    });
    // ////debugger
    // Update clip numbers based on assigned numbers
    // for (const key of Object.keys(assignedNumbers)) {
    //     assignedNumbers[key].forEach((clip) => {
    //         ////debugger
    //         clip.number = (key).toString();
    //     });
    // }

    // Optional: Redraw or refresh the grid if needed
    this.redraw();
  }

  updateColumnOrder(e){
    this.autoSortEntries = false;

    let controller_instance = this;
    let assignedNumbers = {};

    // Make method
    (typeof columnsGrid !== 'undefined') && columnsGrid.gridOptions.api.getModel().rowsToDisplay.forEach((row, index) => {
      let clip_number = row.data.clips[0].number;

      let selectedClip = controller_instance.getClips().find((clip) => {
        return clip.id == row.data.clips[0].id;
      })
      assignedNumbers[(row.rowIndex + 1).toString()] = [selectedClip];
    })

    for (const key of Object.keys(assignedNumbers)) {
      assignedNumbers[key].forEach((clip) => {
        clip.number = (key).toString();
      })
    }

    // Redraw updated
    this.redraw();
  }

  // For Column
  async markAsSingleRegion(e) {
    if (!confirm("Are you sure? All existing regions would be deleted")){
      e.preventDefault()
      e.stopPropagation()
      return false
    }

    if (e.currentTarget.checked) {
      await API.post({
        url: `${window.location.pathname}/mark_as_single_region`,
        data: { }
      })
    } else {
      await API.post({
        url: `${window.location.pathname}/destroy_regions`,
        data: { },
      })
    }
    location.reload()
  }

  // For Column
  async markAsContinue(e) {
    await API.post({
      url: `${window.location.pathname}/mark_as_continue`,
      data: {
        checked: e.target.checked
      }
    });
  }

  // For column
  async markAsBreaking(e) {
    await API.post({
      url: `${window.location.pathname}/mark_as_breaking`,
      data: {
        checked: e.target.checked
      }
    });
  }

  // For page_set
  preview(e) {
    let stageUrl = this.previewUrlForStage(this.stage, this.pageSetId)
    let data = {}
    if (this.stage == 'split')
      data['current_page_id'] = this.firstCanvas().pageId
    else if (this.stage == 'slice')
      data['current_column_id'] = this.firstCanvas().columnId
    else if (this.stage == 'highlight')
      data['current_region_id'] = this.firstCanvas().regionId

    $.ajax({
      url: stageUrl,
      type: 'GET',
      data: data
    });
  }

  // Why is it async
  async toggleThumbnailsVisibility(e){
    // TODO: Use separate classes for css and js
    let areThumbnailsVisible = (document.getElementsByClassName("hide_thumbnail").length == 0);
    let updatedThumbnailFlag = !areThumbnailsVisible;
    
    await Rails.ajax({
      type: "PATCH",
      url: "/user_settings/toggle_thumbnail_preview.js",
      data: `thumbnail_flag=${updatedThumbnailFlag}`,
      success: function(){
        location.reload();
      }
    });
  }

  closePreviewModal(){
    preview_modal = undefined
  }

  previewUrlForStage(stage, setId) {
    switch(stage){
    case 'split':
      return Routes.preview_columns_set_path({ id: setId })
      break
    case 'slice':
      return Routes.preview_regions_set_path({ id: setId })
      break
    case 'highlight':
      return Routes.preview_entries_set_path({ id: setId })
      break
    }
  }

  // TODO: What is LShapedMode
  resetLShapedMode() {
    this.lShapeMode = this.lShapeAngleIndex = false
    this.lShapePointIndexes = []
    this.polygonInEditMode = this.polygonIndexInEditMode = false
    this.clear();
  }
  
  // this method toggles the display of clip number on columns in Split stage
  toggleClipNumberDisplayOnColumns() {
    if(cropper.isSplitStage()){
      this.showEntriesNumbering = !this.showEntriesNumbering;

      // updating the data attribute to maintain the state
      this.element.dataset.showNumbering = this.showEntriesNumbering.toString();
      this.redraw();
    }
  }

  toggleEntriesNumberingView(e){
    this.showEntriesNumbering = !this.showEntriesNumbering;

    this.clear();
    this.#updateVisibilityReorderEntriesButton();
    this.redraw();
    API.put({
      url: `/dictionaries/${this.dictionaryId}/sets/${this.firstCanvas().pageSetId}/regions/${this.firstCanvas().regionId}`,
      data: { region: { is_serialized: this.showEntriesNumbering } },
    })

    // To remove focus from the serialize button - Ticket: https://trello.com/c/8MNuBgR8/312-when-regions-serialized-after-combining-images-numbers-are-gone
    // TODO: Might need to add this functionality for all buttons, bcoz enter key is used in canvas
    e.currentTarget.blur();
  }

  loadReorderEntriesContainer(_evt){
    if (this.currentState().unsavedChanges){
      alert('Please apply unsaved changes to load updated entries');
    } else {
      Rails.ajax({
        type: "GET",
        url: `/dictionaries/${this.dictionaryId}/sets/${this.firstCanvas().pageSetId}/regions/${this.firstCanvas().regionId}/reorder_entries`
      });
      canvasInstances.forEach((canvasInstance) => {
        canvasInstance.zoomWidth();
      })
      // this.reorderEntriesRootTarget.classList.toggle("collapse", false);
      dragElement(this.reorderEntriesRootTarget)
    }
  }

  hideReorderEntriesContainer(_evt){
    this.reorderEntriesRootTarget.classList.toggle("collapse", true);
    this.redraw()
  }

  hideReorderColumnsContainer(_evt){
    this.reorderColumnsRootTarget.classList.toggle("collapse", true);
    this.redraw();
  }

  // Highligh
  resetEntriesNumbers(_evt){
    this.autoSortEntries = true;
    this.getClips().forEach((clip) => {
      clip.number = null;
      clip.subNumber = null
      clip.inParts =  null;
      clip.markedForLinking = null;
    });
    this.setUnsavedChanges();
    this.redraw();
    this.reorderEntriesContainerRoot.classList.toggle("collapse", true);
  }

  focusOnClip(evt) {
    let object = evt.currentTarget;
    let clipId = object.dataset['clipId']
    let clipOtherWordCategory = object.dataset['clipOtherWordcategory']

    let selectedClip = this.getClips().find((clip) => clip.otherWordCategory === clipOtherWordCategory && clip.id == clipId)
    selectedClip.clicked = true;
    this.redraw();

    setTimeout(() => {
      selectedClip.clicked = false;
      cropper.redraw();
    }, 1500);
  }

  firstCanvas(){
    return canvasInstances[0];
  }

  // This method is just an alias
  activeCanvas(){
    return this.currentOnScreenCanvas();
  }
  
  currentOnScreenCanvas(){
    let currentCanvasIndex = 0;
    currentCanvasIndex = this.currentOnScreenCanvasIndex()
    return canvasInstances[currentCanvasIndex];
  }

  currentOnScreenCanvasIndex() {
    let currentCanvasIndex = 0;
    let itemIndex = $('.js-canvas-container').toArray().findIndex(item => cropper.isElementInViewport(item))
    if(itemIndex >= 0) { // if element found in the view port
      currentCanvasIndex = itemIndex;
    } else { // if not found, find the previous nearest
        currentCanvasIndex = $('.js-canvas-container').toArray().findIndex(item => item.getBoundingClientRect().y >= 0);
    }
    return currentCanvasIndex;
  }

  // https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport
  isElementInViewport (el) {
    let rect = el.getBoundingClientRect();

    return (
        rect.top >= -50 && //even if el top is -50 above viewport's top it is still in view port
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  }

  // Redraws all the canvases
  redraw(){
    if ((this.isHighlightStage() || this.isSplitStage()) && this.autoSortEntries){
      this.sortClips();
    }

    canvasInstances.forEach((canvas_instance) => {
      canvas_instance.redraw();
    })
  }

  clear(){
    canvasInstances.forEach((canvas_instance) => {
      canvas_instance.hoverContext.clear();
    });
  }

  resetDeletedClips(){
    canvasInstances.forEach((canvas_instance) => {
      canvas_instance.mainContext.deletedClips = []
    })
  }

  // Helper Methods
  showProcessingSpinner(){
    this.spinnerTarget.classList.remove('d-none')
  }

  hideProcessingSpinner(){
    this.spinnerTarget.classList.add('d-none')
  }

  isSplitStage(){
    return this.stage === 'split'
  }

  isSliceStage(){
    return this.stage === 'slice'
  }

  isHighlightStage(){
    return this.stage === 'highlight'
  }

  // For sorting entries in multiple canvases, we add a new attribute in clip assuming that the canvases are horizontally attached, and returns the new coordinates of the whole attached image. Handled only for 2 canvases
  #calculateMultiCanvasPolygon(clip, canvasIndex){
    if(canvasIndex == 0){
      return clip.polygon;
    } else { // for canvasIndex == 1
      let relativePolygon = _.cloneDeep(clip.polygon)
      
      let upperCanvasesHeight = 0;
      for(let i = 0; i < canvasIndex; i++)
        upperCanvasesHeight += canvasInstances[i].mainContext.canvas.clientHeight;
      
      relativePolygon.forEach((point) => {
        point.y += upperCanvasesHeight;
      })
      return relativePolygon;
    }
  }

  // get first element from getClip
  getMainEntryClip(){
    return this.getClips('entry')[0]
  }

  // Get all clips of multiple canvases instances
  getClips(clipType = 'all', {
      withCanvasIndex = false
    } = {})
  { // available options are entry, subentry and other
    let combinedClips = [];
    canvasInstances.forEach((canvasInstance, canvasIndex) => {
      // Adding regions to identify clips of multiple regions
      canvasInstance.mainContext.clips.forEach((clip) => {
        clip.pageSetId = canvasInstance.pageSetId
        clip.regionId = canvasInstance.regionId
        if(true || withCanvasIndex){
          clip.canvasIndex = canvasIndex
          clip.multiCanvasPolygon = this.#calculateMultiCanvasPolygon(clip, canvasIndex)
        }
      })

      let filteredClips = canvasInstance.mainContext.clips
      // if (clipType != 'all'){
      //   filteredClips = filteredClips.filter((clip) => clip.entryType === clipType)
      // }
      combinedClips = combinedClips.concat(filteredClips)
    })
    return combinedClips;
  }

  getDeletedClips() {
    let combinedDeletedClips = [];
    canvasInstances.forEach((canvasInstance) => {
      // Adding regions to identify clips of multiple regions
      canvasInstance.mainContext.deletedClips.forEach((clip) => {
        clip.pageSetId = canvasInstance.pageSetId
        clip.regionId = canvasInstance.regionId
      })
      combinedDeletedClips = combinedDeletedClips.concat(canvasInstance.mainContext.deletedClips)
    })
    return combinedDeletedClips;
  }

  // returns the current selected item from the list
  getItemAdjacentToActiveItem(index){
    let activeListItemIndex
    let itemsList = document.querySelectorAll('.list-group-item')
    for (let i = 0; i < itemsList.length; i++) {
      if (itemsList[i].classList.contains('active')) {
        activeListItemIndex = i;
        break;
      }
    } 

    return itemsList[activeListItemIndex+index]
  }

  // On clicking the next item if present, initializeSaveListenersOnPageChange() is also called
  openNextItemPage(){
    let nextItemListIndex = 1
    cropper.getItemAdjacentToActiveItem(nextItemListIndex)?.click();
  }

  openPreviousItemPage(){
    let previousItemListIndex = -1
    cropper.getItemAdjacentToActiveItem(previousItemListIndex)?.click();
  }
}

// Import these methods from file
const samePoint = (a, b) => a.x.toFixed(2) === b.x.toFixed(2) && a.y.toFixed(2) === b.y.toFixed(2);
const samePolygon = (polygon, secondPolygon) => {
  return polygon.every((point, index) => {
    samePoint(secondPolygon[index], point)
  })
}
const areClipsSimilar = (clip, secondClip) => {
  // clip.id checks whether clip is persisted or not
  return clip.id && samePolygon(clip.polygon, secondClip.polygon) && clip.otherWordCategory === secondClip.otherWordCategory && clip.id !== secondClip.id
}
const calculateCircularNextIndex = (array, currentIndex) => {
  if (currentIndex === array.length - 1){
    return 0;
  } else {
    return currentIndex + 1;
  }
}
