/*
File generated by js-routes 1.4.14
Based on Rails 6.1.1 routes of BuildLQ::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// abandon_page_set_review_index => /page_set/review/abandon(.:format)
  // function(options)
  abandon_page_set_review_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"abandon",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// abandon_set => /sets/:id/abandon(.:format)
  // function(id, options)
  abandon_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"abandon",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// activate_user => /users/:id/activate(.:format)
  // function(id, options)
  activate_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"activate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// active_users_report_reports => /reports/active_users_report(.:format)
  // function(options)
  active_users_report_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"active_users_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// all_payments => /payments/all(.:format)
  // function(options)
  all_payments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_v1_dictionaries => /api/v1/dictionaries(.:format)
  // function(options)
  api_v1_dictionaries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"dictionaries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_v1_dictionary => /api/v1/dictionaries/:id(.:format)
  // function(id, options)
  api_v1_dictionary_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// apply_slice_update_operations_slices => /slices/apply_slice_update_operations(.:format)
  // function(options)
  apply_slice_update_operations_slices_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"slices",false],[2,[7,"/",false],[2,[6,"apply_slice_update_operations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// approve_for_cropping_dictionary => /dictionaries/:id/approve_for_cropping(.:format)
  // function(id, options)
  approve_for_cropping_dictionary_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"approve_for_cropping",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// approve_page_edit_request => /page_edit_requests/:id/approve(.:format)
  // function(id, options)
  approve_page_edit_request_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_edit_requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// approve_page_set_review_index => /page_set/review/approve(.:format)
  // function(options)
  approve_page_set_review_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// approve_set => /sets/:id/approve(.:format)
  // function(id, options)
  approve_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// approved_sets => /sets/approved(.:format)
  // function(options)
  approved_sets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[6,"approved",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// assign_bulk_reviewer_page_set_review_index => /page_set/review/assign_bulk_reviewer(.:format)
  // function(options)
  assign_bulk_reviewer_page_set_review_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"assign_bulk_reviewer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// assign_mass_reviewer_users => /users/assign_mass_reviewer(.:format)
  // function(options)
  assign_mass_reviewer_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"assign_mass_reviewer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// assign_page_set_review_index => /page_set/review/assign(.:format)
  // function(options)
  assign_page_set_review_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"assign",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// assign_set => /sets/:id/assign(.:format)
  // function(id, options)
  assign_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"assign",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// assigned_sets => /sets/assigned(.:format)
  // function(options)
  assigned_sets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[6,"assigned",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// available_to_pick_page_set_review_index => /page_set/review/available_to_pick(.:format)
  // function(options)
  available_to_pick_page_set_review_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"available_to_pick",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// back_to_pending_dictionary => /dictionaries/:id/back_to_pending(.:format)
  // function(id, options)
  back_to_pending_dictionary_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"back_to_pending",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_assign_sets => /sets/bulk_assign(.:format)
  // function(options)
  bulk_assign_sets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[6,"bulk_assign",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_update_users => /users/bulk_update(.:format)
  // function(options)
  bulk_update_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"bulk_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cancel_user_registration => /users/cancel(.:format)
  // function(options)
  cancel_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_page_set_typeo => /page_set/typeo/:dictionary/:page_set/:word_id(.:format)
  // function(dictionary, page_set, word_id, options)
  create_page_set_typeo_path: Utils.route([["dictionary",true],["page_set",true],["word_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"typeo",false],[2,[7,"/",false],[2,[3,"dictionary",false],[2,[7,"/",false],[2,[3,"page_set",false],[2,[7,"/",false],[2,[3,"word_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// create_snapshot_column => /columns/:id/create_snapshot(.:format)
  // function(id, options)
  create_snapshot_column_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"create_snapshot",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_snapshot_page => /pages/:id/create_snapshot(.:format)
  // function(id, options)
  create_snapshot_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"create_snapshot",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_snapshot_region => /regions/:id/create_snapshot(.:format)
  // function(id, options)
  create_snapshot_region_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"regions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"create_snapshot",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_user_users => /users/create_user(.:format)
  // function(options)
  create_user_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"create_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// deactivate_user => /users/:id/deactivate(.:format)
  // function(id, options)
  deactivate_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"deactivate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_regions_dictionary_set_column => /dictionaries/:dictionary_id/sets/:set_id/columns/:id/destroy_regions(.:format)
  // function(dictionary_id, set_id, id, options)
  destroy_regions_dictionary_set_column_path: Utils.route([["dictionary_id",true],["set_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"destroy_regions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// destroy_snapshot_column => /columns/:id/destroy_snapshot(.:format)
  // function(id, options)
  destroy_snapshot_column_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"destroy_snapshot",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_snapshot_page => /pages/:id/destroy_snapshot(.:format)
  // function(id, options)
  destroy_snapshot_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"destroy_snapshot",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_snapshot_region => /regions/:id/destroy_snapshot(.:format)
  // function(id, options)
  destroy_snapshot_region_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"regions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"destroy_snapshot",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dictionaries => /dictionaries(.:format)
  // function(options)
  dictionaries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// dictionary => /dictionaries/:id(.:format)
  // function(id, options)
  dictionary_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dictionary_columns => /dictionaries/:dictionary_id/columns(.:format)
  // function(dictionary_id, options)
  dictionary_columns_path: Utils.route([["dictionary_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"columns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// dictionary_continuing_column => /dictionaries/:dictionary_id/continuing_columns/:id(.:format)
  // function(dictionary_id, id, options)
  dictionary_continuing_column_path: Utils.route([["dictionary_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"continuing_columns",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// dictionary_continuing_columns => /dictionaries/:dictionary_id/continuing_columns(.:format)
  // function(dictionary_id, options)
  dictionary_continuing_columns_path: Utils.route([["dictionary_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"continuing_columns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// dictionary_entries => /:list_type/dictionaries/:dictionary_id/entries(.:format)
  // function(list_type, dictionary_id, options)
  dictionary_entries_path: Utils.route([["list_type",true],["dictionary_id",true],["format",false]], {"list_type":"image"}, [2,[7,"/",false],[2,[3,"list_type",false],[2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// dictionary_page => /dictionaries/:dictionary_id/pages/:id(.:format)
  // function(dictionary_id, id, options)
  dictionary_page_path: Utils.route([["dictionary_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// dictionary_pages => /dictionaries/:dictionary_id/pages(.:format)
  // function(dictionary_id, options)
  dictionary_pages_path: Utils.route([["dictionary_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// dictionary_pages_dictionary_pages => /dictionaries/:dictionary_id/pages/dictionary_pages(.:format)
  // function(dictionary_id, options)
  dictionary_pages_dictionary_pages_path: Utils.route([["dictionary_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"dictionary_pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// dictionary_regions => /dictionaries/:dictionary_id/regions(.:format)
  // function(dictionary_id, options)
  dictionary_regions_path: Utils.route([["dictionary_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"regions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// dictionary_set => /dictionaries/:dictionary_id/sets/:id(.:format)
  // function(dictionary_id, id, options)
  dictionary_set_path: Utils.route([["dictionary_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// dictionary_set_column => /dictionaries/:dictionary_id/sets/:set_id/columns/:id(.:format)
  // function(dictionary_id, set_id, id, options)
  dictionary_set_column_path: Utils.route([["dictionary_id",true],["set_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// dictionary_set_column_regions => /dictionaries/:dictionary_id/sets/:set_id/columns/:column_id/regions(.:format)
  // function(dictionary_id, set_id, column_id, options)
  dictionary_set_column_regions_path: Utils.route([["dictionary_id",true],["set_id",true],["column_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[3,"column_id",false],[2,[7,"/",false],[2,[6,"regions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// dictionary_set_entry => /dictionaries/:dictionary_id/sets/:set_id/entries/:id(.:format)
  // function(dictionary_id, set_id, id, options)
  dictionary_set_entry_path: Utils.route([["dictionary_id",true],["set_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"entries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// dictionary_set_entry_subentries => /dictionaries/:dictionary_id/sets/:set_id/entries/:entry_id/subentries(.:format)
  // function(dictionary_id, set_id, entry_id, options)
  dictionary_set_entry_subentries_path: Utils.route([["dictionary_id",true],["set_id",true],["entry_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"entries",false],[2,[7,"/",false],[2,[3,"entry_id",false],[2,[7,"/",false],[2,[6,"subentries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// dictionary_set_other_word => /dictionaries/:dictionary_id/sets/:set_id/other_words/:id(.:format)
  // function(dictionary_id, set_id, id, options)
  dictionary_set_other_word_path: Utils.route([["dictionary_id",true],["set_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"other_words",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// dictionary_set_other_words => /dictionaries/:dictionary_id/sets/:set_id/other_words(.:format)
  // function(dictionary_id, set_id, options)
  dictionary_set_other_words_path: Utils.route([["dictionary_id",true],["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"other_words",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// dictionary_set_page => /dictionaries/:dictionary_id/sets/:set_id/pages/:id(.:format)
  // function(dictionary_id, set_id, id, options)
  dictionary_set_page_path: Utils.route([["dictionary_id",true],["set_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// dictionary_set_page_columns => /dictionaries/:dictionary_id/sets/:set_id/pages/:page_id/columns(.:format)
  // function(dictionary_id, set_id, page_id, options)
  dictionary_set_page_columns_path: Utils.route([["dictionary_id",true],["set_id",true],["page_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[3,"page_id",false],[2,[7,"/",false],[2,[6,"columns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// dictionary_set_region => /dictionaries/:dictionary_id/sets/:set_id/regions/:id(.:format)
  // function(dictionary_id, set_id, id, options)
  dictionary_set_region_path: Utils.route([["dictionary_id",true],["set_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"regions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// dictionary_set_region_combined_typings => /dictionaries/:dictionary_id/sets/:set_id/regions/:region_id/combined_typings(.:format)
  // function(dictionary_id, set_id, region_id, options)
  dictionary_set_region_combined_typings_path: Utils.route([["dictionary_id",true],["set_id",true],["region_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"regions",false],[2,[7,"/",false],[2,[3,"region_id",false],[2,[7,"/",false],[2,[6,"combined_typings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// dictionary_set_region_entries => /dictionaries/:dictionary_id/sets/:set_id/regions/:region_id/entries(.:format)
  // function(dictionary_id, set_id, region_id, options)
  dictionary_set_region_entries_path: Utils.route([["dictionary_id",true],["set_id",true],["region_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"regions",false],[2,[7,"/",false],[2,[3,"region_id",false],[2,[7,"/",false],[2,[6,"entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// dictionary_set_region_other_words => /dictionaries/:dictionary_id/sets/:set_id/regions/:region_id/other_words(.:format)
  // function(dictionary_id, set_id, region_id, options)
  dictionary_set_region_other_words_path: Utils.route([["dictionary_id",true],["set_id",true],["region_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"regions",false],[2,[7,"/",false],[2,[3,"region_id",false],[2,[7,"/",false],[2,[6,"other_words",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// dictionary_set_subentries => /dictionaries/:dictionary_id/sets/:set_id/subentries(.:format)
  // function(dictionary_id, set_id, options)
  dictionary_set_subentries_path: Utils.route([["dictionary_id",true],["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"subentries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// dictionary_set_subentry => /dictionaries/:dictionary_id/sets/:set_id/subentries/:id(.:format)
  // function(dictionary_id, set_id, id, options)
  dictionary_set_subentry_path: Utils.route([["dictionary_id",true],["set_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"subentries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// dictionary_sets => /dictionaries/:dictionary_id/sets(.:format)
  // function(dictionary_id, options)
  dictionary_sets_path: Utils.route([["dictionary_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// dictionary_subentries => /:list_type/dictionaries/:dictionary_id/subentries(.:format)
  // function(list_type, dictionary_id, options)
  dictionary_subentries_path: Utils.route([["list_type",true],["dictionary_id",true],["format",false]], {"list_type":"image"}, [2,[7,"/",false],[2,[3,"list_type",false],[2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"subentries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// dynamic_translation => /dynamic_translations/:id(.:format)
  // function(id, options)
  dynamic_translation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dynamic_translations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dynamic_translations => /dynamic_translations(.:format)
  // function(options)
  dynamic_translations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dynamic_translations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// ebook => /ebook/:id(.:format)
  // function(id, options)
  ebook_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"ebook",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_api_v1_dictionary => /api/v1/dictionaries/:id/edit(.:format)
  // function(id, options)
  edit_api_v1_dictionary_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_dictionary => /dictionaries/:id/edit(.:format)
  // function(id, options)
  edit_dictionary_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_dictionary_page => /dictionaries/:dictionary_id/pages/:id/edit(.:format)
  // function(dictionary_id, id, options)
  edit_dictionary_page_path: Utils.route([["dictionary_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_dictionary_set_combined_typing => /dictionaries/:dictionary_id/:typing/sets/:set_id/combined_typings/:id/edit(.:format)
  // function(dictionary_id, typing, set_id, id, options)
  edit_dictionary_set_combined_typing_path: Utils.route([["dictionary_id",true],["typing",true],["set_id",true],["id",true],["format",false]], {"typing":"type_o"}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[3,"typing",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"combined_typings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_dictionary_set_entry => /dictionaries/:dictionary_id/:typing/sets/:set_id/entries/:id/edit(.:format)
  // function(dictionary_id, typing, set_id, id, options)
  edit_dictionary_set_entry_path: Utils.route([["dictionary_id",true],["typing",true],["set_id",true],["id",true],["format",false]], {"typing":"type_o"}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[3,"typing",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"entries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_dictionary_set_other_word => /dictionaries/:dictionary_id/:typing/sets/:set_id/other_words/:id/edit(.:format)
  // function(dictionary_id, typing, set_id, id, options)
  edit_dictionary_set_other_word_path: Utils.route([["dictionary_id",true],["typing",true],["set_id",true],["id",true],["format",false]], {"typing":"type_o"}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[3,"typing",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"other_words",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_dictionary_set_subentry => /dictionaries/:dictionary_id/:typing/sets/:set_id/subentries/:id/edit(.:format)
  // function(dictionary_id, typing, set_id, id, options)
  edit_dictionary_set_subentry_path: Utils.route([["dictionary_id",true],["typing",true],["set_id",true],["id",true],["format",false]], {"typing":"type_o"}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[3,"typing",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"subentries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_dynamic_translation => /dynamic_translations/:id/edit(.:format)
  // function(id, options)
  edit_dynamic_translation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dynamic_translations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_page => /pages/:id/edit(.:format)
  // function(id, options)
  edit_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_page_set_typel => /page_set/latin_transcription/:dictionary/:page_set/:word_id/edit(.:format)
  // function(dictionary, page_set, word_id, options)
  edit_page_set_typel_path: Utils.route([["dictionary",true],["page_set",true],["word_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"latin_transcription",false],[2,[7,"/",false],[2,[3,"dictionary",false],[2,[7,"/",false],[2,[3,"page_set",false],[2,[7,"/",false],[2,[3,"word_id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_page_set_typeo => /page_set/typeo/:dictionary/:page_set/:word_id/edit(.:format)
  // function(dictionary, page_set, word_id, options)
  edit_page_set_typeo_path: Utils.route([["dictionary",true],["page_set",true],["word_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"typeo",false],[2,[7,"/",false],[2,[3,"dictionary",false],[2,[7,"/",false],[2,[3,"page_set",false],[2,[7,"/",false],[2,[3,"word_id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_payment_receipt => /payment_receipts/:id/edit(.:format)
  // function(id, options)
  edit_payment_receipt_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payment_receipts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_report_settings_reports => /reports/edit_report_settings(.:format)
  // function(options)
  edit_report_settings_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"edit_report_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_slice => /slices/:id/edit(.:format)
  // function(id, options)
  edit_slice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"slices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user => /users/:id/edit(.:format)
  // function(id, options)
  edit_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_registration => /users/edit(.:format)
  // function(options)
  edit_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_user_settings_users => /users/edit_user_settings(.:format)
  // function(options)
  edit_user_settings_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit_user_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// entries_csv_index => /csv/entries(.:format)
  // function(options)
  entries_csv_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"csv",false],[2,[7,"/",false],[2,[6,"entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// entries_search_index => /search/entries(.:format)
  // function(options)
  entries_search_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[2,[6,"entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// fees_dictionaries => /dictionaries/fees(.:format)
  // function(options)
  fees_dictionaries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[6,"fees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// first_dictionary_set_columns => /dictionaries/:dictionary_id/sets/:set_id/columns/first(.:format)
  // function(dictionary_id, set_id, options)
  first_dictionary_set_columns_path: Utils.route([["dictionary_id",true],["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[6,"first",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// first_dictionary_set_entries => /dictionaries/:dictionary_id/sets/:set_id/entries/first(.:format)
  // function(dictionary_id, set_id, options)
  first_dictionary_set_entries_path: Utils.route([["dictionary_id",true],["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"entries",false],[2,[7,"/",false],[2,[6,"first",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// first_dictionary_set_pages => /dictionaries/:dictionary_id/sets/:set_id/pages/first(.:format)
  // function(dictionary_id, set_id, options)
  first_dictionary_set_pages_path: Utils.route([["dictionary_id",true],["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"first",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// first_dictionary_set_regions => /dictionaries/:dictionary_id/sets/:set_id/regions/first(.:format)
  // function(dictionary_id, set_id, options)
  first_dictionary_set_regions_path: Utils.route([["dictionary_id",true],["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"regions",false],[2,[7,"/",false],[2,[6,"first",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// first_pending_page_set_typel_index => /page_set/latin_transcription/:dictionary/:page_set/first_pending(.:format)
  // function(dictionary, page_set, options)
  first_pending_page_set_typel_index_path: Utils.route([["dictionary",true],["page_set",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"latin_transcription",false],[2,[7,"/",false],[2,[3,"dictionary",false],[2,[7,"/",false],[2,[3,"page_set",false],[2,[7,"/",false],[2,[6,"first_pending",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// first_pending_page_set_typeo_index => /page_set/typeo/:dictionary/:page_set/first_pending(.:format)
  // function(dictionary, page_set, options)
  first_pending_page_set_typeo_index_path: Utils.route([["dictionary",true],["page_set",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"typeo",false],[2,[7,"/",false],[2,[3,"dictionary",false],[2,[7,"/",false],[2,[3,"page_set",false],[2,[7,"/",false],[2,[6,"first_pending",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// first_pending_set_columns => /sets/:set_id/columns/first_pending(.:format)
  // function(set_id, options)
  first_pending_set_columns_path: Utils.route([["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[6,"first_pending",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// first_pending_set_entries => /sets/:set_id/:typing/entries/first_pending(.:format)
  // function(set_id, typing, options)
  first_pending_set_entries_path: Utils.route([["set_id",true],["typing",true],["format",false]], {"typing":"type_o"}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[3,"typing",false],[2,[7,"/",false],[2,[6,"entries",false],[2,[7,"/",false],[2,[6,"first_pending",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// first_pending_set_pages => /sets/:set_id/pages/first_pending(.:format)
  // function(set_id, options)
  first_pending_set_pages_path: Utils.route([["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"first_pending",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// first_pending_set_regions => /sets/:set_id/regions/first_pending(.:format)
  // function(set_id, options)
  first_pending_set_regions_path: Utils.route([["set_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"regions",false],[2,[7,"/",false],[2,[6,"first_pending",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// for_approval_sets => /sets/for_approval(.:format)
  // function(options)
  for_approval_sets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[6,"for_approval",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// handle_highlighted_entries_highlight => /highlight/handle_highlighted_entries(.:format)
  // function(options)
  handle_highlighted_entries_highlight_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"highlight",false],[2,[7,"/",false],[2,[6,"handle_highlighted_entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// hide_user => /users/:id/hide(.:format)
  // function(id, options)
  hide_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"hide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// i_am_active_users => /users/i_am_active(.:format)
  // function(options)
  i_am_active_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"i_am_active",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// impersonate_user => /users/:id/impersonate(.:format)
  // function(id, options)
  impersonate_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"impersonate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// in_progress_sets => /sets/in_progress(.:format)
  // function(options)
  in_progress_sets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[6,"in_progress",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// in_review_page_set_review_index => /page_set/review/in_review(.:format)
  // function(options)
  in_review_page_set_review_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"in_review",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// latest_saved_identical_ottoman_spelling_alternates_page_set_typeo_prepopulation_index => /page_set/typeo_prepopulation/latest_saved_identical_ottoman_spelling_alternates(.:format)
  // function(options)
  latest_saved_identical_ottoman_spelling_alternates_page_set_typeo_prepopulation_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"typeo_prepopulation",false],[2,[7,"/",false],[2,[6,"latest_saved_identical_ottoman_spelling_alternates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// letter_opener_web => /letter_opener
  // function(options)
  letter_opener_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"letter_opener",false]]),
// letter_opener_web.letters => /letter_opener/
  // function(options)
  letter_opener_web_letters_path: Utils.route([], {}, [2,[2,[7,"/",false],[6,"letter_opener",false]],[7,"/",false]]),
// letter_opener_web.clear_letters => /letter_opener/clear(.:format)
  // function(options)
  letter_opener_web_clear_letters_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"letter_opener",false]],[7,"/",false]],[2,[6,"clear",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// letter_opener_web.letter => /letter_opener/:id(/:style)(.:format)
  // function(id, options)
  letter_opener_web_letter_path: Utils.route([["id",true],["style",false],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"letter_opener",false]],[7,"/",false]],[2,[3,"id",false],[2,[1,[2,[7,"/",false],[3,"style",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// letter_opener_web.delete_letter => /letter_opener/:id/delete(.:format)
  // function(id, options)
  letter_opener_web_delete_letter_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"letter_opener",false]],[7,"/",false]],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mark_as_breaking_dictionary_set_column => /dictionaries/:dictionary_id/sets/:set_id/columns/:id/mark_as_breaking(.:format)
  // function(dictionary_id, set_id, id, options)
  mark_as_breaking_dictionary_set_column_path: Utils.route([["dictionary_id",true],["set_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_as_breaking",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// mark_as_continue_dictionary_set_column => /dictionaries/:dictionary_id/sets/:set_id/columns/:id/mark_as_continue(.:format)
  // function(dictionary_id, set_id, id, options)
  mark_as_continue_dictionary_set_column_path: Utils.route([["dictionary_id",true],["set_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_as_continue",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// mark_as_single_region_dictionary_set_column => /dictionaries/:dictionary_id/sets/:set_id/columns/:id/mark_as_single_region(.:format)
  // function(dictionary_id, set_id, id, options)
  mark_as_single_region_dictionary_set_column_path: Utils.route([["dictionary_id",true],["set_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_as_single_region",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// mass_activate_users => /users/mass_activate(.:format)
  // function(options)
  mass_activate_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"mass_activate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mass_deactive_users => /users/mass_deactive(.:format)
  // function(options)
  mass_deactive_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"mass_deactive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mass_delete_users => /users/mass_delete(.:format)
  // function(options)
  mass_delete_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"mass_delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mass_hide_users => /users/mass_hide(.:format)
  // function(options)
  mass_hide_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"mass_hide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mass_unhide_users => /users/mass_unhide(.:format)
  // function(options)
  mass_unhide_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"mass_unhide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_api_v1_dictionary => /api/v1/dictionaries/new(.:format)
  // function(options)
  new_api_v1_dictionary_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_dictionary => /dictionaries/new(.:format)
  // function(options)
  new_dictionary_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_dictionary_page => /dictionaries/:dictionary_id/pages/new(.:format)
  // function(dictionary_id, options)
  new_dictionary_page_path: Utils.route([["dictionary_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_page => /pages/new(.:format)
  // function(options)
  new_page_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_page_edit_request => /page_edit_requests/new(.:format)
  // function(options)
  new_page_edit_request_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_edit_requests",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_payment_receipt => /payment_receipts/new(.:format)
  // function(options)
  new_payment_receipt_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payment_receipts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_payments_income_entry => /payments/income_entries/new(.:format)
  // function(options)
  new_payments_income_entry_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"income_entries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_rails_conductor_inbound_email_source => /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_source_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_slice => /slices/new(.:format)
  // function(options)
  new_slice_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"slices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user => /users/new(.:format)
  // function(options)
  new_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_confirmation => /users/confirmation/new(.:format)
  // function(options)
  new_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_registration => /users/sign_up(.:format)
  // function(options)
  new_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_unlock => /users/unlock/new(.:format)
  // function(options)
  new_user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// next_page_ebook_index => /ebook/next_page(.:format)
  // function(options)
  next_page_ebook_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ebook",false],[2,[7,"/",false],[2,[6,"next_page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// note => /notes/:id(.:format)
  // function(id, options)
  note_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// notes => /notes(.:format)
  // function(options)
  notes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// open_page_ebook_index => /ebook/open_page(.:format)
  // function(options)
  open_page_ebook_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ebook",false],[2,[7,"/",false],[2,[6,"open_page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// output_entries => /output_entries(.:format)
  // function(options)
  output_entries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"output_entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// page => /pages/:id(.:format)
  // function(id, options)
  page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// page_edit_requests => /page_edit_requests(.:format)
  // function(options)
  page_edit_requests_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_edit_requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// page_list_dictionary => /dictionaries/:id/page_list(.:format)
  // function(id, options)
  page_list_dictionary_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"page_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// page_set_bookmark => /page_set/bookmarks/:id(.:format)
  // function(id, options)
  page_set_bookmark_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"bookmarks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// page_set_bookmarks => /page_set/bookmarks(.:format)
  // function(options)
  page_set_bookmarks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"bookmarks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// page_set_histories => /page_set_histories(.:format)
  // function(options)
  page_set_histories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set_histories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// page_set_typel_index => /page_set/latin_transcription/:dictionary/:page_set(.:format)
  // function(dictionary, page_set, options)
  page_set_typel_index_path: Utils.route([["dictionary",true],["page_set",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"latin_transcription",false],[2,[7,"/",false],[2,[3,"dictionary",false],[2,[7,"/",false],[2,[3,"page_set",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// pages => /pages(.:format)
  // function(options)
  pages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// payment_receipt => /payment_receipts/:id(.:format)
  // function(id, options)
  payment_receipt_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payment_receipts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payment_receipts => /payment_receipts(.:format)
  // function(options)
  payment_receipts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payment_receipts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// payments => /payments(.:format)
  // function(options)
  payments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// payments_income_entries => /payments/income_entries(.:format)
  // function(options)
  payments_income_entries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"income_entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pick_page_set_review_index => /page_set/review/pick(.:format)
  // function(options)
  pick_page_set_review_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"pick",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// pick_sets => /sets/pick(.:format)
  // function(options)
  pick_sets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[6,"pick",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// populate_dictionary_ottoman_values_ocr_index => /ocr/populate_dictionary_ottoman_values(.:format)
  // function(options)
  populate_dictionary_ottoman_values_ocr_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ocr",false],[2,[7,"/",false],[2,[6,"populate_dictionary_ottoman_values",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// prev_page_ebook_index => /ebook/prev_page(.:format)
  // function(options)
  prev_page_ebook_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ebook",false],[2,[7,"/",false],[2,[6,"prev_page",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// preview_columns_set => /sets/:id/preview_columns(.:format)
  // function(id, options)
  preview_columns_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview_columns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// preview_entries_set => /sets/:id/preview_entries(.:format)
  // function(id, options)
  preview_entries_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview_entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// preview_regions_set => /sets/:id/preview_regions(.:format)
  // function(id, options)
  preview_regions_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview_regions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// preview_type_l_set => /sets/:id/preview_type_l(.:format)
  // function(id, options)
  preview_type_l_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview_type_l",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// preview_type_o_set => /sets/:id/preview_type_o(.:format)
  // function(id, options)
  preview_type_o_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"preview_type_o",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// preview_view_component => /rails/view_components/*path(.:format)
  // function(path, options)
  preview_view_component_path: Utils.route([["path",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"view_components",false],[2,[7,"/",false],[2,[5,[3,"path",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// preview_view_components => /rails/view_components(.:format)
  // function(options)
  preview_view_components_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"view_components",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_blob_representation => /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_blob_representation_proxy => /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_proxy_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_sources => /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
  // function(options)
  rails_conductor_inbound_email_sources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_info => /rails/info(.:format)
  // function(options)
  rails_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_info_properties => /rails/info/properties(.:format)
  // function(options)
  rails_info_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_info_routes => /rails/info/routes(.:format)
  // function(options)
  rails_info_routes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"routes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_mailers => /rails/mailers(.:format)
  // function(options)
  rails_mailers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"mailers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_service_blob_proxy => /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_proxy_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// ready_for_pick_sets => /sets/ready_for_pick(.:format)
  // function(options)
  ready_for_pick_sets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[6,"ready_for_pick",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reject_page_edit_request => /page_edit_requests/:id/reject(.:format)
  // function(id, options)
  reject_page_edit_request_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_edit_requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reject",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// remove_mass_reviewer_users => /users/remove_mass_reviewer(.:format)
  // function(options)
  remove_mass_reviewer_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"remove_mass_reviewer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reorder_columns_page => /pages/:id/reorder_columns(.:format)
  // function(id, options)
  reorder_columns_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reorder_columns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reorder_entries_dictionary_set_region => /dictionaries/:dictionary_id/sets/:set_id/regions/:id/reorder_entries(.:format)
  // function(dictionary_id, set_id, id, options)
  reorder_entries_dictionary_set_region_path: Utils.route([["dictionary_id",true],["set_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"regions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reorder_entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// reset_column => /columns/:id/reset(.:format)
  // function(id, options)
  reset_column_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"columns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reset_dictionary_set_combined_typing => /dictionaries/:dictionary_id/:typing/sets/:set_id/combined_typings/:id/reset(.:format)
  // function(dictionary_id, typing, set_id, id, options)
  reset_dictionary_set_combined_typing_path: Utils.route([["dictionary_id",true],["typing",true],["set_id",true],["id",true],["format",false]], {"typing":"type_o"}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[3,"typing",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"combined_typings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// reset_dictionary_set_entry => /dictionaries/:dictionary_id/:typing/sets/:set_id/entries/:id/reset(.:format)
  // function(dictionary_id, typing, set_id, id, options)
  reset_dictionary_set_entry_path: Utils.route([["dictionary_id",true],["typing",true],["set_id",true],["id",true],["format",false]], {"typing":"type_o"}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[3,"typing",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"entries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// reset_dictionary_set_other_word => /dictionaries/:dictionary_id/:typing/sets/:set_id/other_words/:id/reset(.:format)
  // function(dictionary_id, typing, set_id, id, options)
  reset_dictionary_set_other_word_path: Utils.route([["dictionary_id",true],["typing",true],["set_id",true],["id",true],["format",false]], {"typing":"type_o"}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[3,"typing",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"other_words",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// reset_dictionary_set_subentry => /dictionaries/:dictionary_id/:typing/sets/:set_id/subentries/:id/reset(.:format)
  // function(dictionary_id, typing, set_id, id, options)
  reset_dictionary_set_subentry_path: Utils.route([["dictionary_id",true],["typing",true],["set_id",true],["id",true],["format",false]], {"typing":"type_o"}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[3,"typing",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"subentries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// reset_highlight_page_set_reset => /page_set/reset/:id/reset_highlight(.:format)
  // function(id, options)
  reset_highlight_page_set_reset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"reset",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset_highlight",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// reset_ottoman_spelling_page_set_typeo => /page_set/typeo/:dictionary/:page_set/:word_id/reset(.:format)
  // function(dictionary, page_set, word_id, options)
  reset_ottoman_spelling_page_set_typeo_path: Utils.route([["dictionary",true],["page_set",true],["word_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"typeo",false],[2,[7,"/",false],[2,[3,"dictionary",false],[2,[7,"/",false],[2,[3,"page_set",false],[2,[7,"/",false],[2,[3,"word_id",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// reset_page => /pages/:id/reset(.:format)
  // function(id, options)
  reset_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reset_page_set_typel => /page_set/latin_transcription/:dictionary/:page_set/:word_id/reset(.:format)
  // function(dictionary, page_set, word_id, options)
  reset_page_set_typel_path: Utils.route([["dictionary",true],["page_set",true],["word_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"latin_transcription",false],[2,[7,"/",false],[2,[3,"dictionary",false],[2,[7,"/",false],[2,[3,"page_set",false],[2,[7,"/",false],[2,[3,"word_id",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// reset_region => /regions/:id/reset(.:format)
  // function(id, options)
  reset_region_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"regions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reset_slice_page_set_reset => /page_set/reset/:id/reset_slice(.:format)
  // function(id, options)
  reset_slice_page_set_reset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"reset",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset_slice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// reset_split_page_set_reset => /page_set/reset/:id/reset_split(.:format)
  // function(id, options)
  reset_split_page_set_reset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"reset",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset_split",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// reset_type_l_page_set_reset => /page_set/reset/:id/reset_type_l(.:format)
  // function(id, options)
  reset_type_l_page_set_reset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"reset",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset_type_l",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// reset_type_o_page_set_reset => /page_set/reset/:id/reset_type_o(.:format)
  // function(id, options)
  reset_type_o_page_set_reset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"reset",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset_type_o",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// return_page_set_review_index => /page_set/review/return(.:format)
  // function(options)
  return_page_set_review_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"return",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// return_set => /sets/:id/return(.:format)
  // function(id, options)
  return_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"return",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// returned_sets => /sets/returned(.:format)
  // function(options)
  returned_sets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[6,"returned",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reviews_submitted_page_set_review_index => /page_set/review/reviews_submitted(.:format)
  // function(options)
  reviews_submitted_page_set_review_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"reviews_submitted",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// search_output => /search_output/:id(.:format)
  // function(id, options)
  search_output_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"search_output",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// search_output_index => /search_output(.:format)
  // function(options)
  search_output_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"search_output",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// search_word_ebook_index => /ebook/search_word(.:format)
  // function(options)
  search_word_ebook_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ebook",false],[2,[7,"/",false],[2,[6,"search_word",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sets => /sets(.:format)
  // function(options)
  sets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sets_report_reports => /reports/sets_report(.:format)
  // function(options)
  sets_report_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"sets_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// show_searched_users_users => /users/show_searched_users(.:format)
  // function(options)
  show_searched_users_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"show_searched_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sidekiq_web => /sidekiq
  // function(options)
  sidekiq_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"sidekiq",false]]),
// sign_up_stage_validator_users => /users/sign_up_stage_validator(.:format)
  // function(options)
  sign_up_stage_validator_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_up_stage_validator",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// slice => /slices/:id(.:format)
  // function(id, options)
  slice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"slices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// slices => /slices(.:format)
  // function(options)
  slices_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"slices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// stop_impersonating_users => /users/stop_impersonating(.:format)
  // function(options)
  stop_impersonating_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"stop_impersonating",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_page_set_review_index => /page_set/review/submit(.:format)
  // function(options)
  submit_page_set_review_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"submit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// submit_set => /sets/:id/submit(.:format)
  // function(id, options)
  submit_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"submit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// toggle_display_complete_menu_user_settings => /user_settings/toggle_display_complete_menu(.:format)
  // function(options)
  toggle_display_complete_menu_user_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user_settings",false],[2,[7,"/",false],[2,[6,"toggle_display_complete_menu",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// toggle_presentation_mode_users => /users/toggle_presentation_mode(.:format)
  // function(options)
  toggle_presentation_mode_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"toggle_presentation_mode",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// toggle_thumbnail_preview_user_settings => /user_settings/toggle_thumbnail_preview(.:format)
  // function(options)
  toggle_thumbnail_preview_user_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user_settings",false],[2,[7,"/",false],[2,[6,"toggle_thumbnail_preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// type_dictionary_set_combined_typing => /dictionaries/:dictionary_id/:typing/sets/:set_id/combined_typings/:id/type(.:format)
  // function(dictionary_id, typing, set_id, id, options)
  type_dictionary_set_combined_typing_path: Utils.route([["dictionary_id",true],["typing",true],["set_id",true],["id",true],["format",false]], {"typing":"type_o"}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[3,"typing",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"combined_typings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// type_dictionary_set_entry => /dictionaries/:dictionary_id/:typing/sets/:set_id/entries/:id/type(.:format)
  // function(dictionary_id, typing, set_id, id, options)
  type_dictionary_set_entry_path: Utils.route([["dictionary_id",true],["typing",true],["set_id",true],["id",true],["format",false]], {"typing":"type_o"}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[3,"typing",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"entries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// type_dictionary_set_other_word => /dictionaries/:dictionary_id/:typing/sets/:set_id/other_words/:id/type(.:format)
  // function(dictionary_id, typing, set_id, id, options)
  type_dictionary_set_other_word_path: Utils.route([["dictionary_id",true],["typing",true],["set_id",true],["id",true],["format",false]], {"typing":"type_o"}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[3,"typing",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"other_words",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// type_dictionary_set_subentry => /dictionaries/:dictionary_id/:typing/sets/:set_id/subentries/:id/type(.:format)
  // function(dictionary_id, typing, set_id, id, options)
  type_dictionary_set_subentry_path: Utils.route([["dictionary_id",true],["typing",true],["set_id",true],["id",true],["format",false]], {"typing":"type_o"}, [2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[3,"typing",false],[2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[3,"set_id",false],[2,[7,"/",false],[2,[6,"subentries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// type_l_page_set_payment_preview => /page_set/payment_preview/:id/type_l(.:format)
  // function(id, options)
  type_l_page_set_payment_preview_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"payment_preview",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"type_l",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// type_o_page_set_payment_preview => /page_set/payment_preview/:id/type_o(.:format)
  // function(id, options)
  type_o_page_set_payment_preview_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"payment_preview",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"type_o",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// typing_item_context_page_set_context_index => /page_set/context/typing_item_context(.:format)
  // function(options)
  typing_item_context_page_set_context_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"context",false],[2,[7,"/",false],[2,[6,"typing_item_context",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// unassign_bulk_reviewer_page_set_review_index => /page_set/review/unassign_bulk_reviewer(.:format)
  // function(options)
  unassign_bulk_reviewer_page_set_review_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"unassign_bulk_reviewer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// unassign_page_set_review_index => /page_set/review/unassign(.:format)
  // function(options)
  unassign_page_set_review_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"page_set",false],[2,[7,"/",false],[2,[6,"review",false],[2,[7,"/",false],[2,[6,"unassign",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// unassigned_sets => /sets/unassigned(.:format)
  // function(options)
  unassigned_sets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sets",false],[2,[7,"/",false],[2,[6,"unassigned",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// unhide_user => /users/:id/unhide(.:format)
  // function(id, options)
  unhide_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unhide",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_page_number_page => /pages/:id/update_page_number(.:format)
  // function(id, options)
  update_page_number_page_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"pages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_page_number",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_report_settings_reports => /reports/update_report_settings(.:format)
  // function(options)
  update_report_settings_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"update_report_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_user_settings_users => /users/update_user_settings(.:format)
  // function(options)
  update_user_settings_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"update_user_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user => /users/:id(.:format)
  // function(id, options)
  user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_confirmation => /users/confirmation(.:format)
  // function(options)
  user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_registration => /users(.:format)
  // function(options)
  user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_unlock => /users/unlock(.:format)
  // function(options)
  user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users => /users(.:format)
  // function(options)
  users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// users_payments => /payments/users(.:format)
  // function(options)
  users_payments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// with_subentries_dictionary_entries => /:list_type/dictionaries/:dictionary_id/entries/:view_mode(.:format)
  // function(list_type, dictionary_id, view_mode, options)
  with_subentries_dictionary_entries_path: Utils.route([["list_type",true],["dictionary_id",true],["view_mode",true],["format",false]], {"list_type":"image","view_mode":"with_subentries"}, [2,[7,"/",false],[2,[3,"list_type",false],[2,[7,"/",false],[2,[6,"dictionaries",false],[2,[7,"/",false],[2,[3,"dictionary_id",false],[2,[7,"/",false],[2,[6,"entries",false],[2,[7,"/",false],[2,[3,"view_mode",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, null, result);
  }

  return result;

}).call(this);
