import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [
    'ottoInput',
    'latInput',
    'ottoText',
    'ottoTextCorrected',
    'ottoInputCorrected',
    'alternateTextField',
    'latinInputCorrected',
    'latinValueStatus', // TODO: needs to be removed
    'toBeCheckedCheckBox',
    'misspelledCheckBox',
    'form',
    'contextViewBtn',
    'startQuoteBtn',
    'endQuoteBtn',
    'addAlternateBtn',
    'formSubmitButton', // used in typing forms(edit partials) for ajax call to avoid sidebar items reload on item click
    'returnOrApproveModal', // TODO: this target is common for cropper controller & typing_form controller, find & use some js module approach for common attributes/functionalities
    'currentItemIndexContainer',
    'simpleStarIcon',
    'goldStarIcon',
    'bookmarkToggle',
    'markedAsVulgar',
    'markedAsOriginal',
    'markedAsVulgarCorrected',
    'markedAsOriginalCorrected'
  ];

  initialize() {
    window['typing_form'] = this
    this.bookmarkToggleTarget.classList.remove('collapse');
    // to keep on toggle of bookmark
    if (isEnabledDisplayOnlyBookmarkedItems) {
      this.bookmarkToggleTarget.checked = true
    }
    this.typing_stage = this.element.dataset['typing']
    this.typing_for = this.element.dataset['objectType']
    this.current_item_id = this.element.dataset['typingFormItemId'] // id of currently active item
    // Changing tabs quickly with keys had a weird issue
    this.pausedChangingPreviewItem = false
    this.currentItemNotes = parseInt(this.element.dataset['currentItemNotesCount'])
    // this.isValueLatinPresent = JSON.parse(this.element.dataset['valueLatinPresent?']);
    this.validOttomanCharacters = JSON.parse(this.element.dataset['validOttomanCharacters']) // Space is optionally allowed in dictionary setting
    this.validOttomanCharactersForAlternates = JSON.parse(this.element.dataset['validOttomanCharactersForAlternates'] || '{}');
    this.validLatinCharacters = [' '].concat(JSON.parse(this.element.dataset['validLatinCharacters'])) // Adding <space> as an allowed character    
    this.otherWordIds = JSON.parse(this.element.dataset['otherWordIds'])
    this.otherWordsColors = JSON.parse(this.element.dataset['otherWordColors'])
    this.otherWordsColors.forEach((color, index) => {
      let fillStyle = BuildlqColors.hexToRGB(color)
      this.otherWordsColors[index] = fillStyle; //populating other word colors
    })


    this.setReturnOrApproveModalStatus();
    // To have focus on the field, and not on the active image links

    // to show duplicate alternate fields(with comma separated values)if root leter toggle value is on initially when page loads
    this.showDuplicateFieldsForRootLeteredAlternates();

    // adding event listners to both  actual and duplicate alternate fields 
    $(document).on('focus', '.js-duplicate-field', this.showOriginalAndHideDuplicateAlternateIfRootLtr.bind(this));
    $(document).on('blur', '.js-otto-field-for-alternates', this.showDuplicateAndHideOriginalAlternateIfRootLtr.bind(this));

    this.setCursorFocus()
    setTimeout(() => {
      this.setCursorFocus()
    }, 50);
    setTimeout(() => {
      this.setCursorFocus()
    }, 100);
    this.isNotesModalOpen = false;

    // TODO: Move to connect
    let modalElement = document.getElementById("js-preview-modal");
    modalElement.addEventListener("hide.bs.modal", () => {
      preview_modal = undefined
    })


    // // TODO: move to latin history controller when controller created 
    // document.body.addEventListener('shown.bs.modal', function(event)  {
    //   if(event.target.classList.contains('js-latin-history-modal')){
    //     isLatinHistoryModalOpen = true;
    //   }
    // });

    // // TODO: move to latin history controller when controller created
    // document.body.addEventListener('hide.bs.modal', function(event)  {
    //   if(event.target.classList.contains('js-latin-history-modal')){
    //     isLatinHistoryModalOpen = false;
    //   }
    // });

   //hiding add alternative buttons of simple ottoman value if item is misspelled.
    if(this.hasMisspelledCheckBoxTarget && this.misspelledCheckBoxTarget.checked) {
      $('.js-add-alternative-btn.js-simple-add-alt-btn').addClass('d-none');
    }

    // removing this class so alt + enter hotkeys won't focus on hidden corrected ottoman value fields
    if(this.hasMisspelledCheckBoxTarget && !this.misspelledCheckBoxTarget.checked) {
      $('.js-corrected-ottoman-field').removeClass('js-text-field');
    }

    this.initializeDragAndDropInAlternativeEntries(".js-simple-sortable-values", ".js-simple-values-sortable-container");
    this.initializeDragAndDropInAlternativeEntries(".js-corrected-sortable-values", ".js-corrected-values-sortable-container");
  }

  // This method makes given divs moveable
  initializeDragAndDropInAlternativeEntries(moveable_class, containment_class) {
    const _this = this;

    $(moveable_class).each(function() {
      $(this).sortable({
        axis: 'y',
        handle: ".js-drag-handle",
        containment: $(this).closest(containment_class),
        tolerance: "pointer",
        update: function(event, ui){
          _this.updateAddAltBtnsAndAssignedNumberFields(event, ui);
        }
      });
    });
  }

  // Helper method to update the identifiers and assigned numbers of alternative fields
  // Summary:
  // This method first determines the position of the field immediately above the moved field after it has been repositioned.
  // It then increments the assigned values and identifiers by 1 for all fields located below this above field.
  // Subsequently, it updates the assigned value and identifier of the moved field.
  // If the moved field is placed at the top, its new assigned value and identifier will be set to 1.
  updateAddAltBtnsAndAssignedNumberFields(e, ui) {
    const movedAltField               = ui.item;
    // Find the field with class 'nested-form-wrapper' above the moved field
    const aboveMovedTextField         = movedAltField.prevAll('.nested-form-wrapper').first();
    const aboveMovedTextFieldPosition = aboveMovedTextField.find('button[data-add-alternative-btn-identifier]').attr('data-add-alternative-btn-identifier');
    
    // update assigned value and position identifier of fields that are located below aboveMovedTextField
    this.updateAddAltBtnsAndAssignedValueFieldsAfterSelectedField(aboveMovedTextFieldPosition);
    
    // update assigned value and position identifier of moved field
    let movedAltFieldUpdatedPosition     = parseInt(aboveMovedTextFieldPosition) + 1;
    const movedAltFieldPrevPosition      = movedAltField.find('button[data-add-alternative-btn-identifier]').attr('data-add-alternative-btn-identifier');
    const movedAltFieldAddAlternativeBtn = movedAltField.find('button[data-add-alternative-btn-identifier]')[0];
   
   // when field is moved to the top then movedAltFieldUpdatedPosition will be null
    if (isNaN(movedAltFieldUpdatedPosition)) {
      movedAltFieldUpdatedPosition = 1
    }

    this.setAddAltBtnIdentifier(movedAltFieldAddAlternativeBtn, movedAltFieldUpdatedPosition);
    this.updateAltEntryAssignedNumber(movedAltFieldPrevPosition, movedAltFieldUpdatedPosition);      
  }

  handleOriginalOrVulgarToggle(e){
    let parentElement = e.currentTarget.closest('.js-root-parent-container')
    if (e.currentTarget.dataset.typingFormTarget == 'markedAsVulgar') {
      parentElement.querySelector('.js-original-toggle').checked = false
    } else {
      parentElement.querySelector('.js-vulgar-toggle').checked = false
    } 
  }

  // it is called when page is reloaded to set initial values(when no neither focus/blur event occured initially) of alternate text fields actual alternate fields/ duplicate alternate fields based on R.Ltr. Toggle saved value
  showDuplicateFieldsForRootLeteredAlternates() {
    const alternateInputFieldContainers = document.querySelectorAll('.entries-input');
    alternateInputFieldContainers.forEach(container => {
      const rootLetterToggle = container.querySelector('.js-root-letter-toggle');
      const relatedTextField = container.querySelector('.js-otto-field-for-alternates');
    
      if (rootLetterToggle && relatedTextField) {
        if (rootLetterToggle.checked) {
          const event = { target: relatedTextField };
          this.showDuplicateAndHideOriginalAlternateIfRootLtr(event);
        } else {
          const relatedDuplicateField = container.querySelector('.js-duplicate-field');
          if (relatedDuplicateField) {
            const event = { target: relatedDuplicateField };
            this.showOriginalAndHideDuplicateAlternateIfRootLtr(event);
          }
        }
      }
    });
  }

  // this method adds comma between characters of passed text field value making sure that there are no more than one comma btw chars in case text already have comma separation
  getTextWithCommaBetweenCharacters(textFieldValue) {
    let textWithCommas = '';
    for (let i = 0; i < textFieldValue.length; i++) {
      textWithCommas += textFieldValue[i];
      if (textFieldValue[i] !== '،' && textFieldValue[i+1] !== '،' && i < textFieldValue.length - 1) {
        textWithCommas += '،';
      }
    }
    return textWithCommas;
  }

  // this method is called when cursor goes out of an duplicate alternate text field, this method checks if R.Ltr. toggle is on for an alternate text field, if it is on then it hides the duplicate field with comma separated value as cursor is not focused here and displays the actual alternate input field
  showOriginalAndHideDuplicateAlternateIfRootLtr(event) {
    const duplicateField = event.target;
    const container = duplicateField.closest('.entries-input');
    if (container) {
      const textField = container.querySelector('.js-otto-field-for-alternates');
      if (textField) {
        duplicateField.style.display = 'none';
        textField.style.display = 'block';
        textField.focus();
      }
    }
  }

  // this method is called when cursor goes in alternate text field, this method checks if R.Ltr. toggle is on for an alternate text field, if it is on then it displays a duplicate alternate field with comma separated values
  showDuplicateAndHideOriginalAlternateIfRootLtr(event) {
    const textField = event.target;
    const container = textField.closest('.entries-input'); // Find the closest specific container
    if (container) {
      const rootLetterToggle = container.querySelector('.js-root-letter-toggle');
      if (rootLetterToggle && rootLetterToggle.checked) {
        const textWithCommas = this.getTextWithCommaBetweenCharacters(textField.value);
        const duplicateField = container.querySelector('.js-duplicate-field');
        if (duplicateField) {
          duplicateField.textContent = textWithCommas;
          // hiding the original alternate text input field and displaying duplicate field with comma separated values
          textField.style.display = 'none';
          duplicateField.style.display = 'block';
        }
      }
    }
  }

  handleRootLetterToggle(e){
    let relatedRootToggle = e.currentTarget.closest('.js-root-parent-container').querySelector('.marked-to-root-container')
    let relatedTextField  = e.currentTarget.closest('.entries-input').querySelector('.js-otto-field-for-alternates');
    if(e.currentTarget.checked) {
      // if Root Ltr. toggle is turned on then Root toggle will also be turned on and it(the simple Root) will be read only untill Root Ltr. is on
      relatedRootToggle.checked = true
      // this method is called here due to the requirement that harakat should be removed if root toggle is on and here Root Letter toggle is also making Root toggle on
      this.removeHarakatCharsFromTextFieldForRoot(e);
      // for read only when root toggle on
      relatedRootToggle.onclick = () => false;
      // if Root Ltr. toggle is on, then we will show the comma separated values
      if (relatedTextField) {
        const event = { target: relatedTextField }; // making this event because method showDuplicateAndHideOriginalAlternateIfRootLtr(e) takes events on text input fields
        this.showDuplicateAndHideOriginalAlternateIfRootLtr(event);
      }
    } else {
      // if Root Ltr. toggle is turned off then Root toggle will also be turned off and read only from Root will be removed
      relatedRootToggle.checked = false
      relatedRootToggle.onclick = () => true;
      // getting duplicate field (duplicate with comma separated values) to pass it as event to method showOriginalAndHideDuplicateAlternateIfRootLtr(e)
      let relatedDuplicateField = e.currentTarget.closest('.entries-input').querySelector('.js-duplicate-field');
      if (relatedDuplicateField) {
          const event = { target: relatedDuplicateField };
          this.showOriginalAndHideDuplicateAlternateIfRootLtr(event);
      }
      this.validOttomanCharactersForAlternates = JSON.parse(this.element.dataset['validOttomanCharactersForAlternates'])
    }
  }

  toggleBookmark(e){
    let url = null
    let url_type = null
    let data = {
      item_id: this.current_item_id,
      item_type: this.typing_for,
      typing: this.typing_stage
    };

    if (e.currentTarget.classList.contains('gold-star')) {
      this.goldStarIconTarget.classList.add('collapse')
      this.simpleStarIconTarget.classList.remove('collapse')
      document.querySelector('.js-selectable-entry.active').querySelector('.js-typing-list-item').dataset.starType = false
      
      url = '/page_set/bookmarks/' + this.current_item_id
      url_type = 'DELETE'
    } else {
      this.goldStarIconTarget.classList.remove('collapse')
      this.simpleStarIconTarget.classList.add('collapse')
      document.querySelector('.js-selectable-entry.active').querySelector('.js-typing-list-item').dataset.starType = true
      
      url = '/page_set/bookmarks'
      url_type = 'POST'
    }
    
    $.ajax({
      url: url ,
      type: url_type,
      data: data
    });
  }

  toggleBookmarkedItems(e){
    let allEntryItems = document.querySelectorAll('.js-selectable-entry')
    if (this.bookmarkToggleTarget.checked) {
      isEnabledDisplayOnlyBookmarkedItems = true
      allEntryItems.forEach((entry) => {
        if(entry.querySelector('.js-typing-list-item').dataset.starType == 'false'){
          // Temporarily doing this due to difference in staging vs local css difference
          entry.classList.remove('d-flex')
          entry.classList.add('hide_thumbnail')
        }
      })
    } else {
      isEnabledDisplayOnlyBookmarkedItems = false
      allEntryItems.forEach((entry) => {
        if(entry.classList.contains('hide_thumbnail')){
          entry.classList.add('d-flex')
          entry.classList.remove('hide_thumbnail')
        }
      })
    }
  }

  handlePartOfOttomanToggle(e) {
    const isChecked = e.currentTarget.checked
    const misspelledFieldsContainer = document.querySelector('#js-misspelled-fields-container')
    const btnAddAlternate = document.querySelector('.js-alternative_fields')
    const misspelledOptionsCheckboxes = document.querySelector('.js-misspelled-checkboxes-container')
    if (isChecked) {
      misspelledFieldsContainer.classList.add('hide_thumbnail')
      misspelledOptionsCheckboxes.classList.add('hide_thumbnail')
      btnAddAlternate.classList.add('hide_thumbnail')
      this.misspelledCheckBoxTarget.checked = false
      this.misspelledCheckBoxTarget.setAttribute('disabled', true);
    } else {
      misspelledOptionsCheckboxes.classList.remove('hide_thumbnail')
      misspelledFieldsContainer.classList.remove('hide_thumbnail')
      btnAddAlternate.classList.remove('hide_thumbnail')
      this.misspelledCheckBoxTarget.disabled = false;
    }
  }

  addSpecialCharacterForTypingForm(e){
    e.preventDefault();
    // TODO: use data-attribute to fetch value, instead of text within button
    if (e.currentTarget.innerText == 'ZWS') {
      this.addCharacterWhereCursorPointing('\u200C')
    } else {
      this.addCharacterWhereCursorPointing(e.currentTarget.innerText)
    }
  }

  addCharacterWhereCursorPointing(specialCharacter){
    const cursorPosition = this.lastFocusedField.selectionStart;
    const selectionEnd = this.lastFocusedField.selectionEnd
    const currentValue = this.lastFocusedField.value;
    let selectedCharacter = this.lastFocusedField.value.substring(this.lastFocusedField.selectionStart, this.lastFocusedField.selectionEnd);
    // For replace selected character to specialCharacter
    const newValue = currentValue.slice(0, cursorPosition) + specialCharacter + currentValue.slice(selectionEnd);
    this.lastFocusedField.value = newValue;
    this.lastFocusedField.setSelectionRange(cursorPosition + 1, cursorPosition + 1); // Move cursor after apostrophe
    this.lastFocusedField.focus();

    let event = new Event('input');
    this.lastFocusedField.dispatchEvent(event) // To call onchange callbacks
  }

  showItemOfWrittenIndex(e) {
    this.currentItemIndexContainerTarget.contentEditable = true;
    this.currentItemIndexContainerTarget.focus();
    this.currentItemIndexContainerTarget.innerText = '';
  }

  editToWrittenEntryItem(){
    let currentItemIndexContainer = this.currentItemIndexContainerTarget
    let allEntryItems = document.querySelectorAll('.js-selectable-entry')
    if (Number(currentItemIndexContainer.innerText) <= allEntryItems.length ) {
      allEntryItems[currentItemIndexContainer.innerText-1].click()
      currentItemIndexContainer.blur()
    } else {
      allEntryItems[allEntryItems.length - 1].click()
      currentItemIndexContainer.blur()
    }
    currentItemIndexContainer.contentEditable = false
  }

  setCursorFocus(){
    let entriesInputField;
    if(typing_form.isTypingLatinStage()) {
      entriesInputField = typing_form.latInputTarget;
      typing_form.lastFocusedField = typing_form.latInputTarget;
    } else {
      entriesInputField = typing_form.ottoInputTarget;
      typing_form.lastFocusedField = typing_form.ottoInputTarget;
    }

    // First new Alternate transcription button can be used to scroll, for a better UX
    entriesInputField.focus({ preventScroll: true });
    scrollIntoView(entriesInputField, {
      block: 'end', // To scroll only to show current typing element at end
      behavior: 'instant'
    })

  }

  // Manually, addition of alternative entry works independent of this method.
  // This method clicks that button to add alternative entry
  addAlternativeEntryManually(){
    let selectedText = window.getSelection().toString();
    this.lastFocusedField.closest('.js-typing-value-container').querySelector('.js-btn-add-alternate').click()
    // This assumes that lastFocusedField is the new alternateField, which is achieved by this method: focusToAlternateTranscription
    this.lastFocusedField.value = selectedText;

    this.filterValidCharacters({ currentTarget: this.lastFocusedField });
  }

  moveToNextTextField(){
    var textFields = document.querySelectorAll('.js-text-field');
    var nextIndex  = this.getNextFieldIndex(textFields);

    textFields[nextIndex].focus();
  }

  // copy and paste selected text to next transcription field
  copySelectedTextToNextTranscriptionField(selectedText){
    var textFields   = document.querySelectorAll('.js-latin-text-field');
    var nextIndex    = this.getNextFieldIndex(textFields);
    var nextElement  = textFields[nextIndex];

    // handled cases of empty selected value and last field 
    if (nextIndex == 0 || selectedText.length == 0) return;
    nextElement.value = selectedText;
    nextElement.focus();

    this.filterValidCharacters({ currentTarget: nextElement });
  }

  // Helper function to get the index of the next transcription field
  getNextFieldIndex(textFields) {
    var focusedElement = document.activeElement;
    var currentIndex   = Array.prototype.indexOf.call(textFields, focusedElement);
    
    return (currentIndex + 1) % textFields.length;
  }
  
  // Alternate entry is hid from view through nested-form library. However, need to set its _destroy flag (hidden field) to true for backend. Setting here
  removeAlternativeEntry(e) {
    let destroyFieldId = e.currentTarget.dataset.destroyFieldId;
    document.getElementById(destroyFieldId).value = true
  }

  setCurrentItemNotes(notes_count){
    this.currentItemNotes = notes_count
  }

  // On selecting whether an item is misspelled, additional checkboxes are displayed and notes form is made visible.
  triggerMisspelled(e) {
    if (this.toBeCheckedCheckBoxTarget.checked && this.currentItemNotes > 0) {
      let msg = 'Please delete notes with conflicting labels before changing the label.'
      alert(msg)
      e.currentTarget.checked = false
      return false
    }

    // e.currentTarget.checked is the new value, and preventDefault reverts it
    if(!e.currentTarget.checked && this.currentItemNotes > 0){
      e.preventDefault();
      let msg = 'Because a note is labeled "Misspelled," it cannot be unchecked directly. To remove the check, you must delete all notes labeled as "Misspelled".'
      alert(msg)
      return false;
    }

    const misspelledOptionsContainer = document.getElementById('misspelled-options')
    const misspelledOptionsCheckboxes = misspelledOptionsContainer.querySelectorAll('.js-misspelled-option-checkboxes');
    const misspelledFieldsContainer = document.querySelector('#js-misspelled-fields-container')

    // handling cursor focus by hotkeys   
    let correctedOttomanSpellingFields = $('.js-corrected-ottoman-field');

    if (e.currentTarget.checked) {
      // this.showNotesWithAdditionalFlags({ created_on_type_o_misspelled: true })
      misspelledOptionsContainer.classList.remove('d-none');
      misspelledFieldsContainer.classList.remove('d-none')
      //hiding add alternative button of simple ottoman value if item is misspelled.
      $('.js-add-alternative-btn.js-simple-add-alt-btn').addClass('d-none');
      this.toBeCheckedCheckBoxTarget.checked = false
      correctedOttomanSpellingFields.addClass('js-text-field');

      // Replicate ottoman simple to corrected value automatically
      this.ottoInputCorrectedTarget.value = this.ottoInputTarget.value;
    } else {
      misspelledOptionsContainer.classList.add('d-none');
      misspelledFieldsContainer.classList.add('d-none')
      //adding add alternative button of simple ottoman value if item is not misspelled.
      $('.js-add-alternative-btn.js-simple-add-alt-btn').removeClass('d-none');

      correctedOttomanSpellingFields.removeClass('js-text-field');
      // Uncheck all checkboxes inside the misspelled-options container
      misspelledOptionsCheckboxes.forEach(checkbox => {
        checkbox.checked = false;
      });
    }
  }

  // On selecting whether an item is misspelled, additional checkboxes are displayed and notes form is made visible.
  triggerTypeLMisspelled(e) {
    const misspelledOptions = document.getElementById('misspelled-options')
    const misspelledOptionsCheckboxes = misspelledOptions.querySelectorAll('.js-misspelled-option-checkboxes');

    if (e.currentTarget.checked) {
      // this.showNotesWithAdditionalFlags({ created_on_type_l_misspelled: true })
      // TODO: Fix: this.toBeCheckedCheckBoxTarget.checked = false
      misspelledOptions.classList.remove('d-none');
    } else {
      misspelledOptions.classList.add('d-none');
      misspelledOptionsCheckboxes.forEach(checkbox => {
        checkbox.checked = false;
      });
    }
  }

  showNotesWithAdditionalFlags({ created_on_type_o_to_be_checked = false, created_on_type_o_misspelled = false, created_on_type_l_to_be_checked = false, created_on_type_l_misspelled = false } ){
    let stagesElement = document.querySelector('.js-stages-controller');
    if(stagesElement){
      let stagesController = this.application.getControllerForElementAndIdentifier(
        stagesElement, "stages"
      );

      stagesController.showNotes({ created_on_type_o_to_be_checked: created_on_type_o_to_be_checked, created_on_type_o_misspelled: created_on_type_o_misspelled, created_on_type_l_to_be_checked: created_on_type_l_to_be_checked, created_on_type_l_misspelled: created_on_type_l_misspelled } )
    }
  }

  triggerToBeChecked(e) {
    if (this.typing_for != 'combined_word') {
      if (this.misspelledCheckBoxTarget.checked && this.currentItemNotes > 0) {
        let msg = 'Please delete notes with conflicting labels before changing the label.'
        alert(msg)
        e.currentTarget.checked = false
        return false
      }
    }

    if(!e.currentTarget.checked && this.currentItemNotes > 0){
      e.preventDefault();
      let msg = 'Because a note is labeled "Checked," it cannot be unchecked directly. To remove the check, you must delete all notes labeled as "Checked"'
      alert(msg)
      return false;
    }


    // If to be checked is selected, then system open notes automatically for ther user to create a new note
    if (this.typing_for != 'combined_word') {
      const misspelledOptionsContainer = document.getElementById('misspelled-options')
      const misspelledOptionsCheckboxes = misspelledOptionsContainer.querySelectorAll('.js-misspelled-option-checkboxes');
      let misspelledFieldsContainer = document.getElementById('js-misspelled-fields-container')

      if (e.currentTarget.checked) {
        // this.showNotesWithAdditionalFlags({ created_on_type_o_to_be_checked: true });
        this.misspelledCheckBoxTarget.checked = false

        misspelledOptionsContainer.classList.add('d-none');
        misspelledFieldsContainer.classList.add('d-none')
        // Uncheck all checkboxes inside the misspelled-options container
        misspelledOptionsCheckboxes.forEach(checkbox => {
          checkbox.checked = false;
        });

      }
    }
  }

  triggerTypelToBeChecked(e){
    const misspelledOptions = document.getElementById('misspelled-options')
    if (e.currentTarget.checked) {
      // this.showNotesWithAdditionalFlags({ created_on_type_l_to_be_checked: true });
      // TODO: Wrong implementation. Fix. misspelledOptions.classList.add('d-none')
      // this.misspelledCheckBoxTarget.checked = false
    }
  }

  // getFontSize(fontSizePercent) {
  //   // type-field is the common class used for typing, and it is setting the height of field
  //   const maxFontSize = document.getElementsByClassName('type-field')[0].clientHeight * 1.15;
  //   return maxFontSize * fontSizePercent / 100;
  // }

  setFocusedField(e){
    this.lastFocusedField = e.currentTarget;
  }

  // TODO: fetch harakat characters from rails because we have a constant in rails side too
  // this method removes the harakats from the text in the text field when root toggle is toggled on/off
  removeHarakatCharsFromTextFieldForRoot(e) {
    const rootToggle = e.currentTarget;
    const textField = rootToggle.closest('.entries-input').querySelector('.js-otto-field-for-alternates');
    if (textField) {
      let textValue = textField.value;
      const harakatChars = ['َ', 'ِ', 'ٰ', 'ٖ', 'ُ', 'ٌ', 'ْ', 'ّ', 'ً', 'ٍ'];
      const regex = new RegExp(harakatChars.map(char => '\\' + char).join('|'), 'g');
      textValue = textValue.replace(regex, '');
      textField.value = textValue;
    }
  }

  // TODO: fetch harakat characters from rails because we have a constant in rails side too
  filterValidCharacters(e) {
    let str = e.currentTarget.value || '';
    let savedStr = str;
    let savedCursorPosition = e.currentTarget.selectionStart;
    str = this.removeConsecutiveSpaces(str)
    // we dont allow this specific combination in type-l
    str = str.replace('ھ ', 'ھ')
    str = str.replace('ھ\u200C', 'ھ')
    str = str.replace('ە\u200C', 'ە')
    let stringCharacters = str.split('')
    
    let allowedChars = []
    if (e.currentTarget.classList.contains('js-otto-field-for-alternates')){
      if (e.currentTarget.parentElement.querySelector('.js-root-letter-toggle').checked) {
        allowedChars = ['ء', 'ب', 'ت', 'ث', 'ج', 'ح', 'خ', 'د', 'ذ', 'ر', 'ز', 'س', 'ش', 'ص', 'ض', 'ط', 'ظ', 'ع', 'غ', 'ف', 'ق', 'ك', 'ل', 'م', 'ن', 'و', 'ه', 'ی'];
      } else if (e.currentTarget.parentElement.querySelector('.js-root-toggle-typeo-alternates').checked) {
        const harakatChars = ['َ', 'ِ', 'ٰ', 'ٖ', 'ُ', 'ٌ', 'ْ', 'ّ', 'ً', 'ٍ'];
        let allowedCharsForRoot = this.validOttomanCharactersForAlternates;
        allowedChars = allowedCharsForRoot.filter(char => !harakatChars.includes(char));
      } else {
        allowedChars = this.validOttomanCharactersForAlternates
      }
    } else if (e.currentTarget.classList.contains('js-otto-field')) {
      allowedChars = this.validOttomanCharacters
    } else{
      allowedChars = this.validLatinCharacters
    }
    let validStr   = stringCharacters.filter((char) => allowedChars.includes(char)).join('');
    e.currentTarget.value = validStr;
    
    if(savedStr !== validStr){
      e.currentTarget.setSelectionRange(savedCursorPosition - 1, savedCursorPosition - 1); // Setting cursor to current position after setting filtered value
      e.currentTarget.focus()
    }
    
    // if(savedStr != str){ // it means words are filtered
    //   savedCursorPosition--;
    // }
    // // Setting placement of cursor
    // e.currentTarget.setSelectionRange(savedCursorPosition, savedCursorPosition); // Move cursor after apostrophe

    let wrongChars = stringCharacters.filter((char) => !allowedChars.includes(char));
    if(wrongChars.length > 0){
      // this.addFlashMessage(`Invalid character(s) [${wrongChars}] entered.`)
    }
  }

  // Remove consecutive space or zero with space, keeps 1
  removeConsecutiveSpaces(str) {
    let pattern = /( |\u200C)+/g; // Space and zero widht character. we keep one of these characters, if they appear consecutive
    let cleanedString = str.replace(pattern, (match, group) => group[0]);
    return cleanedString;
  }

  moveToPrevEntry(e){
    typing_form.submit(e, "prev");
  }

  moveToNextEntry(e){
    typing_form.submit(e, "next");
  }

  makeClickedItemActive(e) {
    $(".list-group-item").removeClass("active").removeClass("js-current-selected-item"); // Remove active and js-current-selected-item classes from all list items
    var closestAnchor = $(e.currentTarget).closest('a.list-group-item');  // Find the closest ancestor anchor tag (if any)
    if (closestAnchor.length > 0) { // Add active and js-current-selected-item classes to the closest anchor, js-current-selected-item for scrolling 
      closestAnchor.addClass("active js-current-selected-item");
    }
  }

  makePassedItemActive(itemId){
    $(".list-group-item").removeClass("active").removeClass("js-current-selected-item"); // Remove active and js-current-selected-item classes from all list items
    $(`#${itemId}`).addClass("active js-current-selected-item");
  }

  changeIconToSubmitted(itemId) {
    // getting the div containing icon
    const iconContainer = $(`#${itemId} .js-item-submission-status-icon-container`);
    if(iconContainer.length > 0){
      const icon = iconContainer.find('svg.feather.feather-edit-3');
      if(icon.length > 0){
        icon.replaceWith(feather.icons['check'].toSvg());
        // as the svg icon is replaced with updated icon, getting new svg icon to add classes to it
        const newIcon = iconContainer.find('svg.feather.feather-check');
        newIcon.addClass("ms-3 d-inline-block rounded-circle shadow-sm bg-success border border-2");
      }
    }
  }

  // if against item/image ottoman/latin value is present/exists then this method marks it green
  makePassedItemSubmitted(itemId){
    $(`#${itemId}`).removeClass("list-item-entry");
    $(`#${itemId}`).addClass("submitted-list-item-entry");
    this.changeIconToSubmitted(itemId);      
  }

  changeIconToNonSubmitted(itemId) {
    // getting the div containing icon
    const iconContainer = $(`#${itemId} .js-item-submission-status-icon-container`);
    if (iconContainer.length > 0) {
      const icon = iconContainer.find('svg.feather.feather-check');
      if (icon.length > 0) {
        icon.replaceWith(feather.icons['edit-3'].toSvg());
         // as the svg icon is replaced with updated icon, getting new svg icon to add classes to it
        const newIcon = iconContainer.find('svg.feather.feather-edit-3');
        newIcon.addClass("ms-3 d-inline-block shadow-sm");
      }
    }
  }

  // if against item/image ottoman/latin value is NOT present/exists then this method marks it non-green
  makePassedItemNonSubmitted(itemId){
    $(`#${itemId}`).removeClass("submitted-list-item-entry");
    $(`#${itemId}`).addClass("list-item-entry");
    this.changeIconToNonSubmitted(itemId);
  }

  showMisspelledErrorIfRequired(){
    if (this.isTypingOttomanStage() && this.typing_for != 'combined_word' && this.misspelledCheckedWithNoOptions()){
      showFlashMessage('The item has been identified as <b>Misspelled</b>. To proceed, kindly review and select one of the following options: <b>Letters, Harakat or Complicated</b>', 'alert')
      return true;
    } else {
        return false;
    }
  }

  moveToClickedItem(e){
    if (this.showMisspelledErrorIfRequired()){
      e.preventDefault(); // to prevent form submission // to prevent moving to next item and making it active in typing sidebar list 
      return;
    }
    this.makeClickedItemActive(e);
    let itemId   = e.currentTarget.dataset.itemId;
    let itemType = e.currentTarget.dataset.itemType;
    typing_form.submit(e, null, itemId, itemType);
  }

  submit(e, moveDirection = "next", itemId = null, itemType = null) {
    e.preventDefault();
    e.stopPropagation();
    
    if (this.showMisspelledErrorIfRequired()){
      return;
    }
    let formElement = typing_form.formTarget;
    if (moveDirection == "prev") {
      let inp = document.createElement('input')
      inp.type = 'hidden'
      inp.name = 'redirect_prev'
      inp.value = 'true'
      formElement.appendChild(inp)
    }

    // when item is clicked from sidebar list
    if (itemId && itemType) {
      // Item id
      let inp = document.createElement('input')
      inp.type = 'hidden'
      inp.name = 'item_id'
      inp.value = itemId
      formElement.appendChild(inp)
      // Item Type 
      let inp2 = document.createElement('input')
      inp2.type = 'hidden'
      inp2.name = 'item_type'
      inp2.value = itemType
      formElement.appendChild(inp2)
    }

    let bookMarkTogglerInput = document.createElement('input')
    bookMarkTogglerInput.type = 'hidden'
    bookMarkTogglerInput.name = 'showOnlyBookmarkedItems'
    bookMarkTogglerInput.value = isEnabledDisplayOnlyBookmarkedItems
    formElement.appendChild(bookMarkTogglerInput)

    //formElement.submit() // here instead of formElement.submit() we are clicking Save submit button to avoid list elements reload(which slows down the page) & to make ajax call work instead. 
    if (this.formSubmitButtonTarget) {
      this.formSubmitButtonTarget.click();
    }
  }

  isTypingLatinStage(){
    return this.typing_stage == 'type_l'
  }

  isTypingOttomanStage(){
    return this.typing_stage == 'type_o'
  }

  // model key to add params according to back-end model
  modelKey(){
    let key = '';

    if (this.typing_for == 'entry'){
      key = 'entry'
    } else if (this.typing_for == 'subentry'){
      key = 'subentry'
    } else if (this.typing_for == 'other'){
      key = 'other_word'
    }
    return key;
  }

  focusToAlternateTranscription(e) {
    let newAlternateFieldContainer = document.querySelector('.js-new-added-alternate-field-container');
    if (newAlternateFieldContainer) {
      let inputField = newAlternateFieldContainer.querySelector('input');
      if (inputField) {
        inputField.focus();
        this.lastFocusedField = inputField;
      }
    }

    e.currentTarget.scrollIntoView({ behavior: 'instant', block: 'center', inline: 'nearest' });
    // removing the class here so that when next time new alternate field is created, it should be available only on that newest alternate field 
    newAlternateFieldContainer.classList.remove('js-new-added-alternate-field-container');
  }

  addAppostrphe(e){
    // TODO: Dont directly use from buttons display html. Data attributes can be used for this
    let apostrophe = e.srcElement.innerHTML;
    const cursorPosition = this.lastFocusedField.selectionStart;
    const currentValue = this.lastFocusedField.value;
    
    const newValue =
      currentValue.substring(0, cursorPosition) +
      apostrophe +
      currentValue.substring(cursorPosition);
    
    this.lastFocusedField.value = newValue;
    this.lastFocusedField.setSelectionRange(cursorPosition + 1, cursorPosition + 1); // Move cursor after apostrophe
    this.lastFocusedField.focus();
  }

  clearOttomanField(e){
    if (e.currentTarget.classList.contains('js-simple-ottoman-field')) {
      this.ottoInputTarget.value = ''
    } else {
      this.ottoInputCorrectedTarget.value = ''
    }
  }

  hotKeys(e) {
    if (e.keyCode == 13 && isLatinHistoryModalOpen) {
      e.preventDefault()
    }
    
    if(this.isNotesModalOpen || preview_modal || submit_page_set_modal || pageset_approve_or_return_modal || isLatinHistoryModalOpen){
      return true;
    }

    const eventObject = window.event ? event : e
    const enter       = 13
    const up          = 38
    const down        = 40
    const alt         = 18
    const ctrl        = 17
    const one         = 49
    const two         = 50
    const three       = 51
    const left        = 37
    const right       = 39
    const m           = 77

    const capturedKeys = [enter, up, down, alt, one, two, three, left, right, ctrl, m]
    
    if (eventObject.keyCode == enter && isLatinHistoryModalOpen) {
      e.preventDefault()
    }
    
    // to avoid changing behaviour for rest of the keys
    if (!capturedKeys.includes(eventObject.keyCode) || (eventObject.keyCode === three && this.typing_stage === "type_l")){
      return;
    }

    if ([left, right].includes(eventObject.keyCode) && !preview_modal) { //to allow left/right arrow keys to work in text field in typeo/typel
      return true;
    }

    if (!eventObject.ctrlKey && eventObject.keyCode == m) {
      return true
    }
    // Check if the '1' key is pressed without any modifier keys
    if (eventObject.keyCode === one && !eventObject.altKey && !eventObject.ctrlKey && !eventObject.shiftKey) {
      return true
    }

    // if the key '3' is pressed in the text field of alternate ottoman on type_o stage, then toggle the state of root letter toggle
    if (eventObject.keyCode === three && this.typing_stage === 'type_o') {
      e.preventDefault();

      // getting the root letter toggle, through the container of the target input field
      const rootLetterCheckbox = event.target
        .closest('.js-entries-input')
        ?.querySelector('.js-root-parent-container .js-root-letter-toggle');

      if (rootLetterCheckbox) {
        rootLetterCheckbox.checked = !rootLetterCheckbox.checked;
      }
    } 

    e.preventDefault();
    e.stopPropagation();
    let moveDirection = 'next'
    // if Alt +1 or Alt +2 for apostrophe
    if (eventObject.altKey && (eventObject.keyCode == one || eventObject.keyCode == two)) {
      if (this.typing_stage == 'type_l') {
        if (eventObject.altKey && eventObject.keyCode == one) {
          this.startQuoteBtnTarget.click()
        } else {
          this.endQuoteBtnTarget.click()
        }
      }    
    } else if(eventObject.shiftKey && eventObject.keyCode == enter) {
      let selectedText = window.getSelection().toString();
      // in type_o add simple ottomans alternate spelling values if in misspelled case and focus is not on simple ottoman value
      // in type_l add alterative entry manually if text is not selected
      if ((this.typing_stage == 'type_o' && !(this.hasMisspelledCheckBoxTarget && this.misspelledCheckBoxTarget.checked && this.lastFocusedField.classList.contains('js-simple-ottoman-alternative-value'))) || 
         (this.typing_stage == 'type_l' && !selectedText)) {
        this.addAlternativeEntryManually();
      } else {
        this.copySelectedTextToNextTranscriptionField(selectedText);
      }
    } 
    // alt + enter key to focus on next text field
    else if ((eventObject.altKey && eventObject.keyCode == enter)){
      this.moveToNextTextField();
    } 
    /* ctrl + enter and up key for move to previous item and else for moving to next item*/
    else if((eventObject.ctrlKey && eventObject.keyCode == enter) || eventObject.keyCode == up || eventObject.keyCode == down || eventObject.keyCode == enter && !isLatinHistoryModalOpen) {
      /* ctrl + enter */
      if (eventObject.ctrlKey && eventObject.keyCode == enter) {
        moveDirection = "prev";
      } else if (eventObject.keyCode == up) {
        moveDirection = "prev";
      } else { // Enter, down
        moveDirection = "next";
      }

      // when current item container of badge is not in edit mode
      if (this.currentItemIndexContainerTarget.contentEditable == 'inherit' || this.currentItemIndexContainerTarget.contentEditable == 'false' ) {
        typing_form.submit(e, moveDirection)
      } else {
        let editToWrittenEntryItem = this.currentItemIndexContainerTarget
        if ((!/[0-9]/.test(event.key) && event.keyCode !== 13)|| (event.keyCode === 13 && editToWrittenEntryItem.innerText == '')) {
          event.preventDefault();
        } else if (event.keyCode === 13 && /[0-9]/.test(editToWrittenEntryItem.innerText)) {
          this.editToWrittenEntryItem();
        }
      }
    } else if(eventObject.keyCode == m && eventObject.ctrlKey) {
      this.contextViewBtnTarget.click()
    }
  }

  // For page_set
  // TODO: should be separate preview controller
  preview(e) {
    let stageUrl = this.previewUrlForStage(this.typing_stage, e.currentTarget.dataset.pageSetId)
    let data = {
      current_item_id: this.current_item_id,
      current_item_type: this.typing_for
    };
    $.ajax({
      url: stageUrl,
      type: 'GET',
      data: data
    });
  }

  previewUrlForStage(typing_stage, setId) {
    switch(typing_stage){
    case 'type_o':
      return Routes.preview_type_o_set_path({ id: setId })
      break
    case 'type_l':
      return Routes.preview_type_l_set_path({ id: setId })
      break
    }
  }
  
  // Why is it async
  async toggleThumbnailsVisibility(e){
    // TODO: Use separate classes for css and js
    let areThumbnailsVisible = (document.getElementsByClassName("hide_thumbnail").length == 0);
    let updatedThumbnailFlag = !areThumbnailsVisible;
    
    await Rails.ajax({
      type: "PATCH",
      url: "/user_settings/toggle_thumbnail_preview.js",
      data: `thumbnail_flag=${updatedThumbnailFlag}`,
      success: function(){
        location.reload();
      }
    });
  }

  misspelledCheckedWithNoOptions(){
    let misspelledOptionsContainer = document.getElementById('misspelled-options')
    let misspelledOptionsCheckboxes = misspelledOptionsContainer.querySelectorAll('.js-misspelled-option-checkboxes');
    let anyChecked = false;
    misspelledOptionsCheckboxes.forEach(checkbox => {
      if(checkbox.checked){
        anyChecked = true
      }
    });

    return (this.misspelledCheckBoxTarget.checked && !anyChecked)
  }

  // this method removed notes related styling from typing edit area
  removeNotesStyling(e){
    let currentItemNotesCount = this.currentItemNotes;

    if (currentItemNotesCount == 0 && 
        e.target.dataset.noteStage == this.typing_stage){  
      this.removeNotesStylingInTypingEditArea();
    }
  }

  removeNotesStylingInTypingEditArea(){
    $('.js-item-container').removeClass('border border-danger border-2');
    $('.js-progress-bar-slider').removeClass('bg-danger');
    $('.js-progress-bar').css('background-color', '');
  }

  // this method adds notes related styling from typing edit area
  addNotesStyling(){
    this.setCurrentItemNotes(this.currentItemNotes + 1);
    this.displayNotesStylingInTypingEditArea();
  }

  displayNotesStylingInTypingEditArea(){
    $('.js-item-container').addClass('border border-danger border-2');
    $('.js-progress-bar-slider').addClass('bg-danger');
    $('.js-progress-bar').css('background-color', '#FFB6C1');
  }

  // this method updates type_o/type_l alternative values assigned numbers
  updateAlternativeValueAssignedNumber(e) {
    const clickedBtn           = e.currentTarget;
    const clickedBtnIdentifier = parseInt(clickedBtn.getAttribute('data-add-alternative-btn-identifier'));

    // Updating identifier numbers of unclicked add alternative btns
    // also setting assigned values of hidden fields
    this.updateAddAltBtnsAndAssignedValueFieldsAfterSelectedField(clickedBtnIdentifier);
    
    // Setting Identifier numbers of new rendered add alternative btns
    // also setting assigned values of hidden fields
    this.setNewAddAltBtnsAndAssignedValueFields(clickedBtn, clickedBtnIdentifier); 
  }

  // Helper function to update identifier numbers of unclicked add alternative btns
  updateAddAltBtnsAndAssignedValueFieldsAfterSelectedField(selectedFieldIdentifier){
    const addAlternativeBtns = $('.js-add-alternative-btn');
    addAlternativeBtns.each((index, addAlternativeBtn) => {
      let unclickedBtnPrevIdentifier = parseInt(addAlternativeBtn.getAttribute('data-add-alternative-btn-identifier'));
      let updatedIdentifier          = unclickedBtnPrevIdentifier + 1;
      let isAddAlternativeBottomBtn  = addAlternativeBtn.getAttribute('data-from-add-alternative-button');
      
      if (unclickedBtnPrevIdentifier <= selectedFieldIdentifier && !isAddAlternativeBottomBtn) return;
      
      this.setAddAltBtnIdentifier(addAlternativeBtn, updatedIdentifier);

      // Updating Assigned Numbers Hidden Fields Value if unclicked btn is not bottom button
      if(!isAddAlternativeBottomBtn){
        this.updateAltEntryAssignedNumber(unclickedBtnPrevIdentifier, updatedIdentifier);
      }
    });
  }

  // Helper function to set identifier number and assigned Value of new rendered add alternative btn
  setNewAddAltBtnsAndAssignedValueFields(clickedBtn, clickedBtnIdentifier){
    const newAddAlternativeBtn               = document.querySelector(`[data-add-alternative-btn-identifier='-1']`);
    const isFromBottomAlternativeBtn         = clickedBtn.getAttribute('data-from-add-alternative-button');
    const updatedAddAlternativeBtnIdentifier = clickedBtnIdentifier + 1;
    
    // checking if clicked btn is bottom add alternative btn
    if (isFromBottomAlternativeBtn == 'true') {
      // set new btn identifier and assigned value equal to clicked btn and then increment clicked btn identifier number by 1 
      // because bottom button is at the most bottom of entries that's why it's identifier value will be highest 
      this.setAddAltBtnIdentifier(newAddAlternativeBtn, clickedBtnIdentifier);
      this.updateAltEntryAssignedNumber(-1, clickedBtnIdentifier);
      this.setAddAltBtnIdentifier(clickedBtn, updatedAddAlternativeBtnIdentifier);
    } else {
      // set new alt btn identifier and assigned value one more than clicked btn
      this.setAddAltBtnIdentifier(newAddAlternativeBtn, updatedAddAlternativeBtnIdentifier);
      this.updateAltEntryAssignedNumber(-1, updatedAddAlternativeBtnIdentifier);
    }
  }

  // Setter function to set identifier value of all alternative btns
  setAddAltBtnIdentifier(addAlternativeBtn, updatedIdentifier) {
    addAlternativeBtn.setAttribute('data-add-alternative-btn-identifier', updatedIdentifier);
  }


  // Helper function to update Hidden Field Assigned Number
  updateAltEntryAssignedNumber(prevAssignedValue, updatedAssignedValue ){
    const assignedNumberHiddenField = $(`.assigned-number-${prevAssignedValue}`);
    if (assignedNumberHiddenField.length > 1){
      //updating second value as previously changed assigned_value would become equal to the next value
      assignedNumberHiddenField[1].value = updatedAssignedValue;
      assignedNumberHiddenField[1].setAttribute('class', `assigned-number-${updatedAssignedValue} js-otto-spelling-assigned-number`);
    } else {
      assignedNumberHiddenField[0].value = updatedAssignedValue;
      assignedNumberHiddenField[0].setAttribute('class', `assigned-number-${updatedAssignedValue} js-otto-spelling-assigned-number`);
    }
  }
}
