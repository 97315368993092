export default class API {
  static async get(params, type) {
    return this.call('GET', params, type);
  }

  static async post(params) {
    return this.call('POST', params);
  }

  static async put(params) {
    return this.call('PUT', params);
  }

  static async delete(params) {
    return this.call('DELETE', params);
  }

  static async call(method, { url, data }, type = 'json') {
    const response = await fetch(url, {
      method,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      ...(['put', 'post'].includes(method.toLowerCase()) ? {
          body: JSON.stringify(data)
      } : {})
    })
    return await response[type]()
  }

  static async put({ url, data }) {
    const response = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    })
    return await response.json()
  }

  static async delete({ url, data }) {
    const response = await fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer'
    })
    return await response.json()
  }
}
