import ApplicationController from "./application_controller"

export default class extends ApplicationController {

  connect() { }

  showTruncatedTextInModal(event) {
    const messageContent = $(event.currentTarget).find('.js-truncated-text').html();
    const linkedMessage  = linkify(messageContent);

    const modal      = document.getElementById("textContentModal");
    const modalTitle = document.getElementById("messageModalTitle");
    const modalText  = document.getElementById("messageModalText");

    modalTitle.innerHTML = "Note";
    modalText.innerHTML  = linkedMessage;

    $('#textContentModal').modal('show');
  }
}