// Copied, transformed into js and modified from https://github.com/stimulus-components/stimulus-rails-nested-form/blob/master/src/index.ts
import NestedFormController from "./nested_form_controller";

export default class DictionaryEditorNestedFormController extends NestedFormController {

  // Dynamically sets category order, when it is created on runtime
  initializeCategoryOrderField(){
    let categoryOrderFields = document.querySelectorAll('.js-order-number-field')
    if (categoryOrderFields.length > 1) {
      
      let lastCategoryOrderField       = allNumberField[allNumberField.length - 2];
      let newAddedCategoryOrderField   = allNumberField[allNumberField.length - 1];
      newAddedCategoryOrderField.value = Number(newAddedCategoryOrderField.value) + 1;
    }
  }

  // Each category can only contain a single sub-category
  toggleSubcategoryField(e){
    let subCategory = e.currentTarget.closest('.js-other_word_parent_container').querySelector('.js-sub-category')

    if (e.currentTarget.checked) {
      subCategory.classList.remove('hide_thumbnail')
      subCategory.querySelector('.js-child-title-field').disabled = false
      subCategory.querySelector('.js-child-color-field').disabled = false
      subCategory.querySelector('.js-child-destroy-hidden-field').disabled = false
    } else {
      subCategory.classList.add('hide_thumbnail')
      subCategory.querySelector('.js-child-title-field').disabled = true
      subCategory.querySelector('.js-child-color-field').disabled = true
      subCategory.querySelector('.js-child-destroy-hidden-field').disabled = true
    }
  }
}
