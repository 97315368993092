import { calculatePolygonDimensions } from '../lib/array_utils';

let isWorker = true;

try {
  if (window?.document) {
    isWorker = false;
  } else {
    isWorker = true;
  }
} catch {
  isWorker = true;
}

export const rotatePoint = (point, centerPoint, angle) => {
  if (!centerPoint) {
    centerPoint = {x: 0, y: 0}
  }
  let radians = (Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = (cos * (point.x - centerPoint.x)) + (sin * (point.y - centerPoint.y)) + centerPoint.x,
    ny = (cos * (point.y - centerPoint.y)) - (sin * (point.x - centerPoint.x)) + centerPoint.y;
  return { x: nx, y: ny };
}

export const rotatePolygon = (polygon, centerPoint, angle) => {
  return polygon.map((point) => rotatePoint(point, centerPoint, angle));
}

// Draws the image on canvas
export const drawImageOnCanvas = (context, image, { horizontalOffsetCanvas, verticalOffsetCanvas, horizontalWidthCanvas, verticalHeightCanvas, offsetX, offsetY }) => {
  // If only image is passed. Use all of the space of canvas. // THIS CASE NEVER HAPPENS IN THE APP
  if (arguments.length === 2) {
    horizontalOffsetCanvas = verticalOffsetCanvas = 0
    horizontalWidthCanvas = context.canvas.width
    verticalHeightCanvas = context.canvas.height
  }

  // TODO: Explain
  // Offsets are mostly useless
  // default offset is center
  offsetX = typeof offsetX === 'number' ? offsetX : 0.5
  offsetY = typeof offsetY === 'number' ? offsetY : 0.5
  // keep bounds [0.0, 1.0]
  if (offsetX < 0) offsetX = 0
  if (offsetY < 0) offsetY = 0
  if (offsetX > 1) offsetX = 1
  if (offsetY > 1) offsetY = 1

  const rotationImageCenter = { x: image.width / 2, y: image.height / 2 }
  const imagePolygon        = [{ x: 0, y: 0 }, { x: image.width, y: 0 }, { x: image.width, y: image.height }, { x: 0, y: image.height }]
  const rotatedImagePolygon = rotatePolygon(imagePolygon, rotationImageCenter, context.currentRotation)
  const imageDimensions     = calculatePolygonDimensions(rotatedImagePolygon)
  const imagePolygonMinX    = imageDimensions.minX, imagePolygonMinY = imageDimensions.minY, imagePolygonWidth = imageDimensions.width, imagePolygonHeight = imageDimensions.height;

  var originalWidthImage = imagePolygonWidth,
    originalHeightImage  = imagePolygonHeight,
    canvasToImageRatio   = Math.min(horizontalWidthCanvas / originalWidthImage, verticalHeightCanvas / originalHeightImage),
    scaledWidth          = originalWidthImage * canvasToImageRatio,   // new prop. width
    scaledHeight         = originalHeightImage * canvasToImageRatio,   // new prop. height
    calculatedMinX, calculatedMinY, calculatedWidth, calculatedHeight, canvasToScaledImageRatio = 1;

  // decide which gap to fill
  if (scaledWidth < horizontalWidthCanvas)
    canvasToScaledImageRatio = horizontalWidthCanvas / scaledWidth
  if (Math.abs(canvasToScaledImageRatio - 1) < 1e-14 && scaledHeight < verticalHeightCanvas)
    canvasToScaledImageRatio = verticalHeightCanvas / scaledHeight; // updated
  scaledWidth  *= canvasToScaledImageRatio
  scaledHeight *= canvasToScaledImageRatio

  // calc source rectangle
  calculatedWidth  = originalWidthImage * (horizontalWidthCanvas / scaledWidth)
  calculatedHeight = originalHeightImage * (verticalHeightCanvas / scaledHeight)
  calculatedMinX   = (originalWidthImage - calculatedWidth) * offsetX
  calculatedMinY   = (originalHeightImage - calculatedHeight) * offsetY
  // Why is this required
  // make sure source rectangle is valid
  if (calculatedMinX < 0)
    calculatedMinX = 0
  if (calculatedMinY < 0)
    calculatedMinY = 0
  if (calculatedWidth > originalWidthImage)
    calculatedWidth = originalWidthImage
  if (calculatedHeight > originalHeightImage)
    calculatedHeight = originalHeightImage

  context.save()
  context.translate(context.canvas.width / 2, context.canvas.height / 2)
  context.rotate(context.currentRotation * Math.PI / 180)
  context.translate(-context.canvas.width / 2, -context.canvas.height / 2)
  // DOCS:
  // sourceImageStartX Optional. The x coordinate where to start clipping from img
  // sourceImageStartY Optional. The y coordinate where to start clipping from img
  // sourceImageWidth  Optional. Pixels in width of image  
  // sourceImageHeight Optional. Pixels in height of image 
  // offsetCanvasX The x coordinate where to place the image on the canvas 
  // offsetCanvasY The y coordinate where to place the image on the canvas 
  // horizontalWidthCanvas Optional. The width of the image to use (stretch or reduce the image) 
  // verticalHeightCanvas  Optional. The height of the image to use (stretch or reduce the image)
  // context.drawImage(img, sourceImageStartX, sourceImageStartY, sourceImageWidth, sourceImageHeight, offsetCanvasX, offsetCanvasY, horizontalWidthCanvas, verticalHeightCanvas)
  context.drawImage(image, calculatedMinX + imagePolygonMinX, calculatedMinY + imagePolygonMinY, calculatedWidth, calculatedHeight, horizontalOffsetCanvas, verticalOffsetCanvas, horizontalWidthCanvas, verticalHeightCanvas)
  context.restore()
};

export const drawPath = ({ context, points = [], closePath = false, lineWidth = null, strokeStyle = 'red' }) => {
  if (points.length < 1)
    return

  context.beginPath()
  context.lineWidth = lineWidth || 1 / context.globalZoomFactor
  context.strokeStyle = strokeStyle
  context.moveTo(points[0].x, points[0].y)
  for(let i = 1; i < points.length; i++){
    context.lineTo(points[i].x, points[i].y)
  }
  if (closePath)
    context.closePath()
};

export const getClippedDataUrlWorker = (path, image, currentRotation, width, height) => {
  if (isWorker) {
    return new Promise(async resolve => {
      let minX, minY;
      const image = await createImageBitmap(await (await fetch(imageSrc)).blob());
      const tempCanvas = new OffscreenCanvas(width, height);
      const tempContext = tempCanvas.getContext('2d');
      tempContext.currentRotation = currentRotation;
      tempContext.save();
      tempContext.clear?.();
      drawPath({ context: tempContext, points: path });
      tempContext.clip();
      requestAnimationFrame(() => drawImageOnCanvas(tempContext, image, 
          { horizontalOffsetCanvas: 0, verticalOffsetCanvas: 0, horizontalWidthCanvas: width, verticalHeightCanvas: height }
        )
      );
      tempContext.restore();
      ({ minX, minY, width, height } = calculatePolygonDimensions(path));
      const clippedCanvas = new OffscreenCanvas(width, height);
      clippedCanvas.getContext('2d').drawImage(tempCanvas, minX, minY, width, height, 0, 0, width, height)
      requestAnimationFrame( async () => resolve(new FileReaderSync().readAsDataURL(await clippedCanvas.convertToBlob())));
    });
  } else {
    let minX, minY;
    const tempCanvas = isWorker ? new OffscreenCanvas(width, height) : document.createElement('canvas');
    tempCanvas.width = width;
    tempCanvas.height = height;
    const tempContext = tempCanvas.getContext('2d');
    tempContext.currentRotation = currentRotation;
    tempContext.save();
    tempContext.clearRect(0, 0, tempContext.canvas.width, tempContext.canvas.height);
    drawPath({ context: tempContext, points: path });
    tempContext.clip();
    drawImageOnCanvas(tempContext, image, 
          { horizontalOffsetCanvas: 0, verticalOffsetCanvas: 0, horizontalWidthCanvas: width, verticalHeightCanvas: height }
        )
    tempContext.restore();
    ({ minX, minY, width, height } = calculatePolygonDimensions(path));
    const clippedCanvas = isWorker ? new OffscreenCanvas(width, height) : document.createElement('canvas');
    clippedCanvas.width = width;
    clippedCanvas.height = height;
    clippedCanvas.getContext('2d').drawImage(tempCanvas, minX, minY, width, height, 0, 0, width, height);
    return clippedCanvas.toDataURL();
  }

}

onmessage = async function({ data }) {
  if (false && data) {
    postMessage(await getClippedDataUrlWorker(...data));
  }
}
