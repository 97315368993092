// This controller is derived from canvas_controller, with only some of its functionalities

import ApplicationController from "./application_controller";
import Polygon from '../lib/polygon'
import Zoom from '../lib/zoom'
import { initUtils } from '../lib/canvas_utils'
import { CanvasScrollbar } from "../lib/canvas_scrollbar";

export default class EbookCanvasController extends ApplicationController {
  static targets = [
    'mainCanvas',
    'container',
    'xScroll',
    'yScroll',
  ];

  // Gets called in initialize()
  #assignDefaultValues(){
    this.originalImagePath = ''
    this.mainImage = new Image()
    this.parentImage = new Image()
    this.zoomInstance = {}
  }

  initialize() {
    window['ebook_canvas'] = this;

    this.#assignDefaultValues();
    this.originalImagePath = this.element.dataset.originalImage;
    this.mainContext = this.mainCanvasTarget.getContext('2d')
    initUtils(this.mainContext)

    this.mainImage.crossOrigin = 'anonymous'
    this.mainImage.onload = () => this.imageOnLoad()
    this.mainImage.src = this.originalImagePath
    this.#initializeZoom();
  }

  #initializeZoom(){
    this.zoomInstance = Zoom().init({
      canvasContext: this.mainContext,
      redraw: (mousePoint = null) => {
        this.redraw()
      },
      updateScrollbars: (...args) => this.canvasScrollbar.updateScrollbars(...args)
    })
    this.canvasScrollbar = new CanvasScrollbar(this.mainContext, this.xScrollTarget, this.yScrollTarget, this.zoomInstance);
  }

  async imageOnLoad() {
    this.mainContext.canvas.classList.remove('w-100', 'h-100');
    
    this.mainContext.image = this.mainImage
    let canvasImageDimensions               = this.mainContext.fitImageInCanvas(this.mainImage, this.containerTarget.parentNode)
    this.mainContext.canvasHorizontalMargin = canvasImageDimensions.canvasHorizontalMargin;
    this.mainContext.canvasVerticalMargin   = canvasImageDimensions.canvasVerticalMargin;

    this.mainContext.blurOffset = this.mainContext.blurSize / this.mainContext.imageRatio()
    this.zoomInstance.initZoom()

    this.redraw()
    let clips = this.element.dataset.clips;
    this.addClips(clips)
  }

  addClips(clips){ 
    clips = JSON.parse(clips);
    let processedClips = []
    if (clips) {

      for (let clip of clips) {
        processedClips.push({
          id: clip.id,
          polygon: this.mainContext.unscaledPath(clip.polygon),
          scaledPolygon: clip.scaledPolygon,
          data: this.mainContext.getClippedDataUrl(clip.polygon, this.mainContext.parentImage),
          number: clip.number,
          itemOrder: clip.ItemOrder,
          subNumber: clip.subNumber,
          inParts: !!clip.subNumber,
          regionId: clip.regionId,
          otherWordCategory: clip.otherWordCategoryId,
        });
      }
      this.mainContext.clips = processedClips;
      this.redraw()
    }
  }


  redraw(extraBorder) {
    this.mainContext.clear()
    // this.mainContext.setBackgroundColor()
    this.mainContext.drawImageOnCanvas(this.mainImage, 
      { horizontalOffsetCanvas: this.mainContext.canvasHorizontalMargin, verticalOffsetCanvas: this.mainContext.canvasVerticalMargin, horizontalWidthCanvas: this.mainContext.imageCanvasWidth(), verticalHeightCanvas: this.mainContext.imageCanvasHeight() }
    );
    this.mainContext.initBlur(0)

    this.mainContext.save()
    this.mainContext.clip()
    this.mainContext.drawImageOnCanvas(this.mainImage, 
      { horizontalOffsetCanvas: this.mainContext.canvasHorizontalMargin, verticalOffsetCanvas: this.mainContext.canvasVerticalMargin, horizontalWidthCanvas: this.mainContext.imageCanvasWidth(), verticalHeightCanvas: this.mainContext.imageCanvasHeight() }
    );
    this.mainContext.restore()

    this.mainContext.drawPolygons({ showEntriesNumbering: false });
  }

  handleZoom(e) {
    if (e.altKey || e.ctrlKey) {
      e.preventDefault();
      
      const delta = e.wheelDelta ? e.wheelDelta / 200 : e.detail ? -e.detail : 0
      if (delta)
        this.zoomInstance.zoom(delta, e.ctrlKey)
      return false
    } else {
      e.preventDefault();
      this.canvasScrollbar.handleMove(e);
    }
  }
}
