import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [
    'enabledUsersDropDown',
    'disabledUsersDropDown',
    'filterToggle'
  ];

  initialize(){
    // Initialize by removing filter toggle button disabled attribute
    this.enableFilterToggleButton();
  }

  enableFilterToggleButton() {
    if (this.filterToggleTarget) {
      this.filterToggleTarget.removeAttribute("disabled");
    }
  }

  toggleSearchBars(e) {
    const isChecked = e.currentTarget.checked;
    if (isChecked) {
      this.enabledUsersDropDownTarget.classList.remove("collapse");
      this.disabledUsersDropDownTarget.classList.add("collapse");

      this.disabledUsersDropDownTarget.setAttribute("disabled", "disabled");
      this.enabledUsersDropDownTarget.removeAttribute("disabled");
      $('#default_search_bar').val(0);
    } else {
      this.enabledUsersDropDownTarget.classList.add("collapse");
      this.disabledUsersDropDownTarget.classList.remove("collapse");
      
      this.enabledUsersDropDownTarget.setAttribute("disabled", "disabled");
      this.disabledUsersDropDownTarget.removeAttribute("disabled");
      $('#default_search_bar').val(1);
    }
  }

  hanldeSetsFilterCard(e){
    if(e.currentTarget.checked){
      document.querySelector('.js-sets-filter-card').classList.remove('hide_thumbnail')
    } else {
      document.querySelector('.js-sets-filter-card').classList.add('hide_thumbnail')
    }
  }
}