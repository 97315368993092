import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [
  "userRole",
  "reviewerPermission",
  "reviewerBindingPermission",
  "allowReviewerToApproveCropStage",
  "reviewerStagePermission"]

  initialize() {
    // removing reviewer permissions and reviewer binidng action permissions from user form if user is not reviewer
    if (this.userRoleTarget.value != 'reviewer') {
      this.reviewerPermissionTarget.classList.add('d-none');
      this.reviewerBindingPermissionTarget.classList.add('d-none');
    } else {
      // Displaying binding actions stages options based on corresponding reviewer permission settings
      this.displayBindingActionsOptions();
    }
  }
  
  // Displays binding action stage options based on the corresponding reviewer permission settings
  displayBindingActionsOptions(){
    this.reviewerStagePermissionTargets.forEach(stageCheckbox => {
      // displaying binding action option if corresponding reviewer permission is checked
      if (stageCheckbox.checked){
        const bindingActionStageOptionTargetName= this.getBinindgActionOptionTarget(stageCheckbox.dataset.reviewerPermission); 
        this.displayBindingActionsOption(bindingActionStageOptionTargetName);
      }
    });
  }

  updateRole() {
    const selectedRole = this.userRoleTarget.value;

    if (selectedRole == 'reviewer') {
      this.reviewerPermissionTarget.classList.remove('d-none')
      this.reviewerBindingPermissionTarget.classList.remove('d-none')
    } else {
      this.reviewerPermissionTarget.classList.add('d-none')
      this.reviewerBindingPermissionTarget.classList.add('d-none')
    }
  }

  handleBindingActionOptions(e){
    // getting reviewer permission
    const toggleReviewerPermission = e.target.dataset.reviewerPermission;
    // toggleReviewerPermissionStageCheckboxes gets both access and approval-required sets checkboxes of reviewer permissions
    const toggleReviewerPermissionStageCheckboxes = document.querySelectorAll(`[data-reviewer-permission="${toggleReviewerPermission}"]`);
    
    const bindingActionStageOptionTargetName = this.getBinindgActionOptionTarget(toggleReviewerPermission);
    
    this.toggleBindingActionOption(bindingActionStageOptionTargetName, e.target.checked, toggleReviewerPermissionStageCheckboxes);
  }

  // Returns the target name for the binding action permission stage option  
  getBinindgActionOptionTarget(reviewerPermissionTargetName){
    // Mapping from reviewer permission to corresponding binding action stage target
    const bindingActionStageTargets = {
      'allow_reviewer_crop_stage': 'allow_reviewer_to_approve_crop_stage',
      'allow_reviewer_sslice_stage': 'allow_reviewer_to_approve_sslice_stage',
      'allow_reviewer_highlight_stage': 'allow_reviewer_to_approve_highlight_stage',
      'allow_reviewer_type_o_stage': 'allow_reviewer_to_approve_type_o_stage',
      'allow_reviewer_type_l_stage': 'allow_reviewer_to_approve_type_l_stage'
    };

    return bindingActionStageTargets[reviewerPermissionTargetName];
  }
  
  // Toggles display of binding actions options 
  toggleBindingActionOption(targetName, isChecked, reviewer_permission_checkboxes) {
    const allUnchecked = Array.from(reviewer_permission_checkboxes).every(checkbox => !checkbox.checked);

    if (isChecked) {
      this.displayBindingActionsOption(targetName);
    } else if (allUnchecked) {
      this.hideBindingActionOption(targetName);
    }
  }

  displayBindingActionsOption(targetName) {
    this.targets.find(targetName).classList.remove('collapse')
  }
  
  hideBindingActionOption(targetName){
    this.targets.find(targetName).classList.add('collapse')
  }
}