import ApplicationController from "./application_controller"

export default class extends ApplicationController {

  static targets = [
    'alternativeHistoriesModal'
  ];

  initialize() {
    window['spelling_sources'] = this;
    //this.#assignDefaultValues()
    this.addShowHideListenersToLatinHistoryModal();
  }

  // #assignDefaultValues(){
  //   this.isLatinHistoryModalOpen = false;
  // }

  addShowHideListenersToLatinHistoryModal() {
    const modalElement = this.alternativeHistoriesModalTarget;
    modalElement.addEventListener('show.bs.modal', () => {
      isLatinHistoryModalOpen = true;
      // console.log("Modal is now open");
      // console.log(this.isLatinHistoryModalOpen)
    });

    modalElement.addEventListener('hide.bs.modal', () => {
      isLatinHistoryModalOpen = false;
      // console.log("Modal is now closed");
      // console.log(this.isLatinHistoryModalOpen)
    });		
  }
}
