export default class Polygon {
  constructor(points) {
    this.points = points;
  }

  minY() {
    let ys = this.points.map((p) => p['y'])
  
    return Math.min(...ys)
  }

  maxY() {
    let ys = this.points.map((p) => p['y'])
  
    return Math.max(...ys)
  }

  minX() {
    let xs = this.points.map((p) => p['x'])

    return Math.min(...xs);
  }

  maxX() {
    let xs = this.points.map((p) => p['x'])

    return Math.max(...xs);
  }

  centerX(){
    return (this.maxX() + this.minX()) / 2;
  }

  centerY(){
    return (this.maxY() + this.minY()) / 2;
  }

  height(){
    return (this.maxY() - this.minY());
  }

  width(){
    return (this.maxX() - this.minX());
  }

  minYPoint(){
    let minYPoint = this.points.reduce((prev, current) => (prev.y < current.y) ? prev : current)
    return minYPoint;
  }

  maxYPoint(){
    let maxYPoint = this.points.reduce((prev, current) => (prev.y > current.y) ? prev : current)
    return maxYPoint;
  }

  // TODO: Make a rectangle.js child of polygon.js and use that
  // Sides reference: https://trello.com/c/PZr8n3g3/727-changing-the-size-of-the-rectangles-and-polygons-using-arrow-buttons-on-keyboard-p2
  aSide(){
    let aSidePoints = this.points.filter(point => point['y'] < this.centerY());

    return aSidePoints;
  }

  bSide(){
    let bSidePoints = this.points.filter(point => point['x'] < this.centerX());

    return bSidePoints;
  }

  cSide(){
    let cSidePoints = this.points.filter(point => point['y'] > this.centerY());

    return cSidePoints;
  }

  dSide(){
    let dSidePoints = this.points.filter(point => point['x'] > this.centerX());

    return dSidePoints;
  }
  
  // Rectangle
  topLeft(){
    let topLeftPoints = this.points.filter(point => point['y'] < this.centerY() && point['x'] < this.centerX());
    let topLeft = topLeftPoints[0];

    return topLeft;
  }

  topRight(){
    let topRightPoints = this.points.filter(point => point['y'] < this.centerY() && point['x'] > this.centerX());
    let topRight = topRightPoints[0];

    return topRight; 
  }

  bottomLeft(){
    let bottomLeftPoints = this.points.filter(point => point['y'] > this.centerY() && point['x'] < this.centerX());
    let bottomLeft = bottomLeftPoints[0];
    return bottomLeft; 
  }

  bottomRight(){
    let bottomRightPoints = this.points.filter(point => point['y'] > this.centerY() && point['x'] > this.centerX());
    let bottomRight = bottomRightPoints[0];

    return bottomRight; 
  }

};
