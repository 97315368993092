// Copied, transformed into js and modified from https://github.com/stimulus-components/stimulus-rails-nested-form/blob/master/src/index.ts
import ApplicationController from "./application_controller";

export default class NestedFormController extends ApplicationController {
  static targets = ["target", "template"]
  // static values = {
  //   wrapperSelector: { type: String, default: ".nested-form-wrapper" }
  // }

  connect(){
    this.uniq_identifier = this.element.dataset['uniqIdentifier'] || 'NEW_RECORD';
  }

  add(e) {
    e.preventDefault();
    let replaceAllRegex     = new RegExp(this.uniq_identifier, 'g');
    let typingContainer     = e.currentTarget.parentElement;
    let fromSpellingSources = e.currentTarget.classList.contains('js-add-history-sources-field-btn')
    let content             = this.templateTarget.innerHTML.replace(
      replaceAllRegex,
      new Date().getTime().toString()
    );

    // Now we have multiple buttons to add alternative fields refs#1032, thats why we are rendering button at different locations 
    // Check if the action is not triggered from typing form 
    if (!typing_form){
      // If true, append the new content at the end of the target container
      this.targetTarget.insertAdjacentHTML("beforeend", content); // Updated from beforebegin to add new element at top
    // Checking if it is triggered by the spelling sources "+" add more button 
    } else if (fromSpellingSources){
      e.currentTarget.insertAdjacentHTML("afterend", content);
    // Checking if it is triggered by the "add alternative" button at the bottom 
    } else if (e.currentTarget.getAttribute('data-from-add-alternative-button')) {
      typingContainer = typingContainer.querySelector('.js-corrected-sortable-values, .js-simple-sortable-values');
      typingContainer.insertAdjacentHTML("beforeend", content);
    } else {
      // insert the new content immediately after the target's parent element
      typingContainer.insertAdjacentHTML("afterend", content);
    }
  }

  remove(e) {
    e.preventDefault()

    // @ts-ignore
    let wrapper = e.target.closest('.nested-form-wrapper'); //this.wrapperSelectorValue)

    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove()
    } else {
      wrapper.style.display = "none"

      let input = wrapper.querySelector("input[name*='_destroy']")
      input.value = "1"
    }
  }
}
